<template>
  <div style="width: 100%">
    <div class="kb" v-if="cartlist.length == 0">
      <img src="../../assets/kb@2x.png" alt="" />
      <div style="width: 100%; text-align: center;    margin-top: 20px;">
        <router-link to="/">
         <el-button type="primary">去逛逛</el-button>
        </router-link>
      </div>
    </div>
    <div class="tablecart">
      <el-table
        v-if="cartlist.length != 0"
        ref="multipleTable"
        :data="cartlist"
        tooltip-effect="dark"
        style="width: 100%"
        header-cell-style="background:#F5F5FA; color: #1A1A1A ;font-weight: bold; font-size: 14px;"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          header-align="center"
        ></el-table-column>
        <el-table-column label="商品信息" width="420">
          <template slot-scope="scope">
            <!-- {{ scope.row.date }} -->
            <div class="table" @click="navdetails(scope.row)">
              <div class="table_pic">
                <img :src="$util.img(scope.row.goods_image)[0]" alt="" />
              </div>
              <div class="table_text">{{ scope.row.name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="单品价格" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table_sc_pice">
              ￥{{ Number(scope.row.price).toFixed(2) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="数量" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- {{ scope.row.date }} -->
            <div class="good_num">
              <div class="btns">
                <div class="good_num_btn" @click="reduce(scope.row)">-</div>
                <span>{{ scope.row.num }}</span>
                <div class="good_num_btn" @click="add(scope.row)">+</div>
              </div>
            </div>
          </template> </el-table-column
        ><el-table-column
          label="操作"
          show-overflow-tooltip
          header-align="center"
        >
          <template slot-scope="scope">
            <div class="table_cz_textbtn" @click="collection_add(scope.row)">
              加入收藏夹
            </div>
            <div class="table_cz_textbtn" @click="delcart(scope.row)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="table_js" v-if="cartlist.length != 0">
        <div class="table_js_left">
          <div class="table_js_left_checkbox">
            <el-checkbox v-model="checked" @change="quanxuan">全选</el-checkbox>
          </div>
          <div>
            <span class="table_js_left_textbtn" @click="tysc">加入收藏夹</span>
            <span class="table_js_left_textbtn" @click="del">删除</span>
          </div>
        </div>
        <div class="table_js_right">
          <div class="table_js_right_good">
            已选商品 <span>{{ select_list.length }}</span> 件
          </div>
          <div class="table_js_right_hj">
            合计：<span>¥{{ totalprice }}</span>
          </div>

          <div class="">
            <button class="table_js_right_btn" @click="js_table">结 算</button>
          </div>
        </div>
      </div>
      <div class="browse">
        <div class="good_tj">
          <h1>猜你喜欢</h1>
          <div class="tjlistbox">
            <div
              class="tj_list"
              v-for="(item, index) in colllist"
              :key="index"
              @mouseenter="StopRoll(index)"
              @mouseleave="UpRoll(index)"
            >
              <div class="tj_pic">
                <img :src="$util.img(item.goods_image)[0]" alt="" />
              </div>
              <div class="browse_title">{{ item.name }}</div>
              <div class="browse_price_cart">
                <span class="browse_price">¥{{ item.price }}</span>
                <span
                  class="browse_cart"
                  v-if="mouindex == index"
                  @click="addcart(item)"
                  ><img src="../../assets/selectcart.png" alt=""
                /></span>
                <span class="browse_cart" v-else
                  ><img src="../../assets/cart2.png" alt=""
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungCart",

  data() {
    return {
      checked: false,
      tableData: [],
      cartlist: [],
      colllist: [],
      mouindex: null,
      // 总价
      totalprice: 0,
      shuliang: 0,
      select_list: [],
    };
  },

  mounted() {
    this.getcartlist();
    this.getzuji();
  },

  methods: {
    reduce(row) {
      if (row.num > row.starting_sale) {
        row.num -= 1;
        this.$api.user
          .cartnum({
            id: row.id,
            num: row.num,
          })
          .then((res) => {
            if (res.code == 1) {
              this.sum();
            } else {
              row.num += 1;
              this.$message.error(res.msg);
            }
          });
      }
    },
    add(row) {
      row.num += 1;
      if (row.num > 0) {
        this.$api.user
          .cartnum({
            id: row.id,
            num: row.num,
          })
          .then((res) => {
            if (res.code == 1) {
              this.sum();
            } else {
              row.num -= 1;
              this.$message.error(res.msg);
            }
          });
      }
    },
    // 购物车结算
    js_table() {
      let ids = "";
      if (this.select_list.length > 0) {
        this.select_list.forEach((item) => {
          if (ids == "") {
            ids = item.id;
          } else {
            ids += "," + item.id;
          }
        });
        this.$router.push({
          name: "Confirmorder",
          query: {
            sub_type: "2",
            sub_ids: ids,
            plan: "3",
          },
        });
      }
    },
    navdetails(row) {
      let routeData = this.$router.resolve({
        path: "details",
        query: {
          id: row.goods_id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    StopRoll(index) {
      this.mouindex = index;
    },
    UpRoll() {
      this.mouindex = null;
    },
    addcart(item) {
      this.$api.details
        .addcart({
          id: item.id,
          num: item.starting_sale,
          plan: "3",
        })
        .then((res) => {
          if (res.code == 1) {
            this.$notify({
              title: "成功",
              message: "添加购物车成功",
              type: "success",
            });
            this.getcartlist();
          } else {
            this.$notify.error({
              title: "失败",
              message: res.msg,
            });
          }
        });
    },
    // 购物车列表
    getcartlist(item) {
      this.$api.details.cartlist({}).then((res) => {
        this.cartlist = res.data;
      });
    },
    // 计算选中的总价
    sum() {
      let price = 0;
      let nums = 0;
      this.select_list.forEach((item) => {
        price += item.num * item.price;
      });
      this.totalprice = price;
      this.shuliang = event.length;
    },
    quanxuan(e) {
      if (e) {
        this.$refs.multipleTable.toggleAllSelection();
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 选中的数据
    handleSelectionChange(event) {
      this.select_list = event;
      this.sum();
      this.select_table = event;
      if (event.length == this.cartlist.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    // 统一删除
    del() {
      let ids = "";
      if (this.select_list.length > 0) {
        this.select_list.forEach((item) => {
          if (ids == "") {
            ids = item.id;
          } else {
            ids += "," + item.id;
          }
        });
        this.$api.details
          .delcart({
            id: ids,
          })
          .then((res) => {
            if (res.code == 1) {
              this.getcartlist();
              this.$notify({
                title: "成功",
                message: "删除成功",
                type: "success",
              });
              this.checked = false;
            } else {
              this.$notify.error({
                title: "失败",
                message: res.msg,
              });
            }
          });
      }
    },
    // 同意收藏
    tysc() {
      let ids = "";
      if (this.select_list.length > 0) {
        this.select_list.forEach((item) => {
          if (ids == "") {
            ids = item.goods_id;
          } else {
            ids += "," + item.goods_id;
          }
        });
        this.$api.details
          .collection_add({
            collect_id: ids,
            type: 1,
          })
          .then((res) => {
            if (res.code == 1) {
              this.getcartlist();
              this.$notify({
                title: "成功",
                message: "成功添加收藏",
                type: "success",
              });
            } else {
              this.$notify.error({
                title: "失败",
                message: res.msg,
              });
            }
          });
      }
    },
    // 足迹
    getzuji() {
      this.$api.user
        .goodlist({
          limit: 6,
          page: 1,
          type_id2: 1,
          order_filed: "sale",
        })
        .then((res) => {
          this.colllist = res.data.list;
        });
    },
    // 删除购物车
    delcart(item) {
      this.$api.details
        .delcart({
          id: item.id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.getcartlist();
            this.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
            });
            this.checked = false;
          } else {
            this.$notify.error({
              title: "失败",
              message: "删除失败",
            });
          }
        });
    },
    // 添加收藏
    collection_add(item) {
      console.log(item);
      this.$api.details
        .collection_add({
          collect_id: item.goods_id,
          type: 1,
        })
        .then((res) => {
          if (res.code == 1) {
            this.getcartlist();
            this.$notify({
              title: "成功",
              message: "成功添加收藏",
              type: "success",
            });
          } else {
            this.$notify.error({
              title: "失败",
              message: "添加收藏失败",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.kb {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.tablecart {
  width: 1200px;
  margin: 0 auto;
}
.head {
  background: red;
}
.table_pic {
  width: 150px;
  height: 150px;
  background: #f6f6f6;

  img {
    width: 150px;
    height: 150px;
  }
}
.table {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.table_text {
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #212121;
}
.good_num {
  margin-top: 26px;
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
  .btns {
    display: flex;
    align-items: center;
    span {
      padding: 0 20px;
    }
  }
  .good_num_btn {
    cursor: pointer;
    width: 26px;
    height: 26px;
    background: #ededed;
    border-radius: 13px;
    line-height: 26px;
    text-align: center;
  }
}
.table_cz_textbtn {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #1a1a1a;
  line-height: 2;
  text-align: center;
  cursor: pointer;
}
.table_sc_der {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #999999;
  text-decoration: line-through;
}
.table_sc_pice {
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #e70014;
}
.table_js {
  width: 1200px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px -2px 5px 0px rgba(237, 237, 237, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .table_js_left {
    display: flex;
    align-items: center;
  }
  .table_js_left_checkbox {
    margin-left: 20px;
    margin-right: 80px;
  }
  .table_js_left_textbtn {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #666666;
    margin-right: 30px;
    cursor: pointer;
  }
  .table_js_right {
    display: flex;
    align-items: center;
  }
  .table_js_right_btn {
    cursor: pointer;
    width: 150px;
    height: 40px;
    background: #e70014;
    border-radius: 20px;
    font-size: 18px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #ffffff;
  }
  .table_js_right_good {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #1a1a1a;
    margin-right: 30px;
    span {
      font-size: 18px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #e70014;
    }
  }
  .table_js_right_hj {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #1a1a1a;
    margin-right: 30px;
    span {
      font-size: 24px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #e70014;
    }
  }
}
.browse {
  margin-bottom: 108px;
}
.good_tj {
  width: 1160px;
  margin: 0 auto;
  margin-top: 40px;
  .tj_list {
    width: 180px;
    line-height: 2;
    margin-top: 30px;
    float: left;
    margin-right: 20px;
    .tj_pic {
      width: 180px;
      height: 181px;
      background-color: red;
      img {
        width: 180px;
        height: 181px;
      }
    }
  }
  .tjlistbox {
    width: 1200px;
  }
  .browse_price_cart {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .browse_price {
      font-size: 16px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #e70014;
    }
    .browse_cart {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  .browse_title {
    padding: 0 10px;
  }
}
</style>