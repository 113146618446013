import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../theme/index.css'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

import global from '@/utils/global'
import api from '@/utils/index'
import util from '@/utils/fun'


Vue.use(api)
Vue.prototype.$util=util
Vue.prototype.global = global // 挂载全局配置模块
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
