<template>
  <div>
    <div class="swiper">
      <div class="first">
        <div class="firstlist">
          <ul class="listbox">
            <li
              class="qylist"
              :class="pitchindex == -1 ? 'selectqylist' : ''"
              @click="selectlist(-1, '首页')"
            >
              首页
            </li>
            <li
              class="qylist"
              v-for="(item, index) in classlist"
              :class="pitchindex == item.id ? 'selectqylist' : ''"
              :key="index"
              @click="selectlist(item.id, item)"
            >
              {{ item.name }}
            </li>
            <!-- <li class="qylist">周花</li>
            <li class="qylist">永生花</li> -->
            <li
              class="qylist"
              @click="selectlist(-2, '花语')"
              :class="pitchindex == -2 ? 'selectqylist' : ''"
            >
              花语大全
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungHbNav",
  props: {
    currets: {
      type: Number,
    },
  },
  data() {
    return {
      classlist: "",
      pitchindex: null,
    };
  },
  created() {
    this.pitchindex = this.currets;
    this.getclass();
  },
  mounted() {},
  watch: {
    pitchindex(newQuestion) {
      this.$api.user.goodsClass({}).then((res) => {
        this.classlist = res.data;
        for (let item of this.classlist) {
          if (item.id == newQuestion) {
            this.$emit("change", item.id);
          }
        }
      });
    },
  },
  methods: {
    // 商品分类
    getclass() {},
    // 选择分类
    selectlist(index, item) {
      this.pitchindex = index;
      if (index == -1) {
        this.$router.push("/");
      } else if (index == -2) {
        this.$router.push("/pollen");
      } else {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  background-color: #fff;
}
.first {
  border-bottom: 3px solid #e70014;
  box-sizing: border-box;
  .firstlist {
    width: 1200px;
    margin: 0 auto;
    .listbox {
      display: flex;
      line-height: 3;
      align-items: center;
      font-size: 18px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 25px;
      .alllist {
        width: 255px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 48px;
        text-align: center;
        background: #e70014;
        border-radius: 10px 10px 0px 0px;
        margin-right: 90px;
        color: #fff;
        position: relative;
        img {
          margin-right: 18px;
        }
        .pos {
          position: absolute;
          width: 255px;
          height: 480px;
          background-color: #e70014;
          bottom: -483px;
        }
      }
      .qylist {
        line-height: 2;
        cursor: pointer;
        // margin-right: 110px;
        width: 140px;
        text-align: center;
      }
      .qylist:hover {
        color: #e70014;
      }
      .selectqylist:hover{ 
        color: #fff ;
      }
      .selectqylist {
        background: #e70014;
        border-radius: 12px 12px 0px 0px;
        color: #fff;
      }
    }
  }
  .swiperlsit {
    width: 100%;
    height: 480px;
    img {
      width: 100%;
      height: 480px;
    }
  }
}
</style>