<template>
  <div class="colletion">
    <div class="colletionbox" style="height: 100%">
      <div style="height: 1px"></div>
      <div class="colletion_title">我的收藏</div>
      <div class="listbox">
        <div class="list" v-for="(item, index) in collecyion_list" :key="index">
          <div style="height: 1px"></div>
          <div class="list_pic">
            <img
              :src="$util.img(item.goodsInfo.goods_image)[0]"
              alt=""
              srcset=""
            />
          </div>
          <p class="list_text">{{ item.goodsInfo.name }}</p>
          <p class="list_text">¥{{ item.goodsInfo.price }}</p>
          <div class="list_btn">
            <div class="list_btns list_btns1" @click="cancel(item)">
              取消收藏
            </div>
            <div class="list_btns list_btns2" @click="addcart(item)">
              加入购物车
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungMycolletion",

  data() {
    return {
      collecyion_list: "",
      limit: 10,
      page: 1,
    };
  },

  mounted() {
    this.getlist();
  },

  methods: {
    // 收藏列表
    getlist() {
      this.$api.user.collection_list({}).then((res) => {
        this.collecyion_list = res.data.list;
        console.log(this.collecyion_list);
      });
    },
    // 取消收藏
    cancel(item) {
      this.$api.user
        .collection_del({
          collect_id: item.goodsInfo.id,
          type: 1,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$notify({
              title: "成功",
              message: "取消成功",
              type: "success",
            });
            this.getlist();
          } else {
            this.$notify.error({
              title: "失败",
              message: "取消失败",
            });
          }
        });
    },
    // 添加购物车
    addcart(item) {
      this.$api.details
        .addcart({
          id: item.goodsInfo.id,
          num: item.goodsInfo.starting_sale,
          plan: "3",
        })
        .then((res) => {
          if (res.code == 1) {
            this.$notify({
              title: "成功",
              message: "添加购物车成功",
              type: "success",
            });
            this.getcartlist();
          } else {
            this.$notify.error({
              title: "失败",
              message: res.msg,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.colletion {
  height: 100%;
  .colletionbox {
    background: #fff;
    .colletion_title {
      margin: 30px 40px;
      font-size: 18px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #1a1a1a;
    }
    .listbox {
      margin: 0 40px;
      display: flex;
      flex-wrap: wrap;
      .list {
        width: 167px;
        height: 270px;
        background: #f6f6f6;
        border-radius: 12px;
        margin-right: 10px;
        margin-bottom: 20px;

        .list_pic {
          width: 147px;
          height: 147px;
          background: #d8d8d8;
          border-radius: 12px;
          margin: 10px;
          img {
            width: 147px;
            height: 147px;
            border-radius: 12px;
          }
        }
        .list_text {
          font-size: 16px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #1a1a1a;
          text-align: center;
          line-height: 1.5;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #606b7c;
        }
        .list_btn {
          width: 142px;
          background: #fff3f3;
          margin-top: 10px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #e70014;
          margin: 0 auto;
          display: flex;
          line-height: 32px;
          .list_btns {
            cursor: pointer;
            width: 50%;
            height: 100%;
            text-align: center;
          }
          .list_btns1 {
            border-radius: 16px 0px 0px 16px;
            border: 1px solid #e70014;
            color: #e70014;
          }
          .list_btns2 {
            background: #e70014;
            border-radius: 0px 16px 16px 0px;
            border: 1px solid #e70014;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>