<template>
  <div>
    <div class="img">
      <img :src="item" alt="" srcset=""  v-for="item,index in $util.img(bottom[id])" :key="index"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "PcPcb",

  data() {
    return {
        bottom:'',
        id:''
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.pcBottom();
  },

  methods: {
    pcBottom() {
      this.$api.details.pcBottom({}).then((res) => {
        this.bottom = res.data.bottom;
        console.log(this.$util.img(this.bottom[this.id]));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>