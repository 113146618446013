<template>
  <div>
    <header class="header">
      <div class="first">
        <div class="wx">微信小程序</div>
        <div class="login">
          <div @click="login" v-if="username == ''" class="logins">
            您好，请登陆
          </div>
          <div v-else style="cursor: pointer">
            <span style="color: #e70014" @click="navhy">
              Hello！{{ username }}</span
            >
            <span style="padding-left: 10px" @click="tuichu">退出</span>
          </div>
          <!-- <div class="zhuce" v-if="username == ''">免费注册</div> -->
          <div @click="navorder" style="cursor: pointer">我的订单</div>
        </div>
      </div>
      <!-- 登录 -->
      <el-dialog :visible="loginVisible" :show-close="false">
        <div class="dialog_box">
          <div class="dialog_title">
            <img src="../../assets/head.png" alt="" />
            <div class="guanbi" @click="close">
              <img src="../../assets/cl2x.png" alt="" />
            </div>
          </div>

          <div class="dialog_bt">欢迎登陆/注册</div>
          <div class="dialog_input">
            <div class="inputbox">
              <el-input placeholder="请输入手机号" v-model="mobile">
                <!-- <template slot="prepend">手机号</template> -->
              </el-input>
            </div>
            <div class="inputbox">
              <el-input placeholder="请输入内容" v-model="code">
                <el-button type="text" slot="suffix" @click="smscode" v-if="djs"
                  >获取验证码</el-button
                >
                <el-button type="text" slot="suffix" v-else
                  >{{ djs_time }}秒</el-button
                >
              </el-input>
            </div>
          </div>
          <div class="dialog_checkbox">
            <div class="box">
              <el-checkbox v-model="checked"></el-checkbox>
              <p>
                已阅读并同意<span class="xiyi" @click="navxiyi('agreement')"
                  >用户协议</span
                >
                和
                <span class="xiyi" @click="navxiyi('privacy_policy')"
                  >隐私条款</span
                >
              </p>
            </div>
            <div
              style="
                width: 456px;
                margin: 0 auto;
                line-height: 2;
                font-size: 12px;
              "
            >
              <p>未注册手机号将自动为您创建账号</p>
            </div>
          </div>
          <div class="dialog_btn" @click="userlogin">登录</div>
        </div>
      </el-dialog>
      <div style="width: 100%" class="secondbox">
        <div class="second">
          <div class="logo">
            <router-link to="/">
              <img src="../../assets/logo.png" alt="" />
            </router-link>
          </div>
          <div class="input">
            <div class="box">
              <div style="padding-right: 20px">
                <img
                  src="../../assets/搜索.png"
                  alt=""
                  srcset=""
                  style="width: 20px; height: 20px"
                />
              </div>
              <div class="box_input">
                <input
                  type="text"
                  v-model="keyword"
                  placeholder="搜索商品"
                  @keyup.13="navclass"
                />
              </div>
              <div>
                <button class="box_btn" @click="navclass">搜索</button>
              </div>
            </div>
            <div>
              <ul class="secondlist">
                <li
                  v-for="(item, index) in History_list"
                  :key="index"
                  @click="navfl(item.name)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="cart" @click="navcart">
            <img src=".././../assets/cart.png" alt="" />
            <div>购物车</div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "PctheyoungHbSearch",

  data() {
    return {
      // 手机号
      mobile: "",
      code: "",
      loginVisible: false,
      checked: false,
      // 用户名称
      username: "",
      // 搜索的值
      keyword: "",
      History_list: "",
      djs_time: 60,
      djs: true,
    };
  },

  mounted() {
    if (localStorage.getItem("token")) {
      this.$api.user.info({}).then((res) => {
        this.username = res.data.userInfo.nick;
      });
    }
    this.gethistory();
  },

  methods: {
    navxiyi(event) {
      let routeData = this.$router.resolve({
        path: "xiyi",
        query: {
          type: event,
        },
      });
      window.open(routeData.href, "_blank");
    },
    gethistory() {
      this.$api.user.goodsHistory({}).then((res) => {
        this.History_list = res.data;
      });
    },
    tuichu() {
      localStorage.clear();
      this.$router.push("/");
      location.reload();

    },
    close() {
      this.loginVisible = false;
    },
    login() {
      this.loginVisible = true;
    },
    // 订单
    navorder() {
      if (localStorage.getItem("token")) {
        let routeData = this.$router.resolve({
          path: "order",
        });
        window.open(routeData.href, "_blank");
      } else {
        this.loginVisible = true;
      }
    },
    navfl(event) {
      this.keyword = event;
      let routeData = this.$router.resolve({
        path: "Commodity",
        query: {
          keyword: event,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 搜索
    navclass() {
      if (this.keyword != "") {
        let routeData = this.$router.resolve({
          path: "Commodity",
          query: {
            keyword: this.keyword,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    // 获取验证码
    smscode() {
      if (this.mobile) {
        this.$api.user.smsCode({ mobile: this.mobile }).then((res) => {
          if (res.code == 1) {
            this.djs = false;
            let times_val = setInterval(() => {
              this.djs_time -= 1;
              if (this.djs_time == 0) {
                this.djs_time = 60;
                this.djs = true;
                clearInterval(times_val);
              }
            }, 1000);
          }
        });
      } else {
      }
    },
    // 用户登录
    userlogin() {
      if (this.checked) {
        this.$api.user
          .login({
            captcha: this.code,
            mobile: this.mobile,
          })
          .then((res) => {
            if (res.code == 1) {
              this.username = res.data.userinfo.nick;
              localStorage.setItem("token", res.data.userinfo.token);
              this.loginVisible = false;
              location.reload();
            }
          });
      } else {
        this.$message({
          message: "请勾选同意并阅读使用条款和隐私策略",
          type: "warning",
        });
      }
    },
    navhy() {
      this.$router.push("/home");
    },
    login() {
      this.loginVisible = true;
    },
    search() {
      this.$router.push("Commodity");
    },
    navcart() {
      this.$router.push("cart");
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog_title {
  position: relative;
}
.guanbi {
  position: absolute;
  top: 26px;
  right: 21px;
  img {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}
// 对话框默认样式
::v-deep .el-dialog {
  background: transparent !important;
  box-shadow: 0px 0 0;
}
::v-deep .el-checkbox__inner {
  border-radius: 50% !important;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #e70014;
  border-color: #e70014;
}
::v-deep .el-button--text {
  color: #1a1a1a;
}
.secondbox {
  width: 100%;
  background-color: #ffffff;
}
.inputbox {
  margin-bottom: 30px;
}
.header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .first {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #828181;
    line-height: 2;
    .login {
      display: flex;
      div {
        margin-right: 30px;
        cursor: pointer;
      }
      .zhuce {
        color: #e70014;
      }
    }
  }
  .second {
    width: 1200px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;

    .logo {
      width: 156px;
      height: 56px;
      margin-left: 20px;
    }
    .input {
      .box {
        display: flex;
        align-items: center;
        padding: 0px 30px;
        padding-right: 0px;
        border-radius: 40px;
        border: 1px solid #e70014;
      }
      .box_input > input {
        width: 264px;
        height: 100%;
        font-size: 14px;
        border: none;
        background-color: inherit;
        border-color: none;
        outline: none;
      }
      .box_btn {
        cursor: pointer;
        width: 120px;
        height: 48px;
        background: #e70014;
        border-radius: 24px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        border: none;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }
      .secondlist {
        display: flex;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #828181;
        line-height: 16px;
        margin: 15px 0;
        li {
          margin-right: 30px;
          cursor: pointer;
        }
      }
    }
    .cart {
      width: 130px;
      height: 46px;
      box-shadow: 1px 2px 5px 0px rgba(133, 133, 133, 0.19);
      border-radius: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 26px;
        height: 26px;
        margin-right: 20px;
      }
      div {
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
        line-height: 24px;
      }
    }
  }
}
.dialog_box {
  width: 600px;
  height: 650px;
  background: #ffffff;
  border-radius: 12px;
  margin: 0 auto;
  .dialog_bt {
    font-size: 20px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
    margin: 50px 0 40px 0;
    text-align: center;
  }
  .dialog_input {
    width: 456px;
    margin: 0 auto;
  }
  .dialog_checkbox {
    .box {
      display: flex;
      width: 456px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #1a1a1a;
      margin: 0 auto;
      p {
        margin-left: 20px;
      }
      .xiyi {
        color: #e70014;
      }
    }
  }
  .dialog_btn {
    cursor: pointer;
    width: 456px;
    width: 456px;
    height: 54px;
    background: #e70014;
    border-radius: 27px;
    text-align: center;
    line-height: 54px;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 70px;
  }
}
</style>