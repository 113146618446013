<template>
  <div class="evaluate_box">
    <div
      class="goods"
      v-for="(item, index) in order_data.goods_list"
      :key="index"
    >
      <div class="goods_left">
        <div class="goods_left_box">
          <div class="goods_left_box_pic">
            <img :src="$util.img(item.goods_image)[0]" alt="" srcset="" />
          </div>
          <div class="goods_left_box_name">
            {{ item.goods_name }}
          </div>
          <div class="goods_left_price">
            <strong>¥{{ item.price }}</strong>
          </div>
          <!-- <div class="goods_left_desc">32cm 不粘炒锅 配木铲</div> -->
        </div>
      </div>
      <div class="goods_right_box">
        <div class="form_item">
          <div class="form_label">客服服务：</div>
          <div class="form_desc">
            <el-rate v-model="pj[index].fen_service"></el-rate>
          </div>
        </div>
        <div class="form_item">
          <div class="form_label">产品质量：</div>
          <div class="form_desc">
            <el-rate v-model="pj[index].fen_quality"></el-rate>
          </div>
        </div>
        <div class="form_item">
          <div class="form_label">物流速度：</div>
          <div class="form_desc">
            <el-rate v-model="pj[index].fen_logistics"></el-rate>
          </div>
        </div>
        <div class="form_item">
          <div class="form_label">评价晒单:</div>
          <div class="form_desc">
            <el-input
              type="textarea"
              placeholder="分享体验心得，给万千想买的人一个参考~"
              v-model="pj[index].content"
              maxlength="500"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="gooods_btn">
      <div class="btn" @click="fabiao">发表评价</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PcEvaluate",

  data() {
    return {
      form: {},
      value1: "",
      textarea: "",
      order_id: "",
      order_data: "",
      pj: [],
    };
  },

  mounted() {
    this.order_id = this.$route.query.id;
    this.getorder();
  },

  methods: {
    //   获取订单信息
    getorder() {
      this.$api.details.order_info({ order_id: this.order_id }).then((res) => {
        this.order_data = res.data;
        this.order_data.goods_list.forEach((item) => {
          let obj = {};
          obj.fen_service = "";
          obj.fen_quality = "";
          obj.fen_logistics = "";
          obj.goods_id = item.id;
          obj.order_id = this.order_id;
          obj.order_goods_id = item.id;
          obj.images = "";
          obj.content = "";
          this.pj.push(obj);
        });
      });
    },
    fabiao() {
    //   this.pj.forEach(item=>{
    //       for(let itema in item){
    //           if()
    //       }
    //   })
      this.$api.details
        .order_comment({
          order_id: this.order_id,
          info: this.pj,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$notify({
              title: "成功",
              message: "评价成功",
              type: "success",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: res.msg,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.evaluate_box {
  width: 1200px;
  margin: 0 auto;
  .goods {
    display: flex;
  }
  .goods_left {
    width: 322px;
    height: 285px;
    border-right: 1px solid #f5f5f5;
    .goods_left_box {
      text-align: center;
      padding: 55px 50px;
      .goods_left_box_pic {
        img {
          width: 100px;
          height: 100px;
        }
      }
      .goods_left_box_name {
        line-height: 1.5;
      }
      div {
        margin-bottom: 10px;
      }
    }
  }
  .goods_right_box {
    padding: 30px 40px;
    width: 807px;
    .form_item {
      width: 807px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .form_label {
        width: 100px;
        margin-right: 20px;
      }
      .form_desc {
        width: 400px;
      }
    }
  }
}
.gooods_btn {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.btn {
  width: 220px;
  height: 48px;
  background-color: #e70014;
  text-align: center;
  color: #fff;
  line-height: 48px;
  padding: 0;
  font-family: "Microsoft YaHei";
  font-size: 18px;
}
</style>