<template>
  <div>
    <footer class="footer">
      <div class="introduce">
        <div class="introduce_left">
          <div class="introduce_list">
            <ul class="introduce_list_ul">
              <router-link to="/">
              <li>首页</li>
              </router-link>
              <li class="shuxian"></li>
              <li @click="navs('about')">关于小生</li>
              <li class="shuxian"></li>
              <li @click="navs('connect')">联系方式 </li>
              <li class="shuxian"></li>
              <li @click="navs('ruzhu')">花店入驻 </li>
            </ul>
          </div>
          <div class="introduce_text">
            <!-- <p>中国花卉协会会员 中国电子商务协会会员</p> -->
            <p>
              ICP经营许可证：
              京ICP备2022004420号 
            </p>
            <p>小生不才鲜花网  北京小生不才电子商务有限公司</p>
            <!-- <p>中国领先的原创花艺鲜花网，品牌鲜花网服务，鲜花速递专家！</p> -->
          </div>
        </div>
        <div class="introduce_right">
          <div class="ewm_1" style="marign-right: 60px">
            <div class="wx_ewm">
              <img src="../../assets/wx.jpg" alt="">
            </div>
            <p>微信小程序</p>
          </div>
          <div class="ewm_2">
            <div class="wx_ewm">
              <img src="../../assets/gzh.jpg" alt="">
            </div>
            <p>关注公众号</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "PctheyoungHbFooter",

  data() {
    return {};
  },

  mounted() {

  },

  methods: {
    navs(event){
       let routeData = this.$router.resolve({
        path: "pcb",
        query: {
          id:event
        },
      });
      window.open(routeData.href, "_blank");
    }
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background-color: #000000;

  height: 370px;
  display: flex;
  .introduce {
    width: 1200px;
    margin: 0 auto;
    color: #fff;
    margin-top: 84px;
    margin-bottom: 102px;
    display: flex;
    justify-content: space-between;
    .introduce_left {
      .introduce_list {
        width: 780px;
        margin-bottom: 17px;
        .introduce_list_ul {
          display: flex;
          cursor: pointer;
          li {
            margin-right: 60px;
            font-size: 24px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #ffffff;
          }
          .shuxian {
            width: 1px;
            height: 20px;
            background: #ffffff;
          }
        }
      }
      .introduce_text {
        line-height: 2;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .introduce_right {
      display: flex;
      .ewm_1 {
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        margin-right: 60px;
        .wx_ewm {
          width: 150px;
          height: 150px;
          background: #f6f6f6;
          img{
            width: 150px;
            height: 150px;
          }
        }
        p {
          margin-top: 12px;
        }
      }
      .ewm_2 {
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        .wx_ewm {
          width: 150px;
          height: 150px;
          background: #f6f6f6;
          img{
             width: 150px;
            height: 150px;
          }
        }
        p {
          margin-top: 12px;
        }
      }
    }
  }
}
</style>