<template>
  <div style="width: 100%">
    <hb-nav @change="test" :currets="class_id"></hb-nav>
    <div class="layerbox">
      <div class="breadcrumb">
        <div class="breadcrumb_list">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>鲜花</el-breadcrumb-item>
            <!-- <el-breadcrumb-item>你是唯一 系列2</el-breadcrumb-item> -->
          </el-breadcrumb>
        </div>
      </div>
      <div class="filterlist">
        <div class="flists" v-for="(item, index) in child_list" :key="index">
          <p style="padding-right: 20px">{{ item.name }}:</p>
          <ul class="flists_btn_box">
            <li
              class="flists_btn"
              v-for="(itema, indexa) in item.child"
              :key="indexa"
              @click="fllist(itema.id)"
              :class="fllistindex == itema.id ? 'select' : ''"
            >
              {{ itema.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="condition">
        <ul class="condition_list">
          <li
            @click="condition('all')"
            :class="condtext == 'all' ? 'selecttext' : ''"
          >
            综合
          </li>
          <li
            @click="condition('sale')"
            :class="condtext == 'sale' ? 'selecttext' : ''"
          >
            销量
          </li>
          <li
            @click="condition('price')"
            :class="condtext == 'price' ? 'selecttext' : ''"
            v-if="type_id != 1"
          >
            价格
          </li>
          <li
            @click="condition('new')"
            :class="condtext == 'new' ? 'selecttext' : ''"
          >
            最新
          </li>
        </ul>
      </div>
      <div class="good">
        <div
          class="good_list"
          v-for="(item, index) in goodlist.list"
          :key="index"
          @click="navdetails(item)"
        >
          <div class="good_list_pic">
            <img :src="$util.img(item.goods_image)[0]" alt="" />
          </div>
          <div class="good_text">
            <div class="good_text_price">
              <span>￥{{ item.price }}</span>
            </div>
            <div class="good_text_title">{{ item.name }}</div>
            <div class="good_text_hc">{{ item.flower_desc }}</div>
            <!-- <div class="good_text_cart" @click.stop="addcart(item)">
              加入购物车
            </div> -->
          </div>
        </div>
      </div>
      <div class="pages">
        <el-pagination
          background
          layout="prev, pager, next,jumper"
          :total="goodlist.total"
          :page-size="limit"
          :current-page="page"
          @current-change="change"
        >
          <span slot="prev">
            <i class="el-icon-arrow-left btn"></i> 上一页</span
          >
          <span slot="next"
            ><i class="el-icon-arrow-right btn"></i> 上一页</span
          >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import hbNav from "@/components/hb_nav/hb_nav.vue";

export default {
  name: "PctheyoungCommodity",
  components: {
    "hb-nav": hbNav,
  },
  data() {
    return {
      list: [],
      color: {
        name: "颜色：",
        list: [],
      },
      fllistindex: "",
      goodlist: [],
      limit: 8,
      page: 1,
      order_filed: "all",
      order_value: "1",
      category_id: "",
      type_id: "",
      condtext: "all",
      // class_id 选中的classid
      class_id: null,
      goodsname: "",
      child_list: [],
    };
  },

  created() {
    this.class_id = this.$route.query.classid;
    this.fllistindex = this.$route.query.goodid;
    this.category_id = this.$route.query.goodid;
    this.goodsname = this.$route.query.keyword;
  },
  mounted() {
    this.getgoodslist();
  },
  watch: {
    $route(to, from) {
      this.goodsname = to.query.keyword;
      this.getgoodslist();
    },
  },
  methods: {
    // 商品分类
    getgoodslist() {
      this.$api.user
        .goodlist({
          limit: this.limit,
          page: this.page,
          order_filed: this.order_filed,
          order_value: this.order_value,
          category_id: this.category_id,
          type_id: this.type_id,
          goods_name: this.goodsname,
        })
        .then((res) => {
          this.goodlist = res.data;
        });
    },
    // 添加购物车
    addcart(item) {
      console.log(item);
      this.$api.details
        .addcart({
          id: item.id,
          num: item.sale_num == 0 ? 1 : item.sale_num,
          plan: "3",
        })
        .then((res) => {
          if (res.code == 1) {
            this.$notify({
              title: "成功",
              message: "添加购物车成功",
              type: "success",
            });
          } else {
            this.$notify.error({
              title: "失败",
              message: res.msg,
            });
          }
        });
    },
    // 详情
    navdetails(item) {
      let routeData = this.$router.resolve({
        path: "details",
        query: {
          id: item.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    change(event) {
      this.page = event;
      this.getgoodslist();
    },
    test(index) {
      this.type_id = index;
      // this.fllistindex = "";
      this.category_id = "";
      this.$router.push("Commodity");
      this.$api.details
        .list_screen({
          type_id: this.type_id,
        })
        .then((res) => {
          this.child_list = res.data;
        });
      this.getgoodslist();
    },
    condition(index) {
      this.condtext = index;
      this.order_filed = index;
      this.getgoodslist();
    },
    fllist(id) {
      this.fllistindex = id;
      this.category_id = id;
      this.getgoodslist();
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  color: #e70014 !important;

  background: #fff3f3 !important;
}
.selecttext {
  color: #e70014 !important;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #e70014;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e70014;
  color: #fff;
}
::v-deep .el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #e70014;
  outline: 0;
}
.layerbox {
  width: 1200px;
  margin: 0 auto;
}
.breadcrumb {
  padding: 14px 0;
  border-bottom: 1px solid #ededed;
}
.filterlist {
  margin: 22px 0 35px 0;

  .flists {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #e70014;
    margin-bottom: 20px;

    .flists_btn_box {
      display: flex;
      align-items: center;
    }
    .flists_btn {
      cursor: pointer;
      margin-right: 30px;
      // width: 64px;
      text-align: center;
      padding: 4px 24px;
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1a1a1a;
      border-radius: 12px;
    }
    .color {
      display: flex;
    }
    .colorlists {
      width: 40px;
      height: 24px;
      background: linear-gradient(
        180deg,
        #f31dff 8%,
        #33bdff 24%,
        #50ffb2 40%,
        #92ff6e 57%,
        #ffef4a 72%,
        #ff7125 87%,
        #ff0000 100%
      );
      margin-right: 10px;

      border-radius: 4px;
    }
    .colorlist {
      width: 40px;
      height: 24px;
      margin-right: 10px;
      border-radius: 4px;
    }
  }
}
.condition {
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .condition_list {
    display: flex;
    li {
      cursor: pointer;
      line-height: 3;
      padding: 0 20px;
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1a1a1a;
    }
  }
  .condition_page {
    line-height: 3;

    p {
      display: flex;
      align-items: center;
      margin-right: 10px;
      span {
        margin-right: 10px;
      }
    }
    .btn {
      width: 34px;
      height: 34px;
      background-color: #fff;
      line-height: 34px;
      text-align: center;
      font-size: 24px;
    }
  }
}
.good {
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  .good_list {
    cursor: pointer;
    margin-right: 13px;
    margin-bottom: 50px;
    .good_list_pic {
      width: 285px;
      height: 285px;
      background-color: #e70014;
      img {
        width: 285px;
        height: 285px;
      }
    }

    .good_text {
      padding: 0 20px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #666666;
      .good_text_price {
        font-size: 22px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #e70014;
        margin: 10px 0;
      }
      .good_text_title {
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #e70014;
        margin-bottom: 10px;
        overflow: hidden;
        max-width: 245px;

        text-overflow: ellipsis;

        white-space: nowrap;
      }
      .good_text_hc {
        max-width: 245px;
        font-size: 14px;
        min-height: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #e70014;
        margin-bottom: 20px;
        overflow: hidden;
        max-width: 245px;

        text-overflow: ellipsis;

        white-space: nowrap;
      }
      .good_text_cart {
        cursor: pointer;
        width: 245px;
        height: 32px;
        background: #f6f6f6;
        border-radius: 20px;
        border: 1px solid #ededed;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
      }
    }
  }
  .good_list:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.pages {
  float: right;
  margin-bottom: 80px;
}
</style>