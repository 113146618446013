<template>
  <div>
    <!-- 导航 -->
    <div>
      <hb-nav @change="navclass"></hb-nav>
      <div class="breadcrumb">
        <div class="breadcrumb_list">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ gettype }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ gooddetails.name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="good">
        <div class="good_left">
          <el-image
            :src="selectpic.url"
            style="width: 500px; height: 500px"
            v-if="selectpic.type == 'img'"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <video
            v-if="selectpic.type == 'mp4'"
            src="https://flower.csfeng.com/upload/video/3e/ff511774460944d50d0cf5b3a7b56d7f8f920d.mp4"
            controls
            autoplay
            style="width: 500px; height: 500px"
          ></video>
        </div>
        <div class="good_right">
          <div class="good_title">
            <span class="good_title_blod">{{ gooddetails.name }}</span>
          </div>
          <div class="good_title_red">【{{ gooddetails.selling_point }}】</div>
          <div class="good_hy">{{ gooddetails.flower_language }}</div>
          <div class="good_price">
            <div>
              <div style="height: 0.1px"></div>
              <div class="ls_price">
                零售价：<span>￥{{ price }}</span>
              </div>
              <div class="sc_price">
                市场价：<span>¥{{ scribing_price }}</span>
              </div>
            </div>
            <div class="scmun">
              <p>已售单数</p>
              <p>{{gooddetails.sale_num}}</p>
            </div>
          </div>
          <div class="good_hc good_describe" v-if="gooddetails.flower_desc">
            <div class="title">花材：</div>
            <div class="label">{{ gooddetails.flower_desc }}</div>
          </div>
          <div class="good_bz good_describe" v-if="gooddetails.packing_desc">
            <span>包装：</span>
            <span> {{ gooddetails.packing_desc }}</span>
          </div>
          <div class="good_ps good_describe">
            <span>配送：</span>
            <span style="color: #f68000">需提前3天预定</span>
          </div>
          <div class="good_ps good_describe">
            <span style="padding-right: 10px" v-if="gooddetails.type == 3"
              >送&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;至:</span
            >
            <span style="padding-right: 10px" v-else>送至:</span>

            <span>
              <el-cascader
                placeholder="可搜索"
                :options="options"
                filterable
                size="small"
                @change="area"
              ></el-cascader
            ></span>
          </div>
          <div class="good_ps good_describe" v-if="gooddetails.type == 3">
            <span>订几个月：</span>
            <el-select
              v-model="months"
              placeholder="请选择订购周期"
              size="small"
            >
              <el-option
                v-for="item in yue_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="good_ps good_describe" v-if="gooddetails.type == 3">
            <span>配送日期：</span>
            <el-select
              v-model="date_time"
              placeholder="请选择配送日期："
              size="small"
            >
              <el-option
                v-for="item in date_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="good_num">
            <span>数量：</span>
            <div class="btns">
              <div class="good_num_btn" @click="reduce">-</div>
              <span>{{ cartnum }}</span>
              <div class="good_num_btn" @click="add">+</div>
            </div>
          </div>
          <div class="good_btn">
            <div class="btns">
              <button
                class="smallbtn"
                @click="addgwc"
                v-if="gooddetails.type != 3"
              >
                加入购物车
              </button>
              <button class="smallbtn1" @click="ljgm">立即购买</button>
            </div>
            <div class="collection" @click="collection">
              <div class="crice">
                <img :src="issc ? xin.red : xin.white" alt="" srcset="" />
              </div>
              {{ issc ? "已收藏" : "未收藏" }}
            </div>
          </div>
        </div>
      </div>
      <div class="scoll">
        <div class="scollbox">
          <div
            class="scollboxlist"
            v-for="(item, index) in goodpic"
            :key="index"
            @click="selectimg(item)"
          >
            <img
              :src="item.url"
              alt=""
              style="width: 100px; height: 99px"
              v-if="item.type == 'img'"
            />
            <video
              v-if="item.type == 'mp4'"
              :src="item.url"
              style="width: 100px; height: 99px"
            ></video>
          </div>
        </div>
      </div>
      <div class="good_tj">
        <h1>相关推荐</h1>
        <div class="tjlistbox">
          <div
            class="tj_list"
            v-for="(item, index) in xg_list"
            :key="index"
            @click="navdetails(item)"
          >
            <div class="tj_pic">
              <img :src="$util.img(item.goods_image)[0]" alt="" />
            </div>
            <div>{{ item.name }}</div>
            <div>¥{{ item.price }}</div>
          </div>
        </div>
      </div>
      <div class="good_tab">
        <el-tabs type="border-card">
          <el-tab-pane label="商品详情">
            <div
              v-for="(item, index) in $util.img(content)"
              :key="index"
              style="display: flex; flex-wrap: wrap; justify-content: center"
            >
              <img :src="item" alt="" srcset="" />
            </div>
            <div
              v-for="(item, index) in $util.img(temadd)"
              :key="index"
              style="display: flex; flex-wrap: wrap; justify-content: center"
            >
              <img :src="item" alt="" srcset="" />
            </div>
          </el-tab-pane>
          <el-tab-pane :label="'用户评价(' + comment_list.length + ')'">
            <div class="good_evaluate">全部评价</div>
            <div class="evaluatebox">
              <div
                class="evaluatelist"
                v-for="(itema, indexa) in comment_list"
                :key="indexa"
              >
                <div class="evaluatelist_avatr">
                  <el-avatar
                    :size="50"
                    :src="itema.userInfo.avatar"
                  ></el-avatar>
                  <!-- <p>139****6682</p> -->
                </div>
                <div class="evaluatelist_content">
                  <div class="evaluatelist_content_df">
                    <div class="evaluatelist_content_df_fraction">
                      打分：<el-rate
                        v-model="value"
                        disabled
                        show-score
                        text-color="#ff9900"
                        score-template="{value}"
                      >
                      </el-rate>
                    </div>
                    <div class="evaluatelist_content_df_list">
                      配送:5分 包装:5分
                    </div>
                  </div>
                  <div class="evaluatelist_content_nr">
                    {{ itema.content }}
                  </div>
                  <div class="evaluatelist_content_img">
                    <div
                      class="evaluatelist_content_img_list"
                      v-for="(item, index) in $util.img(itema.images)"
                      :key="index"
                      @click="evaluatelist(indexa, item)"
                    >
                      <img :src="item" alt="" srcset="" />
                    </div>
                    <div class="good_img_pic" v-if="itema.ylpic != ''">
                      <img :src="itema.ylpic" alt="" />
                    </div>
                  </div>
                  <div class="evaluatelist_content_time">
                    <div>
                      <!-- <span class="evaluatelist_content_time_log"
                        >河北石家庄裕华区</span
                      > -->
                      <span class="evaluatelist_content_date">{{
                        itema.update_time
                      }}</span>
                    </div>
                    <div class="is_zan" @click="zanevent(itema)">
                      <img
                        :src="itema.is_zan == 1 ? xin.red : xin.white"
                        alt=""
                        srcset=""
                      />
                      {{ itema.zan_num }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import hbNav from "@/components/hb_nav/hb_nav.vue";
export default {
  name: "PctheyoungCommodity",
  components: {
    "hb-nav": hbNav,
  },
  data() {
    return {
      content: "",
      xin: {
        white: require("../assets/wxin.png"),
        red: require("../assets/rxin.png"),
      },
      value: 3.7,
      options: [],
      yue_options: [],
      date_options: [
        {
          label: "周一配送",
          value: 1,
        },
        {
          label: "周六配送",
          value: 2,
        },
      ],
      date_time: "",
      gooddetails: "",
      months: "",
      goodpic: [],
      cityid: "",
      price: "",
      content: "",
      // 购物车
      cartnum: 0,
      minnum: 0,
      // 选中的图片
      selectpic: "",
      // 评论
      comment_list: "",
      limit: 5,
      page: 1,
      total: 0,
      comment_list_pic: "",
      // 收藏
      issc: false,
      // 评价
      iszan: false,
      good_id: "",
      temadd: "",
      scribing_price: "",
      xg_list: "",
      good_video: "",
    };
  },
  computed: {
    gettype() {
      let str = "";
      if (this.gooddetails.type == 1) {
        // 1：鲜花，2：永生花，3：周花，4：鲜花周边
        str = "鲜花";
      } else if (this.gooddetails.type == 2) {
        str = "永生花";
      } else if (this.gooddetails.type == 3) {
        str = "周花";
      } else {
        str = "鲜花周边";
      }
      return str;
    },
  },
  mounted() {
    this.good_id = this.$route.query.id;
    this.getarea();
    this.getdetail();
    this.getcomment_list();
    this.getis_collection();
  },

  methods: {
    navclass(event) {
      let routeData = this.$router.resolve({
        path: "Commodity",
        query: {
          classid: event,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 相关推荐
    getxg() {
      console.log(this.gooddetails.type);
      this.$api.user
        .goodlist({
          limit: 6,
          page: 1,
          type_id2: this.gooddetails.type,
          order_filed: "sale",
        })
        .then((res) => {
          console.log(res);
          this.xg_list = res.data.list;
        });
    },
    // 相关详情
    navdetails(item) {
      let routeData = this.$router.resolve({
        path: "details",
        query: {
          id: item.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 获取地区
    getarea() {
      this.$api.details.arealist({}).then((res) => {
        this.options = res.data;
      });
    },
    // 商品详情
    getdetail() {
      this.$api.details.detail({ id: this.good_id }).then((res) => {
        this.gooddetails = res.data;
        this.price = res.data.price;
        this.scribing_price = res.data.scribing_price;
        // 购物车数量
        this.cartnum = res.data.starting_sale == 0 ? 1 : res.data.starting_sale;
        this.minnum = res.data.starting_sale;

        // 处理图片
        // this.goodpic = this.$util.img(res.data.goods_image);
        this.$util.img(res.data.goods_image).forEach((item) => {
          let obj = {};
          obj.url = item;
          obj.type = "img";
          this.goodpic.push(obj);
        });
        this.$util.img(res.data.video_url).forEach((item) => {
          let obj = {};
          obj.url = item;
          obj.type = "mp4";
          this.goodpic.push(obj);
        });
        this.selectpic = this.goodpic[0];
        // 处理富文本

        this.content = res.data.info;
        this.temadd = res.data.templateAdd;
        // 订几个月选择
        let obj = {};
        if (res.data.flower_type == 4) {
          obj.label = "4束/1个月";
          obj.value = 4;
          // 4：4束/1个月,12：12束/三个月，24：24束/6个月，48：12个月/48束
        } else if (res.data.flower_type == 12) {
          obj.label = "12束/三个月";
          obj.value = 12;
        } else if (res.data.flower_type == 24) {
          obj.label = "24束/6个月";
          obj.value = 24;
        } else if (res.data.flower_type == 48) {
          obj.label = "12个月/48束";
          obj.value = 48;
        }
        this.yue_options.push(obj);
        this.getxg();
      });
    },
    // 加入购物车
    addgwc() {
      if (localStorage.getItem("token")) {
        this.$api.details
          .addcart({
            id: this.good_id,
            num: this.cartnum,
            plan: "3",
          })
          .then((res) => {
            if (res.code == 1) {
              this.$notify({
                title: "成功",
                message: "添加购物车成功",
                type: "success",
              });
            } else {
              this.$notify.error({
                title: "失败",
                message: res.msg,
              });
            }
          });
      } else {
        this.$parent.islogin();
      }
    },
    // 立即购买
    ljgm() {
      if (this.gooddetails.type == 3) {
        if (localStorage.getItem("token")) {
          if (this.date_time !== "" && this.months !== "") {
            this.$router.push({
              name: "Confirmorder",
              query: {
                sub_type: " 1",
                sub_ids: this.good_id,
                plan: this.date_time,
                sub_num: this.cartnum,
              },
            });
          } else {
            if (this.months == "") {
              this.$message({
                message: "请选择订购周期",
                type: "warning",
              });
            } else if (this.date_time == "") {
              this.$message({
                message: "请选择配送日期",
                type: "warning",
              });
            }
            // this.$refs.pronbit.scrollIntoView();
          }
        } else {
          this.$parent.islogin();
        }
      } else {
        if (localStorage.getItem("token")) {
          this.$router.push({
            name: "Confirmorder",
            query: {
              sub_type: " 1",
              sub_ids: this.good_id,
              plan: this.date_time,
              sub_num: this.cartnum,
            },
          });
        } else {
          this.$parent.islogin();
        }
      }
    },
    // 数量减
    reduce() {
      console.log(this.cartnum, this.minnum);
      if (this.cartnum > this.minnum) {
        this.cartnum -= 1;
      }
    },
    add() {
      this.cartnum += 1;
    },
    // 选择地区
    area(event) {
      this.$api.details
        .goods_price_area({ goods_id: this.good_id, city_id: event[1] })
        .then((res) => {
          this.price = res.data.price;
          this.scribing_price = res.data.scribing_price;
        });
    },
    // 选择图片
    selectimg(item) {
      this.selectpic = item;
    },
    // 商品评价
    getcomment_list() {
      this.$api.details
        .comment_list({
          is_all: 1,
          goods_id: this.good_id,
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          this.comment_list = res.data.list;
          for (let item of this.comment_list) {
            this.$set(item, "ylpic", "");
          }
          this.total = res.data.total;
        });
    },
    //查看评论图片
    evaluatelist(indexa, item) {
      this.comment_list.forEach((items) => {
        items.ylpic = "";
      });
      this.comment_list[indexa].ylpic = item;
    },
    // 点赞
    zanevent(event) {
      if (localStorage.getItem("token")) {
        if (event.is_zan == 0) {
          this.$api.details
            .comment_zan({
              id: event.id,
            })
            .then((res) => {
              if (res.code == 1) {
                event.is_zan = 1;
                event.zan_num += 1;
              }
            });
        } else {
          this.$api.details
            .comment_zan({
              id: event.id,
            })
            .then((res) => {
              if (res.code == 1) {
                event.is_zan = 0;
                event.zan_num -= 1;
              }
            });
        }
      } else {
        this.$parent.islogin();
      }
    },
    // 添加收藏
    collection() {
      if (localStorage.getItem("token")) {
        if (this.issc) {
          this.$api.details
            .collection_del({
              collect_id: this.good_id,
              type: 1,
            })
            .then((res) => {
              if (res.code == 1) {
                this.issc = false;
              }
            });
        } else {
          this.$api.details
            .collection_add({
              collect_id: this.good_id,
              type: 1,
            })
            .then((res) => {
              if (res.code == 1) {
                this.issc = true;
              }
            });
        }
      } else {
        this.$parent.islogin();
      }
    },
    // 是否收藏
    getis_collection() {
      this.$api.details
        .is_collection({
          collect_id: this.good_id,
          type: 1,
        })
        .then((res) => {
          if (res.data.is_collection == 1) {
            this.issc = true;
          } else {
            this.issc = false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.is_zan {
  img {
    width: 18px;
  }
}
.ls_price {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #1a1a1a;
  margin-left: 20px;
  // margin-top: 22px;
  span {
    font-size: 28px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #e70014;
    padding-left: 10px;
  }
}
.sc_price {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #1a1a1a;
  margin-left: 20px;
  margin-top: 22px;
  span {
    text-decoration: line-through;
    padding-left: 10px;
  }
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #fff;
  background-color: #e70014;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #909399;
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: #f5f7fa;
  border-bottom: 1px solid #e70014;
  margin: 0;
}
::v-deep .el-tabs--border-card {
  background: #fff;
  border: 0px;
  box-shadow: 0px 0px 0px;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
.breadcrumb {
  width: 100%;
  .breadcrumb_list {
    margin: 14px auto;
    width: 1200px;
  }
}
.good {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  .good_left {
    width: 500px;
    height: 500px;
    img {
      width: 500px;
      height: 500px;
    }
  }
  .good_right {
    margin-left: 32px;
    .good_title {
      display: flex;
      align-items: center;
      margin-top: 27px;
      .good_title_blod {
        font-size: 24px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #1a1a1a;
      }
    }
    .good_title_red {
      font-size: 18px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #e70014;
      // margin-left: 20px;
      margin-top: 10px;
    }
    .good_hy {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 16px;
    }
    .good_price {
      width: 668px;
      height: 101px;
      background: #fff3f3;
      margin-top: 21px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .scmun {
        // margin-right: 20px;
        padding: 0 30px;
        text-align: center;
        line-height: 1.5;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
        border-left: 1px solid #999999;
      }
    }
    .good_describe {
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #1a1a1a;
      margin-top: 10px;
    }
    .good_hc {
      margin-top: 23px;
      display: flex;
      .label{
        width: 90%;
      }
    }
    .good_num {
      margin-top: 26px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #666666;
      display: flex;
      align-items: center;
      .btns {
        display: flex;
        align-items: center;
        span {
          padding: 0 20px;
        }
      }
      .good_num_btn {
        width: 26px;
        height: 26px;
        background: #ededed;
        border-radius: 13px;
        line-height: 26px;
        text-align: center;
        cursor: pointer;
      }
    }
    .good_btn {
      margin-top: 29px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btns {
        .smallbtn {
          width: 210px;
          height: 48px;
          background: #fff3f3;
          border-radius: 24px;
          margin-right: 20px;
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #e70014;
          cursor: pointer;
        }
        .smallbtn1 {
          width: 210px;
          height: 48px;
          cursor: pointer;

          background: #e70014;
          border-radius: 24px;
          margin-right: 20px;
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .collection {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
        .crice {
          width: 32px;
          height: 32px;
          background: #fff3f3;
          border-radius: 16px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 18px;
          }
        }
      }
    }
  }
}
.scoll {
  width: 1200px;
  margin: 0 auto;
  margin-top: 22px;
  .scollbox {
    width: 500px;
    white-space: nowrap;
    height: 99px;
    ::-webkit-scrollbar {
      display: none;
    }
    .scollboxlist {
      cursor: pointer;
      width: 100px;
      height: 99px;
      display: inline-block;
      margin-right: 20px;
    }
  }
}
.good_tj {
  width: 1160px;
  margin: 0 auto;
  margin-top: 40px;
  .tj_list {
    margin-right: 20px;
    cursor: pointer;
    width: 180px;
    text-align: center;
    line-height: 2;
    margin-top: 30px;
    .tj_pic {
      width: 180px;
      height: 181px;
      img {
        width: 180px;
        height: 181px;
      }
    }
  }
  .tjlistbox {
    display: flex;
    // justify-content: space-between;
  }
}
.good_tab {
  width: 1200px;
  margin: 0 auto;
  .good_evaluate {
    width: 1200px;
    height: 48px;
    background: #f6f6f6;
    border: 1px solid #ededed;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 48px;
    padding-left: 20px;
    margin-top: 30px;
  }
  .evaluatebox {
    margin-bottom: 65px;
  }
  .evaluatelist {
    display: flex;
    align-items: flex-start;
    margin-top: 65px;
    .evaluatelist_avatr {
      margin-left: 50px;
      display: flex;
      align-items: center;
      p {
        margin-left: 20px;
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
      }
    }
    .evaluatelist_content_df {
      display: flex;
      align-items: center;
    }
    .evaluatelist_content_df_fraction {
      display: flex;
      align-items: center;
    }
    .evaluatelist_content_df_list {
      margin-left: 20px;
    }
    .evaluatelist_content_nr {
      margin-top: 32px;
    }
    .evaluatelist_content {
      width: 100%;
      margin-left: 40px;
      .evaluatelist_content_img {
        margin-top: 30px;
        width: 380px;
        display: flex;
        flex-wrap: wrap;
        .evaluatelist_content_img_list {
          width: 104px;
          height: 105px;
          background: #d8d8d8;
          margin-right: 20px;
          margin-bottom: 20px;
          img {
            width: 104px;
            height: 105px;
          }
        }
        .good_img_pic {
          width: 430px;
          img {
            width: 430px;
          }
        }
      }
    }
    .evaluatelist_content_time {
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #666666;
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .evaluatelist_content_time_log {
        margin-right: 40px;
      }
    }
  }
}
</style>