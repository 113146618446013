<template>
  <div style="width: 100%">
    <div class="Confirmorderbox">
      <div class="ordertile">
        <div style="margin-left: 20px">收货人信息</div>
      </div>
      <div class="consignee padleft">
        <div class="address">选择收货人地址</div>
        <div class="addresslist">
          <div class="list">
            <div class="listbox">
              <div class="address_name">
                {{ orderdata.address_info.name?orderdata.address_info.name:'' }}收
                <span>{{ orderdata.address_info.phone }}</span>
              </div>
              <div class="address_dz">
                {{ orderdata.address_info.detail_txt }}
              </div>
              <!-- <div class="address_modily">修改</div> -->
            </div>
          </div>
        </div>
        <div style="margin-top: 30px; margin-bottom: 50px; display: flex">
          <div class="increaseaddress" @click="addadress">添加新地址</div>
          <div class="increaseaddress" @click="selectadress">选择其他地址</div>
        </div>

        <el-dialog
          title="新增收货地址"
          :visible.sync="dialogVisible"
          width="1060px"
          :before-close="handleClose"
        >
          <div class="dialog_box">
            <el-form ref="form" :model="adress_form" label-width="120px">
              <el-form-item label="省市区:">
                <el-cascader
                  v-model="adress_form.value"
                  :options="options"
                  ref="cascaderMallCatergory"
                  @change="xzaddress"
                  placeholder="请选择省/市/区/街道"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="详细地址:">
                <el-input
                  type="textarea"
                  v-model="adress_form.desc"
                  placeholder="请输入详细地址信息，如道路、门牌号、小区"
                ></el-input>
              </el-form-item>
              <el-form-item label="收货人姓名:">
                <el-input
                  v-model="adress_form.name"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机号码:">
                <el-input
                  v-model="adress_form.phone"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="address_checked"
                  >设置为默认地址</el-checkbox
                >
              </el-form-item>
            </el-form>
            <div class="btnssbox">
              <div class="btnss" @click="quxiao">取消</div>
              <div class="btnss btnred" @click="address">保存</div>
            </div>
          </div>
        </el-dialog>
        <el-dialog
          title="选择收货地址"
          :visible.sync="sdialogVisible"
          width="1060px"
          :before-close="handleClose"
        >
          <el-table
            ref="multipleTable"
            :data="addresslist"
            tooltip-effect="dark"
            highlight-current-row
            @current-change="handleCurrentChange"
            style="width: 100%"
            border
            :header-cell-style="{
              background: '#F5F5FA',
              color: '#1A1A1A',
              'font-weight': 'bold',
              'font-size': '14px',
              border: 'none',
            }"
          >
            <el-table-column label="收货人">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="联系电话">
              <template slot-scope="scope">
                <div class="table_sc_der">
                  {{ scope.row.phone }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="收货地址"
              prop="detail_txt"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </el-dialog>
      </div>
      <div class="ordertile"><div style="margin-left: 20px">购物清单</div></div>
      <el-table
        ref="multipleTable"
        :data="orderdata.goods_list"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          color: '#1A1A1A',
          fontWeight: 'bold',
          fontSize: '14px',
        }"
      >
        <el-table-column label="商品信息" width="420">
          <template slot-scope="scope">
            <!-- {{ scope.row.date }} -->
            <div class="table">
              <div class="table_pic">
                <img :src="$util.img(scope.row.goods_image)[0]" alt="" />
              </div>
              <div class="table_text">{{ scope.row.name }}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="订购价" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table_sc_pice">
              {{ scope.row.point }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="数量" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table_sc_num">
              <span>{{ scope.row.sub_num }}</span>
            </div>
            <!-- {{ scope.row.date }} -->
          </template>
        </el-table-column>
        <el-table-column label="小计" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table_sc_price">
              {{ scope.row.point * scope.row.sub_num }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="detailsbox">
        <div class="gooddetails">
          <div class="gooddetails_sf">
            <span class="title">实付款：</span>
            <span class="price">{{ orderdata.goods_list[0].point }}</span>
          </div>
          <div class="gooddetails_sz">
            <span class="title">送至：</span
            >{{ orderdata.address_info.detail_txt }}
          </div>
          <div class="gooddetails_sh">
            <span class="title">收货人：</span> {{ orderdata.address_info.name
            }}{{ orderdata.address_info.phone }}
          </div>
          <!-- <div class="gooddetails_time">
            <span class="title">送货时间：</span>2022-02-14 上午
          </div> -->
        </div>
      </div>

      <div class="btn">
        <el-button type="text" @click="navcart">返回购物车</el-button>
        <div class="tj_btn" @click="sub">提交订单</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // name: "PctheyoungConfirmorder",

  data() {
    var isphone = (rule, value, callback) => {
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!value) {
        callback(new Error("请填写手机号"));
      } else {
        if (!myreg.test(value)) {
          return callback(new Error("请正确填写手机号"));
        } else {
          callback();
        }
      }
    };
    return {
      //收货地址
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 8.64e7 * 3;
        },
      },
      addresslist: [],
      options1: [],
      checked: false,
      adress_form: {
        value: "",
        desc: "",
        name: "",
        phone: "",
      },
      address_checked: false,
      form: {
        remark: "",
        buy_user: "",
        buy_user_phone: "",
        wish_shou_time: "",
        greeting_card_remark: "",
        invoice_phone: "",
        invoice_email: "",
        invoice_company_name: "",
        invoice_identify_number: "",
        coupon_id: "",
        time_qj: "",
        radio: 1,
      },
      rules: {
        buy_user_phone: [
          { validator: isphone, trigger: "blur" },
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        wish_shou_time: {
          required: true,
          message: "请选择送达时间",
          trigger: "blur",
        },
        time_qj: {
          required: true,
          message: "请选择送达时间段",
          trigger: "change",
        },
      },
      tableData: [],
      value: "",
      options: [],
      dialogVisible: false,
      sdialogVisible: false,
      dialogVisibles: false,
      // 上一页数据
      orderdata: "",
      sub_type: "",
      sub_ids: "",
      sub_num: "",
      plan: 3,
      coupon_list: "",
      heka_list: [],
      activeName: 0,
      is_zhou: true,
      lat: "",
      lng: "",
      address_id: "",
    };
  },

  mounted() {
    this.sub_num = this.$route.query.sub_num;
    this.sub_type = this.$route.query.sub_type;
    this.sub_ids = this.$route.query.sub_ids;
    this.plan = this.$route.query.plan;
    this.getorder();
    this.getarea();
  },

  methods: {
    getarea() {
      this.$api.details.arealist({}).then((res) => {
        this.options = res.data;
      });
    },
    xzyhq() {
      this.getorder();
    },
    xzaddress(val) {
      if (val != 0) {
        let arr =
          this.$refs["cascaderMallCatergory"].getCheckedNodes()[0].pathLabels;
        let str = "";
        arr.forEach((item) => {
          str += item;
        });
        this.$api.details.lnglat({ address: str }).then((res) => {
          this.lat = res.data.result.location.lat;
          this.lng = res.data.result.location.lng;
        });
      }
    },
    // 保存地址
    address() {
      let isphone = /^1(3|4|5|7|8)\d{9}$/;
      for (let item in this.adress_form) {
        if (this.adress_form[item] == "") {
          return this.$message.error("请填写完整");
        }
      }
      if (!isphone.test(this.adress_form.phone)) {
        return this.$message.error("请正确填写手机号");
      }
      this.$api.user
        .userAddress({
          name: this.adress_form.name,
          phone: this.adress_form.phone,
          province_id: this.adress_form.value[0],
          city_id: this.adress_form.value[1],
          region_id: this.adress_form.value[2],
          detail: this.adress_form.desc,
          default: this.address_checked ? "1" : "0",
          pos_lat: this.lat,
          pos_lng: this.lng,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$notify({
              title: "成功",
              message: "地址添加成功",
              type: "success",
            });
            this.dialogVisible = false;
          } else {
            this.$notify.error({
              title: "失败",
              message: "地址添加失败",
            });
          }
        });
    },
    quxiao() {
      this.dialogVisible = false;
    },
    // select_list(name) {
    //   this.form.greeting_card_remark = name;
    //   this.dialogVisibles = false;
    // },
    // 获取订单信息
    getorder() {
      this.$api.details
        .pointsOrderCreate({
          sub_type: this.sub_type,
          sub_ids: this.sub_ids,
          plan: this.plan,
          sub_num: this.sub_num,
          coupon_id: this.form.coupon_id,
          address_id: this.address_id,
        })
        .then((res) => {
          this.orderdata = res.data;
          this.form.address_id = res.data.address_info.id;
        });
    },
    // 商品可用优惠券

    sub() {
      
      this.$api.details
        .pointsOrderCreatesub({
          sub_type: this.sub_type,
          sub_ids: this.sub_ids,
          sub_num: this.sub_num,
          address_id:this.form.address_id,
          remark: "",
        })
        .then((res) => {
          if (res.code == 1) {
            this.$router.push({
              name: "payok",
              query: {
                orderid: res.data,
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    handleClose() {
      this.sdialogVisible = false;
      this.dialogVisible = false;
      this.sdialogVisible = false;
      this.dialogVisibles = false;
    },
    addadress() {
      this.dialogVisible = true;
    },
    selectadress() {
      this.$api.user.userAddress_list({}).then((res) => {
        if (res.code == 1) {
          this.addresslist = res.data;
          this.sdialogVisible = true;
        }
      });
    },
    // 选中的地址
    handleCurrentChange(val) {
      this.orderdata.address_info = val;
      this.sdialogVisible = false;
      this.address_id = val.id;
      this.getorder();
    },
    liuyan() {
      this.dialogVisibles = true;
    },
    navcart() {
      this.$router.push("cart");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog_box {
  margin: 0 300px;
}
.tabsbox {
  margin: 0 60px;
}
.list_li {
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #1a1a1a;
  margin-bottom: 20px;
  cursor: pointer;
}
.Confirmorderbox {
  width: 1200px;
  margin: 0 auto;
  .ordertile {
    width: 1200px;
    height: 40px;
    background: #f5f5fa;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #212121;
    line-height: 40px;
  }
  .padleft {
    padding-left: 50px;
  }
  .consignee {
    .address {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #212121;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .addresslist {
      .list {
        width: 280px;
        height: 132px;
        background: #ffffff;
        background-image: url(../../assets/物流条.png);
        display: flex;
        .listbox {
          margin: 20px 13px;
          margin-bottom: 17px;
          .address_name {
            font-size: 16px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #1a1a1a;
            span {
              font-size: 16px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #666666;
              margin-left: 16px;
            }
          }
          .address_dz {
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            margin-top: 10px;
          }
          .address_modily {
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #ff6875;
            margin-top: 20px;
          }
        }
      }
    }
    .increaseaddress {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ff6875;
      margin-right: 20px;
      // margin-top: 30px;
      // margin-bottom: 50px;
    }
  }
  .form {
    margin: 30px 0 50px 0;
    padding-left: 50px;
  }
  .ksxz {
    margin-top: 20px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #212121;
  }
}
.input_show {
  display: flex;
  margin-top: 10px;
}
.radio_input {
  padding-top: 20px;
  width: 430px;
  background: #f6f6f6;
  border: 1px solid #ededed;
}
.table_pic {
  width: 150px;
  height: 150px;
  background: #f6f6f6;
  img {
    width: 150px;

    border-radius: 12px;
  }
}
.table {
  display: flex;
  align-items: center;
}
.table_text {
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #212121;
}
.table_sc_pice {
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
}
.table_sc_num {
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
}
.table_sc_price {
  font-size: 22px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #e70014;
}
.detailsbox {
  display: flex;
  justify-content: flex-end;
}
.gooddetails {
  width: 450px;
  height: 188px;
  background: #ffffff;
  border: 1px solid #e70014;
  margin-top: 50px;
  text-align: right;
  line-height: 2.6;
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  padding-right: 30px;
  .title {
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 18px;
  }
  .gooddetails_sf {
    .price {
      font-size: 24px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #e70014;
    }
  }
}
.btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 170px;
  .tj_btn {
    cursor: pointer;
    width: 292px;
    height: 48px;
    background: #e70014;
    border-radius: 24px;
    font-size: 18px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 48px;
    margin-left: 67px;
  }
}
.btnssbox {
  display: flex;
  justify-content: space-between;
}
.btnss {
  width: 205px;
  height: 48px;
  background: #f6f6f6;
  border-radius: 24px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}
.btnred {
  background: #e70014;

  color: #ffffff;
}
::v-deep .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #212121;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  font-weight: 500;
}
::v-deep .el-textarea__inner {
  min-height: 180px;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #fff;
  background-color: #e70014;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #909399;
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: #f5f7fa;
  border-bottom: 1px solid #e70014;
  margin: 0;
}
::v-deep .el-tabs--border-card {
  background: #fff;
  border: 0px;
  box-shadow: 0px 0px 0px;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
</style>