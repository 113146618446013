<template>
  <div style="width: 100%">
    <div class="Confirmorderbox">
      <div class="ordertile">
        <div style="margin-left: 20px">收货人信息</div>
      </div>
      <div class="consignee padleft">
        <div class="address">选择收货人地址</div>
        <div class="addresslist">
          <div class="list">
            <div class="listbox">
              <div class="address_name">蜜蜜收 <span>13383110015</span></div>
              <div class="address_dz">
                河北省石家庄市桥西区金裕商务508室伙伴科技有限公司
              </div>
              <div class="address_modily">修改</div>
            </div>
          </div>
        </div>
        <div class="increaseaddress" @click="addadress">添加新地址</div>
        <el-dialog
          title="新增收货地址"
          :visible.sync="dialogVisible"
          width="1060px"
          :before-close="handleClose"
        >
          <div class="dialog_box">
            <el-form ref="form" :model="form" label-width="120px">
              <el-form-item label="活动区域">
                <el-cascader
                  v-model="form.value"
                  :options="options1"
                  @change="handleChange"
                  placeholder="请选择省/市/区/街道"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="*详细地址:">
                <el-input
                  type="textarea"
                  v-model="form.desc"
                  placeholder="请输入详细地址信息，如道路、门牌号、小区"
                ></el-input>
              </el-form-item>
              <el-form-item label="*收货人姓名:">
                <el-input
                  v-model="form.name"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="*手机号码:">
                <el-input
                  v-model="form.name"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="checked">设置为默认地址</el-checkbox>
              </el-form-item>
            </el-form>
            <div class="btnssbox">
              <div class="btnss">取消</div>
              <div class="btnss btnred">保存</div>
            </div>
          </div>
        </el-dialog>
      </div>
      <div class="ordertile"><div style="margin-left: 20px">购物清单</div></div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        header-cell-style=" color: #1A1A1A ;font-weight: bold; font-size: 14px;"
      >
        <el-table-column label="商品信息" width="420">
          <template slot-scope="scope">
            <!-- {{ scope.row.date }} -->
            <div class="table">
              <div class="table_pic"></div>
              <div class="table_text">你是我的唯一 系列2</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="订购价" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table_sc_pice">
              {{ scope.row.address }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="数量" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table_sc_num">
              <span>{{ scope.row.num }}</span>
            </div>
            <!-- {{ scope.row.date }} -->
          </template> </el-table-column
        >
        <el-table-column label="小计" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table_sc_price">
              {{ scope.row.price }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="detailsbox">
        <div class="gooddetails">
          <div class="gooddetails_sf">
            <span class="title">实付款：</span>
            <span class="price">¥989.00</span>
          </div>
          <div class="gooddetails_sz">
            <span class="title">送至：</span>河北省石家庄市桥西区金裕商务508室
            旁
          </div>
          <div class="gooddetails_sh">
            <span class="title">收货人：</span>蜜蜜 13383110015
          </div>
          <div class="gooddetails_time">
            <span class="title">送货时间：</span>2022-02-14 上午
          </div>
        </div>
      </div>

      <div class="btn">
        <el-button type="text">返回购物车</el-button>
        <div class="tj_btn">提交订单</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungConfirmorder",

  data() {
    return {
      options1: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
            {
              value: "daohang",
              label: "导航",
              children: [
                {
                  value: "cexiangdaohang",
                  label: "侧向导航",
                },
                {
                  value: "dingbudaohang",
                  label: "顶部导航",
                },
              ],
            },
          ],
        },
        {
          value: "zujian",
          label: "组件",
          children: [
            {
              value: "basic",
              label: "Basic",
              children: [
                {
                  value: "layout",
                  label: "Layout 布局",
                },
                {
                  value: "color",
                  label: "Color 色彩",
                },
                {
                  value: "typography",
                  label: "Typography 字体",
                },
                {
                  value: "icon",
                  label: "Icon 图标",
                },
                {
                  value: "button",
                  label: "Button 按钮",
                },
              ],
            },
            {
              value: "form",
              label: "Form",
              children: [
                {
                  value: "radio",
                  label: "Radio 单选框",
                },
                {
                  value: "checkbox",
                  label: "Checkbox 多选框",
                },
                {
                  value: "input",
                  label: "Input 输入框",
                },
                {
                  value: "input-number",
                  label: "InputNumber 计数器",
                },
                {
                  value: "select",
                  label: "Select 选择器",
                },
                {
                  value: "cascader",
                  label: "Cascader 级联选择器",
                },
                {
                  value: "switch",
                  label: "Switch 开关",
                },
                {
                  value: "slider",
                  label: "Slider 滑块",
                },
                {
                  value: "time-picker",
                  label: "TimePicker 时间选择器",
                },
                {
                  value: "date-picker",
                  label: "DatePicker 日期选择器",
                },
                {
                  value: "datetime-picker",
                  label: "DateTimePicker 日期时间选择器",
                },
                {
                  value: "upload",
                  label: "Upload 上传",
                },
                {
                  value: "rate",
                  label: "Rate 评分",
                },
                {
                  value: "form",
                  label: "Form 表单",
                },
              ],
            },
            {
              value: "data",
              label: "Data",
              children: [
                {
                  value: "table",
                  label: "Table 表格",
                },
                {
                  value: "tag",
                  label: "Tag 标签",
                },
                {
                  value: "progress",
                  label: "Progress 进度条",
                },
                {
                  value: "tree",
                  label: "Tree 树形控件",
                },
                {
                  value: "pagination",
                  label: "Pagination 分页",
                },
                {
                  value: "badge",
                  label: "Badge 标记",
                },
              ],
            },
            {
              value: "notice",
              label: "Notice",
              children: [
                {
                  value: "alert",
                  label: "Alert 警告",
                },
                {
                  value: "loading",
                  label: "Loading 加载",
                },
                {
                  value: "message",
                  label: "Message 消息提示",
                },
                {
                  value: "message-box",
                  label: "MessageBox 弹框",
                },
                {
                  value: "notification",
                  label: "Notification 通知",
                },
              ],
            },
            {
              value: "navigation",
              label: "Navigation",
              children: [
                {
                  value: "menu",
                  label: "NavMenu 导航菜单",
                },
                {
                  value: "tabs",
                  label: "Tabs 标签页",
                },
                {
                  value: "breadcrumb",
                  label: "Breadcrumb 面包屑",
                },
                {
                  value: "dropdown",
                  label: "Dropdown 下拉菜单",
                },
                {
                  value: "steps",
                  label: "Steps 步骤条",
                },
              ],
            },
            {
              value: "others",
              label: "Others",
              children: [
                {
                  value: "dialog",
                  label: "Dialog 对话框",
                },
                {
                  value: "tooltip",
                  label: "Tooltip 文字提示",
                },
                {
                  value: "popover",
                  label: "Popover 弹出框",
                },
                {
                  value: "card",
                  label: "Card 卡片",
                },
                {
                  value: "carousel",
                  label: "Carousel 走马灯",
                },
                {
                  value: "collapse",
                  label: "Collapse 折叠面板",
                },
              ],
            },
          ],
        },
        {
          value: "ziyuan",
          label: "资源",
          children: [
            {
              value: "axure",
              label: "Axure Components",
            },
            {
              value: "sketch",
              label: "Sketch Templates",
            },
            {
              value: "jiaohu",
              label: "组件交互文档",
            },
          ],
        },
      ],
      form: {},
      tableData: [
        {
          date: "2016-05-03",
          name: "¥1999",
          address: "¥999",
          num: 1,
          price: "￥998.00",
        },
        {
          date: "2016-05-03",
          name: "¥1999",
          address: "¥999",
          num: 1,
        },
        {
          date: "2016-05-03",
          name: "¥1999",
          address: "¥999",
          num: 1,
        },
        {
          date: "2016-05-03",
          name: "¥1999",
          address: "¥999",
          num: 1,
        },
        {
          date: "2016-05-03",
          name: "¥1999",
          address: "¥999",
          num: 1,
        },
        {
          date: "2016-05-03",
          name: "¥1999",
          address: "¥999",
          num: 1,
        },
        {
          date: "2016-05-03",
          name: "¥1999",
          address: "¥999",
          num: 1,
        },
      ],
      value: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      dialogVisible: false,
      dialogVisibles: false,
    };
  },

  mounted() {},

  methods: {
    addadress() {
      this.dialogVisible = true;
    },
    liuyan() {
      this.dialogVisibles = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog_box {
  margin: 0 300px;
}
.tabsbox {
  margin: 0 60px;
}
.list_li {
  font-size: 16px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #1a1a1a;
  margin-bottom: 20px;
}
.Confirmorderbox {
  width: 1200px;
  margin: 0 auto;
  .ordertile {
    width: 1200px;
    height: 40px;
    background: #f5f5fa;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #212121;
    line-height: 40px;
  }
  .padleft {
    padding-left: 50px;
  }
  .consignee {
    .address {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #212121;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .addresslist {
      .list {
        width: 280px;
        height: 132px;
        background: #ffffff;
        background-image: url(../../assets/物流条.png);
        display: flex;
        .listbox {
          margin: 20px 13px;
          margin-bottom: 17px;
          .address_name {
            font-size: 16px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #1a1a1a;
            span {
              font-size: 16px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #666666;
              margin-left: 16px;
            }
          }
          .address_dz {
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            margin-top: 10px;
          }
          .address_modily {
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #ff6875;
            margin-top: 20px;
          }
        }
      }
    }
    .increaseaddress {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ff6875;
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
  .form {
    margin: 30px 0 50px 0;
    padding-left: 50px;
  }
  .ksxz {
    margin-top: 20px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #212121;
  }
}
.input_show {
  display: flex;
  margin-top: 10px;
}
.radio_input {
  padding-top: 20px;
  width: 430px;
  background: #f6f6f6;
  border: 1px solid #ededed;
}
.table_pic {
  width: 150px;
  height: 150px;
  background: #f6f6f6;
}
.table {
  display: flex;
  align-items: center;
}
.table_text {
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #212121;
}
.table_sc_pice {
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
}
.table_sc_num {
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
}
.table_sc_price {
  font-size: 22px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #e70014;
}
.detailsbox {
  display: flex;
  justify-content: flex-end;
}
.gooddetails {
  width: 450px;
  height: 188px;
  background: #ffffff;
  border: 1px solid #e70014;
  margin-top: 50px;
  text-align: right;
  line-height: 2.6;
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  padding-right: 30px;
  .title {
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 18px;
  }
  .gooddetails_sf {
    .price {
      font-size: 24px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #e70014;
    }
  }
}
.btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 170px;
  .tj_btn {
    width: 292px;
    height: 48px;
    background: #e70014;
    border-radius: 24px;
    font-size: 18px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 48px;
    margin-left: 67px;
  }
}
.btnssbox {
  display: flex;
  justify-content: space-between;
}
.btnss {
  width: 205px;
  height: 48px;
  background: #f6f6f6;
  border-radius: 24px;
  line-height: 48px;
  text-align: center;
}
.btnred {
  background: #e70014;

  color: #ffffff;
}
::v-deep .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #212121;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  font-weight: 500;
}
::v-deep .el-textarea__inner {
  min-height: 180px;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #fff;
  background-color: #e70014;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #909399;
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: #f5f7fa;
  border-bottom: 1px solid #e70014;
  margin: 0;
}
::v-deep .el-tabs--border-card {
  background: #fff;
  border: 0px;
  box-shadow: 0px 0px 0px;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
</style>