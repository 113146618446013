<template>
  <div>
    <!-- 导航 -->
    <div>
      <hb-nav></hb-nav>
      <div class="breadcrumb">
        <div class="breadcrumb_list">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>鲜花</el-breadcrumb-item>
            <el-breadcrumb-item>你是唯一 系列2</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="good">
        <div class="good_left"></div>
        <div class="good_right">
          <div class="good_title">
            <span class="good_title_blod">你是唯一 系列2</span>
            <span class="good_title_red">【新品上市】</span>
          </div>
          <div class="good_hy">花语内容</div>
          <div class="good_price"></div>
          <div class="good_hc good_describe">
            <span>花材：</span>
            <span>花材：哥伦比亚进口玫瑰、绣球，苔藓，满天</span>
          </div>
          <div class="good_bz good_describe">
            <span>包装：</span>
            <span
              >浅紫/香芋紫人造纸2张、白色雪梨纸1张、粉色罗纹烫金丝带1米、60#魔力铁山灰包装盒</span
            >
          </div>
          <div class="good_ps good_describe">
            <span>配送：</span> <span>需提前3天预定</span>
          </div>
          <div class="good_num">
            <span>数量：</span>
            <div class="btns">
              <div class="good_num_btn">-</div>
              <span>1</span>
              <div class="good_num_btn">+</div>
            </div>
          </div>
          <div class="good_btn">
            <div class="btns">
              <button class="smallbtn">加入购物车</button>
              <button class="smallbtn1">立即购买</button>
            </div>
            <div class="collection">
              <div class="crice"></div>
              已收藏
            </div>
          </div>
        </div>
      </div>
      <div class="scoll">
        <div class="scollbox">
          <div class="scollboxlist"></div>
        </div>
      </div>

      <div class="good_tab">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import hbNav from "@/components/hb_nav/hb_nav.vue";
export default {
  name: "PctheyoungCommodity",
  components: {
    "hb-nav": hbNav,
  },
  data() {
    return {
      content: "<p>123</p>",
      value: 3.7,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #fff;
  background-color: #e70014;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #909399;
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: #f5f7fa;
  border-bottom: 1px solid #e70014;
  margin: 0;
}
::v-deep .el-tabs--border-card {
  background: #fff;
  border: 0px;
  box-shadow: 0px 0px 0px;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
.breadcrumb {
  width: 100%;
  .breadcrumb_list {
    margin: 14px auto;
    width: 1200px;
  }
}
.good {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  .good_left {
    width: 500px;
    height: 500px;
    background: #d8d8d8;
  }
  .good_right {
    margin-left: 32px;
    .good_title {
      display: flex;
      align-items: center;
      margin-top: 27px;
      .good_title_blod {
        font-size: 24px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #1a1a1a;
      }
      .good_title_red {
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #e70014;
        margin-left: 20px;
      }
    }
    .good_hy {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 16px;
    }
    .good_price {
      width: 668px;
      height: 101px;
      background: #fff3f3;
      margin-top: 21px;
    }
    .good_describe {
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #1a1a1a;
      margin-top: 10px;
    }
    .good_hc {
      margin-top: 23px;
    }
    .good_num {
      margin-top: 26px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #666666;
      display: flex;
      align-items: center;
      .btns {
        display: flex;
        align-items: center;
        span {
          padding: 0 20px;
        }
      }
      .good_num_btn {
        width: 26px;
        height: 26px;
        background: #ededed;
        border-radius: 13px;
        line-height: 26px;
        text-align: center;
      }
    }
    .good_btn {
      margin-top: 29px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btns {
        .smallbtn {
          width: 210px;
          height: 48px;
          background: #fff3f3;
          border-radius: 24px;
          margin-right: 20px;
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #e70014;
        }
        .smallbtn1 {
          width: 210px;
          height: 48px;

          background: #e70014;
          border-radius: 24px;
          margin-right: 20px;
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .collection {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
        .crice {
          width: 32px;
          height: 32px;
          background: #fff3f3;
          border-radius: 16px;
          margin-right: 10px;
        }
      }
    }
  }
}
.scoll {
  width: 1200px;
  margin: 0 auto;
  margin-top: 22px;
  .scollbox {
    width: 500px;
    white-space: nowrap;
    height: 99px;
    // overflow-y: hidden;
    // overflow-x: scroll;
    // display: flex;
    // flex-direction: row;
    ::-webkit-scrollbar {
      display: none;
    }
    .scollboxlist {
      width: 100px;
      height: 99px;
      display: inline-block;
      background: #e70014;
      margin-right: 20px;
    }
  }
}
.good_tj {
  width: 1160px;
  margin: 0 auto;
  margin-top: 40px;
  .tj_list {
    width: 180px;
    text-align: center;
    line-height: 2;
    margin-top: 30px;
    .tj_pic {
      width: 180px;
      height: 181px;
      background-color: red;
    }
  }
  .tjlistbox {
    display: flex;
    justify-content: space-between;
  }
}
.good_tab {
  width: 1200px;
  margin: 0 auto;
  margin-top: 60px;

  .good_evaluate {
    width: 1200px;
    height: 48px;
    background: #f6f6f6;
    border: 1px solid #ededed;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 48px;
    padding-left: 20px;
    margin-top: 30px;
  }
  .evaluatebox {
    margin-bottom: 65px;
  }
  .evaluatelist {
    display: flex;
    align-items: flex-start;
    margin-top: 65px;
    .evaluatelist_avatr {
      margin-left: 50px;
      display: flex;
      align-items: center;
      p {
        margin-left: 20px;
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
      }
    }
    .evaluatelist_content_df {
      display: flex;
      align-items: center;
    }
    .evaluatelist_content_df_fraction {
      display: flex;
      align-items: center;
    }
    .evaluatelist_content_df_list {
      margin-left: 20px;
    }
    .evaluatelist_content_nr {
      margin-top: 32px;
    }
    .evaluatelist_content {
      margin-left: 40px;
      .evaluatelist_content_img {
        margin-top: 30px;
        width: 380px;
        display: flex;
        flex-wrap: wrap;
        .evaluatelist_content_img_list {
          width: 104px;
          height: 105px;
          background: #d8d8d8;
          border: 1px solid #e70014;
          margin-right: 20px;
          margin-bottom: 20px;
        }
        .good_img_pic {
          width: 630px;
          height: 431px;
          background: #d8d8d8;
          background: #d8d8d8;
        }
      }
    }
    .evaluatelist_content_time {
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #666666;
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .evaluatelist_content_time_log {
        margin-right: 40px;
      }
    }
  }
}
</style>