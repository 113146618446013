<template>
  <div class="coupon">
    <div class="couponbox" style="height: 100%">
      <div style="height: 0.1px"></div>
      <div class="coupon_title">我的优惠券</div>
      <div class="tabs">
        <div class="tabslist">
          <div class="tabslist_name" @click="tab(2)">可使用</div>
          <div class="heng" v-if="type == 2"></div>
        </div>
        <div class="tabslist">
          <div class="tabslist_name" @click="tab(1)">已使用</div>
          <div class="heng" v-if="type == 1"></div>
        </div>
        <div class="tabslist">
          <div class="tabslist_name" @click="tab(3)">已过期</div>
          <div class="heng" v-if="type == 3"></div>
        </div>
      </div>
      <transition name="fade" v-if="type == 2">
        <ul class="couponlist">
          <li class="list" v-for="(item, index) in coupon_list" :key="index">
            <div class="left">
              <div style="height: 0.1px"></div>
              <div class="price"><span>￥</span>{{ item.reduce_price }}</div>
              <div class="ky">满{{ item.min_price }}元可用</div>
            </div>
            <div class="right">
              <div style="" class="rightbox">
                <div class="right_title">满减券</div>
                <span
                  style="
                    font-size: 20px;
                    font-family: PingFang-SC-Bold, PingFang-SC;
                    font-weight: bold;
                    color: #1a1a1a;
                  "
                  >{{ item.name }}</span
                >
              </div>

              <div class="lar_text lar_text1">限品类：{{flower_type_txt?flower_type_txt:'全品类'}}</div>
              <div class="lar_text lar_text2">有效期至{{item.end_time}}</div>
            </div>
          </li>
        </ul>
      </transition>
      <transition name="fade" v-else>
        <ul class="couponlisth">
          <li class="list" v-for="(item, index) in coupon_list" :key="index">
            <div class="left">
              <div style="height: 0.1px"></div>
              <div class="price"><span>￥</span>{{ item.reduce_price }}</div>
              <div class="ky">满{{ item.min_price }}元可用</div>
            </div>
            <div class="right">
              <div style="" class="rightbox">
                <div class="right_title">满减券</div>
                <span
                  style="
                    font-size: 20px;
                    font-family: PingFang-SC-Bold, PingFang-SC;
                    font-weight: bold;
                    color: #999999;
                  "
                  >{{ item.name }}</span
                >
              </div>
              <div class="lar_text lar_text1">限品类：{{flower_type_txt?flower_type_txt:'全品类'}}</div>
              <div class="lar_text lar_text2">有效期至{{item.end_time}}</div>
            </div>
          </li>
        </ul>
      </transition>
      <div class="pages">
        <el-pagination
          background
          layout="prev, pager, next,jumper"
          :page-size="limit"
          :current-page="page"
          @current-change="change"
          :total="total"
        >
          <span slot="prev">
            <i class="el-icon-arrow-left btn"></i> 上一页</span
          >
          <span slot="next"
            ><i class="el-icon-arrow-right btn"></i> 上一页</span
          >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungCoupon",

  data() {
    return {
      coupon_list: "",
      limit: "10",
      page: "",
      type: 2,
      total: "",
    };
  },

  mounted() {
    this.getlist();
  },
  methods: {
    tab(id) {
      this.type = id;
      this.getlist();
    },
    getlist() {
      this.$api.user
        .coupon_list_user({
          limit: this.limit,
          page: this.page,
          type: this.type,
        })
        .then((res) => {
          this.coupon_list = res.data.list;
          this.total = res.data.total;
        });
    },
    change(event) {
      this.page = event;
      this.getlist();
    },
  },
};
</script>

<style lang="scss" scoped>
.pages {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  // margin-right: 20px;
  margin-top: 60px;
  bottom: 40px;
  right: 20px;
}
.couponbox{
  position: relative;
}
.coupon {
  height: 100%;
  background-color: #ffff;
  .coupon_title {
    font-size: 18px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
    margin: 40px;
  }
  .tabs {
    margin: 0 40px;
    .tabslist {
      display: inline-block;
      margin-right: 25px;
      .tabslist_name {
        display: inline-block;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
      }
    }
  }
  .heng {
    width: 12px;
    height: 4px;
    background: #fa4e4b;
    border-radius: 2px;
    margin: 0 auto;
    margin-top: 5px;
  }
  .couponlist {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin: 30px 40px;
    min-height: 600px;
    .list {
      display: flex;
      width: 400px;
      height: 118px;
      background: #fef7f8;
      border-radius: 12px;
      border: 1px solid #ffb0b0;
      display: flex;
      margin-bottom: 20px;
      margin-right: 20px;

      .left {
        width: 118px;
        height: 118px;
        background: url(../../assets/mz.png) no-repeat;
        background-size: 118px;
        .price {
          font-size: 24px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #e70014;
          margin-top: 24px;
          margin-left: 22px;
          span {
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #e70014;
          }
        }
        .ky {
          text-align: center;
          font-size: 12px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #e70014;
          margin: 0 13px;
          margin-top: 18px;
        }
      }
    }
    .right {
      .rightbox {
        display: flex;
        align-items: center;
        margin-top: 12px;
      }
      .right_title {
        display: inline-block;
        padding: 3px 9px;
        margin-left: 20px;
        font-size: 12px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;
        background: #e70014;
        border-radius: 4px;
        margin-right: 10px;
      }
      .lar_text {
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
        margin-left: 20px;
      }
      .lar_text1 {
        margin-top: 21px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
      }
      .lar_text2 {
        // margin-top: 5px;
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .couponlisth {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    // min-height: 600px;

    margin: 30px 40px;
    .list {
      display: flex;
      width: 400px;
      height: 118px;
      border-radius: 12px;

      border: 1px solid #999999;
      display: flex;
      margin-bottom: 20px;
      margin-right: 20px;

      .left {
        width: 118px;
        height: 118px;
        background: url(../../assets/mzh.png) no-repeat;
        background-size: 118px;
        .price {
          font-size: 24px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #999999;
          margin-top: 24px;
          margin-left: 22px;
          span {
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #999999;
          }
        }
        .ky {
          text-align: center;
          font-size: 12px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #999999;
          margin: 0 13px;
          margin-top: 18px;
        }
      }
    }
    .right {
      .rightbox {
        display: flex;
        align-items: center;
        margin-top: 12px;
      }
      .right_title {
        display: inline-block;
        padding: 3px 9px;
        margin-left: 20px;
        font-size: 12px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;
        background: #999999;
        border-radius: 4px;
        margin-right: 10px;
      }
      .lar_text {
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
        margin-left: 20px;
      }
      .lar_text1 {
        margin-top: 21px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
      }
      .lar_text2 {
        // margin-top: 5px;
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
</style>