<template>
  <div class="receiving">
    <div style="height: 100%" class="receiving_box">
      <div style="height: 1px"></div>
      <div class="receiving_title">收货地址</div>
      <div class="receiving_btn" @click="add">新增收货地址</div>
      <el-dialog
        title="新增收货地址"
        :visible.sync="dialogVisible"
        width="1060px"
        :before-close="handleClose"
      >
        <div class="dialog_box">
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="活动区域">
              <el-cascader
              ref="cascaderMallCatergory"
                v-model="form.value"
                :options="options"
                placeholder="请选择省/市/区/街道"
                @change="xzaddress"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="*详细地址:">
              <el-input
                type="textarea"
                v-model="form.desc"
                placeholder="请输入详细地址信息，如道路、门牌号、小区"
              ></el-input>
            </el-form-item>
            <el-form-item label="*收货人姓名:">
              <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="*手机号码:">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="checked">设置为默认地址</el-checkbox>
            </el-form-item>
          </el-form>
          <div class="btnssbox">
            <div class="btnss" @click="quxiao">取消</div>
            <div class="btnss btnred" @click="address">保存</div>
          </div>
        </div>
      </el-dialog>
      <div class="receiving_table" row-key="id">
        <el-table
          ref="multipleTable"
          :data="addresslist"
          tooltip-effect="dark"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#F5F5FA',
            color: '#1A1A1A',
            'font-weight': 'bold',
            'font-size': '14px',
            border: 'none',
          }"
        >
          <el-table-column label="收货人">
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column label="联系电话" width="120">
            <template slot-scope="scope">
              <div class="table_sc_der">
                {{ scope.row.phone }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="收货地址"
            prop="detail_txt"
            show-overflow-tooltip
            width="275"
          >
          </el-table-column>
          <el-table-column label="操作" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="table_btn">
                <div class="table_cz_textbtn" @click="modily(scope.row)">
                  修改
                </div>
                <div class="table_cz_textbtn" @click="del(scope.row.id)">
                  删除
                </div>
                <!-- <div class="table_cz_textbtn">默认地址</div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog
        title="修改收货地址"
        :visible.sync="dialogVisibles"
        width="1060px"
        :before-close="handleClose"
      >
        <div class="dialog_box">
          <el-form ref="forms" :model="form" label-width="120px">
            <el-form-item label="活动区域">
              <el-cascader
                v-model="forms.value"
                :options="options"
                label="name"
                placeholder="请选择省/市/区/街道"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="*详细地址:">
              <el-input
                type="textarea"
                v-model="forms.desc"
                placeholder="请输入详细地址信息，如道路、门牌号、小区"
              ></el-input>
            </el-form-item>
            <el-form-item label="*收货人姓名:">
              <el-input
                v-model="forms.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="*手机号码:">
              <el-input
                v-model="forms.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="checkeds">设置为默认地址</el-checkbox>
            </el-form-item>
          </el-form>
          <div class="btnssbox">
            <div class="btnss" @click="quxiao">取消</div>
            <div class="btnss btnred" @click="modilyaddress">保存</div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungReceiving",

  data() {
    return {
      tableData: [
        {
          date: "蜜蜜",
          name: "133****5856",
          address: "河北省石家庄市桥西区金裕商务508室 旁边小黑屋",
        },
      ],
      form: {
        value: "",
        desc: "",
        name: "",
        phone: "",
      },
      forms: {
        value: "",
        desc: "",
        name: "",
        phone: "",
      },
      checked: false,
      checkeds: false,
      dialogVisibles: false,
      dialogVisible: false,
      options: "",
      modily_id: "",
      addresslist: [],
      lng:'',
      lat:''
    };
  },

  mounted() {
    this.getarea();
    this.getaddresslist();
  },

  methods: {
    xzaddress(val){
       if (val != 0) {       
        let arr = this.$refs['cascaderMallCatergory'].getCheckedNodes()[0].pathLabels
        let str =''
        arr.forEach(item => {
          str+=item
        });
        this.$api.details.lnglat({address:str}).then(res=>{
          this.lat=res.data.result.location.lat
          this.lng=res.data.result.location.lng
        })
      }
    },
    // 选泽省市区
    add() {
      this.dialogVisible = true;
    },
    // 地址列表
    getaddresslist() {
      this.$api.user.userAddress_list({}).then((res) => {
        this.addresslist = res.data;
      });
    },
    // 删除地址
    del(index) {
      this.$api.user
        .deluserAddress({
          id: index,
        })
        .then((res) => {
          if (res.code == 1) {
            this.getaddresslist();
          }
        });
    },

    //
    modilyaddress() {
      let isphone = /^1(3|4|5|7|8)\d{9}$/;
      for (let item in this.forms) {
        if (this.forms[item] == "") {
          return this.$message.error("请填写完整");
        }
      }
      if (!isphone.test(this.forms.phone)) {
        return this.$message.error("请正确填写手机号");
      }
      this.$api.user
        .edituserAddress({
          id: this.modily_id,
          name: this.forms.name,
          phone: this.forms.phone,
          province_id: this.forms.value[0],
          city_id: this.forms.value[1],
          region_id: 10,
          detail: this.forms.desc,
          default: this.checkeds ? "1" : "0",
        })
        .then((res) => {
          if (res.code == 1) {
            this.$notify({
              title: "成功",
              message: "地址修改成功",
              type: "success",
            });
            this.dialogVisible = false;
          } else {
            this.$notify.error({
              title: "失败",
              message: "地址修改失败",
            });
          }
        });
    },
    // 修改地址
    modily(row) {
      // this.$refs['cascaderMallCatergory'].getCheckedNodes()[0].pathLabels='222'
      console.log(row);
      this.modily_id = row.id;
      this.dialogVisibles = true;
      this.forms.value = [row.province_id, row.city_id,row.region_id];
      this.forms.desc = row.detail;
      this.forms.name = row.name;
      this.forms.phone = row.phone;
      this.checkeds = row.default == 1 ? true : false;
    },
    quxiao() {
      this.dialogVisible = false;
      this.dialogVisibles = false;
    },
    address() {
      let isphone = /^1(3|4|5|7|8)\d{9}$/;
      for (let item in this.form) {
        if (this.form[item] == "") {
          return this.$message.error("请填写完整");
        }
      }
      if (!isphone.test(this.form.phone)) {
        return this.$message.error("请正确填写手机号");
      }
      this.$api.user
        .userAddress({
          name: this.form.name,
          phone: this.form.phone,
          province_id: this.form.value[0],
          city_id: this.form.value[1],
          region_id:this.form.value[2],
          detail: this.form.desc,
          default: this.checked ? "1" : "0",
          pos_lat:this.lat,
          pos_lng:this.lng
        })
        .then((res) => {
          if (res.code == 1) {
            this.$notify({
              title: "成功",
              message: "地址添加成功",
              type: "success",
            });
            this.dialogVisible = false;
          } else {
            this.$notify.error({
              title: "失败",
              message: "地址添加失败",
            });
          }
        });
    },
    getarea() {
      this.$api.details.arealist({}).then((res) => {
        this.options = res.data;
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibles = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #212121;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  font-weight: 500;
}
::v-deep .el-textarea__inner {
  min-height: 180px;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #fff;
  background-color: #e70014;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #909399;
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: #f5f7fa;
  border-bottom: 1px solid #e70014;
  margin: 0;
}
::v-deep .el-tabs--border-card {
  background: #fff;
  border: 0px;
  box-shadow: 0px 0px 0px;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
.dialog_box {
  margin: 0 300px;
}
.btnssbox {
  display: flex;
  justify-content: space-between;
}
.btnss {
  width: 205px;
  height: 48px;
  background: #f6f6f6;
  border-radius: 24px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}
.btnred {
  background: #e70014;

  color: #ffffff;
}
.receiving {
  height: 100%;
  background-color: #fff;
  .receiving_title {
    font-size: 18px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
    margin-top: 40px;
  }
  .receiving_box {
    margin: 0 35px;
  }
  .receiving_btn {
    width: 150px;
    height: 40px;
    background: #e70014;
    border-radius: 20px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    margin-top: 28px;
  }
  .receiving_table {
    margin-top: 30px;
  }
}
.table_sc_pice {
}
.table_btn {
  margin: 0 40px;
  display: flex;
  // justify-content: space-between;
  div {
    margin-right: 20px;
    cursor: pointer;
  }
}
::v-deep .el-table__header-wrapper {
  border-radius: 12px 12px 0px 0px;
}
::v-deep .el-table .cell.el-tooltip {
  white-space: initial;
  min-width: 50px;
}
::v-deep .el-table--border,
.el-table--group {
  border-radius: 12px 12px 0px 0px;
}
</style>