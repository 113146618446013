<template>
  <div class="member">
    <hb-nav @change="setest"></hb-nav>
    <div class="navbox">
      <div class="breadcrumb">
        <div class="breadcrumb_list">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ yj_name }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ ej_name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <el-container class="container">
        <el-aside width="210px" class="aside">
          <div class="aside_title">会员中心</div>
          <ul
            class="member_menu"
            v-for="(item, index) in menulist"
            :key="index"
          >
            <li class="member_menu_first">
              <div class="member_menu_first_top">
                <img :src="item.icon" alt="" />
                <div class="member_menu_text">{{ item.name }}</div>
              </div>
              <ul class="zi_ul">
                <li
                  class="member_menu_first_li"
                  v-for="(itema, indexa) in item.list"
                  :key="indexa"
                  :class="select_index == itema.router ? 'select_indexs' : ''"
                  @click="navto(itema, item)"
                >
                  {{ itema.name }}
                </li>
              </ul>
            </li>
          </ul>
        </el-aside>
        <el-main class="main">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import hbNav from "@/components/hb_nav/hb_nav.vue";

export default {
  name: "PctheyoungMember",
  components: {
    "hb-nav": hbNav,
  },
  data() {
    return {
      menulist: [
        {
          name: "会员首页",
          icon: require("../../assets/会员首页2.png"),
          list: [{ name: "会员中心", router: "/home" }],
        },
        {
          name: "订单中心",
          icon: require("../../assets/订单中心2.png"),
          list: [
            { name: "我的订单", router: "/order" },
            // { name: "评价晒单", router: "" },
          ],
        },
        {
          name: "个人中心",
          icon: require("../../assets/个人中心2.png"),
          list: [
            // { name: "个人信息", router: "/personal" },
            { name: " 收货地址", router: "/receiving" },
            { name: "生日纪念提醒", router: "/tips" },
            { name: "我的收藏", router: "/colletion" },
          ],
        },
        {
          name: "账户中心",
          icon: require("../../assets/账户中心2.png"),
          list: [
            { name: "会员积分", router: "/integral" },
            { name: "优惠券", router: "/coupon" },
          ],
        },
      ],
      select_index: "/home",
      yj_name: "会员首页",
      ej_name: "",
    };
  },

  mounted() {
    this.select_index = this.$route.path;
  },
  watch: {
    $route(to, from) {
      this.select_index = to.path;
    },
  },
  methods: {
    navto(itea, item) {
      this.$router.push(itea.router);
      this.yj_name = itea.name;
      // this.ej_name = itea.name;
    },
    setest(index) {
      console.log(index);
      let routeData = this.$router.resolve({
        path: "Commodity",
        query: {
          classid: index,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.select_indexs {
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #e70014;
}
.navbox {
  width: 1200px;
  margin: 0 auto;
}
.breadcrumb {
  padding: 14px 0;
  //   border-bottom: 1px solid #ededed;
}
.aside_title {
  font-size: 20px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #212121;
  margin: 30px 0 20px 30px;
}
.aside {
  min-height: 832px;

  background: #ffffff;
}
.main {
  background: #f6f6f6;
  margin-left: 15px;
}
::v-deep .el-main {
  padding: 0;
}
.member {
  background-color: #f6f6ff;
}
.member_menu {
  margin: 0px 0 0px 30px;
  .member_menu_first {
    font-size: 16px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 30px;
    .member_menu_first_top {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .zi_ul {
      margin-left: 30px;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1a1a1a;
      .member_menu_first_li {
        margin-top: 19px;
        cursor: pointer;
      }
    }
    .member_menu_text {
      margin-left: 10px;
    }
  }
}
</style>