import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import error from '../views/404.vue'
import evaluate from '../views/evaluate/evaluate.vue'
import details from '../views/details.vue'
import Commodity from '../views/Commodity/Commodity.vue'
import silde from '../views/silde/silde.vue'
import ingtaldetails from '../views/ingtaldetails/ingtaldetails.vue'
import ingtalorder from '../views/ingtalorder/ingtalorder.vue'



import cart from '../views/cart/cart.vue'
import Confirmorder from '../views/Confirmorder/Confirmorder.vue'
import pollen from '../views/pollen/pollen.vue'
import hotsale from '../views/hotsale/hotsale.vue'
import hotsales from '../views/hotsales/hotsales.vue'

import pay from '../views/pay/pay.vue'
import payok from '../views/payok/payok.vue'
import pcb from '../views/pcb/pcb.vue'
import xiyi from '../views/xiyi/xiyi.vue'


import mhome from '../views/member/home.vue'
import member from '../views/member/member.vue'
import order from '../views/member/order.vue'
import order_integral from '../views/member/order_integral.vue'
import orderdetails from '../views/member/orderdetails.vue'
import inorder from '../views/member/inorder.vue'
import inorderdetails from '../views/member/inorderdetails.vue'
import personal from '../views/member/personal.vue'
import receiving from '../views/member/receiving.vue'
import tips from '../views/member/tips.vue'
import colletion from '../views/member/mycolletion.vue'
import remind from '../views/member/remind.vue'
import integral from '../views/member/integral.vue'
import integral_good from '../views/member/integral_good.vue'
import inConfirmorder from '../views/inConfirmorder/inConfirmorder.vue'
import coupon from '../views/member/coupon.vue'

















Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'error',
    component: error
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/silde',
    name: 'silde',
    component: silde
  },
  {
    path: '/detail',
    name: 'integral_good',
    component: integral_good
  }, {
    path: '/ingtaldetails',
    name: 'ingtaldetails',
    component: ingtaldetails
  },
  {
    path: '/ingtalorder',
    name: 'ingtalorder',
    component: ingtalorder
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    component: evaluate
  },
  {
    path: '/hotsale',
    name: 'hotsale',
    component: hotsale
  },
  {
    path: '/hotsales',
    name: 'hotsales',
    component: hotsales
  },
  {
    path: '/pollen',
    name: 'pollen',
    component: pollen
  },
  {
    path: '/details',
    name: 'details',
    component: details
  },
  {
    path: '/Commodity',
    name: 'Commodity',
    component: Commodity
  },
  {
    path: '/cart',
    name: 'cart',
    component: cart
  },
  {
    path: '/xiyi',
    name: 'xiyi',
    component: xiyi
  },
  {
    path: '/Confirmorder',
    name: 'Confirmorder',
    component: Confirmorder
  },
  {
    path: '/inConfirmorder',
    name: 'inConfirmorder',
    component: inConfirmorder
  },
  {
    path: '/pay',
    name: 'pay',
    component: pay
  },
  {
    path: '/payok',
    name: 'payok',
    component: payok
  },
  {
    path: '/pcb',
    name: 'pcb',
    component: pcb
  },

  {
    path: '/member',
    name: 'member',
    component: member,
    children: [
      {
        path: '/home',
        name: 'mhome',
        component: mhome
      },
      {
        path: '/order',
        name: 'order',
        component: order
      },
      {
        path: '/orderdetails',
        name: 'orderdetails',
        component: orderdetails
      },
      {
        path: '/inorder',
        name: 'inorder',
        component: inorder
      },
      {
        path: '/inorderdetails',
        name: 'inorderdetails',
        component: inorderdetails
      },
      {
        path: '/personal',
        name: 'personal',
        component: personal
      }
      , {
        path: '/receiving',
        name: 'receiving',
        component: receiving
      }
      , {
        path: '/tips',
        name: 'tips',
        component: tips
      }, {
        path: '/colletion',
        name: 'colletion',
        component: colletion
      }, {
        path: '/remind',
        name: 'remind',
        component: remind
      }, {
        path: '/integral',
        name: 'integral',
        component: integral
      }, {
        path: '/coupon',
        name: 'coupon',
        component: coupon
      }, {
        path: '/order_integral',
        name: 'order_integral',
        component: order_integral
      }


    ]
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
