<template>
  <div class="tips">
    <div class="tipsbox" style="height: 100%">
      <div style="height: 1px"></div>
      <div class="tips_top_btn" @click="addremind">添加生日或纪念日提醒</div>
      <!-- <div class="tips_time">一周内</div> -->
      <div>
        <div class="tips_table">
          <el-table
            :data="list"
            border
            style="width: 100%"
            :show-header="false"
          >
            <el-table-column prop="date">
              <template slot-scope="scope">
                <div class="tips_table_pic">
                  <div class="img">
                    <img src="../../assets/sr.png" alt="" v-if="scope.row.type==2">
                    <img src="../../assets/jnr.png" alt="" v-else>
                  </div>
                  <div class="text">
                    <div class="text_name">{{scope.row.name}}</div>
                    <div class="text_time"> {{scope.row.update_time}}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name">
              <template slot-scope="scope">
                <div class="table_name">
                  <div class="pstime">备注：{{scope.row.remark}}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="address">
              <template slot-scope="scope">
                <div class="btn_box">
                  <div class="table_btn">修改</div>
                  <div class="table_btn table_red">编辑</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungTips",

  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      list: [],
    };
  },

  mounted() {
    this.getlist()
  },

  methods: {
    // 提醒列表
    //
    getlist() {
      this.$api.user.userCommemorationDay_list({}).then((res) => {
        this.list = res.data;
      });
    },

    addremind() {
      this.$router.push("remind");
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  height: 100%;
  .tipsbox {
    height: 100%;
    background-color: #fff;

    .tips_top_btn {
      margin: 30px 40px;
      width: 197px;
      height: 40px;
      background: #e70014;
      border-radius: 20px;
      font-size: 14px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }
    .tips_time {
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #999999;
      margin-left: 40px;
    }
    .tips_table {
      margin: 40px 30px;
    }
  }
  .tips_table_pic {
    display: flex;
    .img {
      width: 80px;
      height: 80px;
      img{
          width: 80px;
      height: 80px;
      }
    }
    .text {
      margin-left: 20px;
      .text_name {
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
      }
      .text_time {
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
        margin-top: 10px;
      }
    }
  }
}
.btn_box {
  display: flex;
  justify-content: flex-end;
  div {
    margin-right: 20px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #444494;
    cursor: pointer;
  }
}
::v-deep .el-table--border,
.el-table--group {
  border-radius: 12px 12px 0px 0px;
}
</style>