<template>
  <div class="hotsale_box">
    <div class="swiper">
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="item,index in $util.img(sale_list.info.images_pc) " :key="index">
          <img :src="item" alt="" srcset="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style="width: 1200px; margin: 0 auto">
      <!-- <div class="tab">
        <div class="tab_list" @click="navsale(1)" :class="type_id==1?'actice_tab_list':''">
          鲜花排行榜 <span>go</span>
        </div>
        <div class="tab_list" style=" border-radius: 0 8px    8px 0;" @click="navsale(2)" :class="type_id==2?'actice_tab_list':''">
          永生花排行榜 <span>go</span>
        </div>
      </div> -->
      <div class="hotsale">
        <div class="sale_list" v-for="(item, index) in good_list" :key="index">
          <!-- <div class="pos_img">
            <div style="margin-top: 10px; font-size: 18px">TOP</div>
            <div>{{ index + 1 }}</div>
          </div> -->
          <div class="sale_list_pic">
            <img :src="$util.img(item.goods_image)[0]" alt="" srcset="" />
          </div>
          <div class="sale_list_info">
            <div class="sale_list_info_title">{{ item.name }}</div>
            <div class="sale_list_info_red">{{ item.flower_language }}</div>
            <div class="sale_list_info_label">
              {{ item.packing_desc }}
            </div>
            <div class="sale_list_info_btn">
              ￥269
              <div class="btn" @click="navdetails(item)">立即购买</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PcHotsale",

  data() {
    return {
      type_id: 1,
      sale_list: [],
      id: "",
      good_list:[]
    };
  },

  mounted() {
    this.id = this.$route.query.ad_id;
    this.getlist();
  },

  methods: {
    navdetails(event) {
      let routeData = this.$router.resolve({
        path: "details",
        query: {
          id: event.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    getlist() {
      this.$api.details
        .ad_info({
          id: this.id,
        })
        .then((res) => {
          this.sale_list = res.data;
          this.sale_list.goods_list.forEach(item => {
            item.goods_list.forEach(itema=>{
              this.good_list.push(itema)
            })
          });
        });
    },
    navsale(index) {
      this.type_id = index;
      this.getlist();
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  width: 100%;
  display: flex;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
  .tab_list {
    cursor: pointer;
    width: 50%;
    text-align: center;
    padding: 20px 0;
    color: #a0a0a0;
    font-size: 20px;
    border-radius: 8px 0 0 8px;
  }
  .actice_tab_list {
    background: #e70014;
    color: #fff;
  }
}
.hotsale_box {
  background-color: #ededed;
  //   width: 1200px;
  min-height: 400px;
  margin: 0 auto;
  .hotsale {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .sale_list {
      background: #ffffff;
      position: relative;
      .pos_img {
        position: absolute;
        top: -4px;
        left: 0px;
        z-index: 2;
        background-image: url(../../assets/排行榜.png);
        background-size: 60px;
        width: 60px;
        height: 60px;
        font-size: 22px;
        color: #fff;
        div {
          width: 100%;
          text-align: center;

          //   height: 90px;
        }
      }
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      width: 588px;
      min-height: 200px;
      margin-bottom: 24px;
      border-radius: 8px;
      display: flex;
      .sale_list_pic {
        border-radius: 8px;

        width: 240px;
        height: 291px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        img {
          width: 240px;
          //   height: 291px;
          border-radius: 8px;
        }
        z-index: 1;
        background-color: #fff;
      }
      .sale_list_info {
        margin-top: 12px;
        margin-left: 34px;
        .sale_list_info_title {
          font-size: 18px;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          color: #232628;
          //   line-height: 2;
        }
        .sale_list_info_red {
          margin-top: 10px;
          font-size: 15px;
          line-height: 15px;
          color: #e70014;
        }
        .sale_list_info_label {
          margin-top: 22px;
          font-size: 13px;
          color: #71797f;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          overflow: hidden;
        }
      }
    }
  }
}
.sale_list_info_btn {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 20px;
}
.btn {
  margin-left: 29px;
  width: 119px;
  height: 32px;
  background: linear-gradient(90deg, rgb(253, 116, 78) 0%, #e70014 100%);
  background-color: #ff3d12;
  border-radius: 17px;
  font-size: 12.75px;
  color: #ffffff;
  letter-spacing: 0.29px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>