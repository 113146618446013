<template>
    <div>
        <div class="xieyi_box" v-html="conet"></div>
    </div>
</template>

<script>
export default {
    name: 'PcXiyi',

    data() {
        return {
            type:'',
            conet:''
        };
    },

    mounted() {
        this.type=this.$route.query.type
        this.getxieyi()
    },

    methods: {
        getxieyi(){
            this.$api.details.agreement({
                type:this.type
            }).then(res=>{
                this.conet=res.data
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.xieyi_box{
    width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
}
</style>