<template>
  <div>
    <div class="details_top">
      <div class="details_top_head">
        <div class="details_top_head_status">{{ orderdata.status_str }}</div>
        <div class="details_top_head_btn" v-if="orderdata.status == 0">
          <div class="text_btn" @click="cancel">取消订单</div>
          <!-- <div class="xg_btn">修改贺卡备注</div> -->
          <div class="lj_btn" @click="del">删除订单</div>
        </div>
        <div class="details_top_head_btn" v-if="orderdata.status == 4">
          <!-- <div class="xg_btn">修改贺卡备注</div> -->
          <div class="lj_btn" @click="del">删除订单</div>
        </div>
        <div class="details_top_head_btn" v-if="orderdata.status == 1">
          <div class="text_btn" @click="cancel">取消订单</div>
          <!-- <div class="xg_btn">修改贺卡备注</div> -->
          <div class="lj_btn" @click="navfk">去付款</div>
        </div>
        <div class="details_top_head_btn" v-if="orderdata.status == 2">
          <div class="text_btn" @click="sh">确认收货</div>
          <!-- <div class="xg_btn">修改贺卡备注</div> -->
          <div
            class="lj_btn"
            @click="refund"
            v-if="Number(orderdata.pay_money) != 0"
          >
            申请退款
          </div>
        </div>
        <div class="details_top_head_btn" v-if="orderdata.status == 5">
          <!-- <div class="xg_btn">修改贺卡备注</div> -->
          <div class="lj_btn">去评价</div>
        </div>
      </div>
      <div class="line"></div>
      <!-- <div class="head_status">配送状态：</div> -->
      <!-- <div class="step">
        <el-steps direction="vertical" :active="1" space="500">
          <el-step title="步骤 1" description="2022-02-10 14:04:06"></el-step>
          <el-step title="步骤 2" description="2022-02-10 14:04:06"></el-step>
          <el-step
            title="步骤 3"
            description="这是一段很长很长很长的描述性文字"
          ></el-step>
        </el-steps>
      </div> -->
    </div>
    <div class="details_main">
      <div class="details_main_left">
        <div class="details_main_left_title">收货信息</div>
        <div class="list_box">
          <ul>
            <li class="list">
              <span>收 货 人</span>
              <div>{{ orderdata.user_name }}</div>
            </li>
            <li class="list">
              <span>联系电话</span>
              <div>{{ orderdata.user_phone }}</div>
            </li>
            <li class="list">
              <span>收货地址</span>
              <div>{{ orderdata.detail_txt }}</div>
            </li>
            <!-- <li class="list">
              <span>送达日期</span>
              <div>2020-02-14 上午</div>
            </li> -->
            <li class="list">
              <span>电子贺卡</span>
              <el-popover
                placement="top"
                width="240"
                trigger="hover"
                v-model="visible"
              >
              <div>
                {{
                  orderdata.greeting_card_remark
                    ? orderdata.greeting_card_remark
                    : "无"
                }}
              </div>
              <div
              slot="reference"
                style="
                  width: 300px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                "
              >
                {{
                  orderdata.greeting_card_remark
                    ? orderdata.greeting_card_remark
                    : "无"
                }}
              </div>
              </el-popover>
            </li>
          </ul>
        </div>
      </div>
      <div class="details_main_right">
        <div class="details_main_left_title">其他信息</div>
        <div class="list_box">
          <ul>
            <li class="list">
              <span>发票信息</span>

              <div slot="reference" v-if="orderdata.invoice == 0">
                {{ fapiao }}
              </div>

              <el-popover
                placement="top"
                width="240"
                trigger="hover"
                v-model="visible"
                v-else-if="orderdata.invoice == 1"
              >
                <ul>
                  <li>
                    <span class="title">手机号：</span>
                    <span class="label">{{
                      orderdata.invoice_phone ? orderdata.invoice_phone : "无"
                    }}</span>
                  </li>
                  <li>
                    <span class="title">邮箱号：</span>
                    <span class="label">{{
                      orderdata.invoice_email ? orderdata.invoice_email : "无"
                    }}</span>
                  </li>
                </ul>
                <div slot="reference">{{ fapiao }}</div>
              </el-popover>

              <el-popover
                placement="top"
                width="240"
                trigger="hover"
                v-model="visible"
                v-else
              >
                <ul>
                  <li>
                    <span class="title">手机号：</span>
                    <span class="label">{{
                      orderdata.invoice_phone ? orderdata.invoice_phone : "无"
                    }}</span>
                  </li>
                  <li>
                    <span class="title">邮箱号：</span>
                    <span class="label">{{
                      orderdata.invoice_email ? orderdata.invoice_email : "无"
                    }}</span>
                  </li>
                  <li>
                    <span class="title">纳税人识别号：</span>
                    <span class="label">{{
                      orderdata.invoice_identify_number
                        ? orderdata.invoice_identify_number
                        : "无"
                    }}</span>
                  </li>
                  <li>
                    <span class="title">公司名称：</span>
                    <span class="label">{{
                      orderdata.invoice_company_name
                        ? orderdata.invoice_company_name
                        : "无"
                    }}</span>
                  </li>
                </ul>
                <div slot="reference">{{ fapiao }}</div>
              </el-popover>
            </li>
            <li class="list">
              <span>备注</span>
              <div>{{ orderdata.remark }}</div>
            </li>
            <li class="list">
              <span>订 单 号</span>
              <div>{{ orderdata.orderno }}</div>
            </li>
            <li class="list">
              <span>下单日期</span>
              <div>{{ orderdata.create_time }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="details_foot">
      <div style="height: 1px"></div>
      <div class="good">
        <div
          class="good_list"
          v-for="(item, index) in orderdata.goods_list"
          :key="index"
        >
          <div class="good_list_pic">
            <img :src="$util.img(item.goods_image)[0]" alt="" />
          </div>
          <div class="goode_list_title">{{ item.goods_name }}</div>
          <div class="goode_list_title1">共{{ item.buy_num }}件</div>
          <div class="goode_list_title2">¥{{ item.price }}</div>
        </div>
      </div>
      <div class="price">
        <div class="pricebox">
          <div class="yh" v-if="orderdata.discount_amount !== '0.00'">
            优惠：<span>-¥{{ orderdata.discount_amount }}</span>
          </div>
          <div class="zj">
            实付款：<span>¥{{ orderdata.pay_money }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungOrderdetails",

  data() {
    return {
      tableData: [],
      order_id: "",
      orderdata: "",
    };
  },

  mounted() {
    this.order_id = this.$route.query.orderid;
    this.getorderinfo();
  },
  computed: {
    fapiao() {
      let str = "";
      if (this.orderdata.invoice == 0) {
        str = "无";
      } else if (this.orderdata.invoice == 1) {
        str = "个人";
      } else {
        str = "公司";
      }
      return str;
    },
  },

  methods: {
    sh(event) {
      this.$api.details.order_confirm({ id: this.orderdata.id }).then((res) => {
        this.getorderlist();
      });
    },
    // 申请退款
    refund(id) {
      this.$api.user
        .order_refund({ order_id: this.orderdata.id })
        .then((res) => {
          if (res.code == 1) {
            this.router.back();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 删除订单
    del(id) {
      this.$api.user.order_del({ order_id: this.orderdata.id }).then((res) => {
        if (res.code == 1) {
          this.router.back(1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    cancel() {
      this.$api.user
        .order_cancel({ order_id: this.orderdata.id })
        .then((res) => {
          if (res.code == 1) {
            this.router.back();
          }
        });
    },
    getorderinfo() {
      this.$api.user
        .order_info({
          order_id: this.order_id,
        })
        .then((res) => {
          this.orderdata = res.data;
        });
    },
    navfk() {
      this.$router.push({
        name: "pay",
        query: {
          orderid: this.order_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.details_top {
  width: 975px;
  background: #ffffff;
  .line {
    width: 970px;
    height: 1px;
    background: #ededed;
    margin: 0 auto;
  }
  .head_status {
    margin: 30px 46px;
    font-size: 18px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
  }
  .step {
    margin: 0px 46px;
    padding-bottom: 80px;
    display: flex;
    margin-top: 30px;
  }
  .details_top_head {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    .details_top_head_status {
      font-size: 20px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #1a1a1a;
    }
    .details_top_head_btn {
      display: flex;
      align-items: center;
      .text_btn {
        cursor: pointer;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #666666;
        margin-right: 40px;
      }
      .xg_btn {
        width: 140px;
        height: 48px;
        background: #ffffff;
        border-radius: 24px;
        border: 1px solid #dedede;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #1a1a1a;
        margin-right: 30px;
      }
      .lj_btn {
        cursor: pointer;
        width: 128px;
        height: 48px;
        background: #e70014;
        border-radius: 24px;
        text-align: center;
        line-height: 48px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
.details_main {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .details_main_left {
    width: 480px;
    height: 239px;
    background: #ffffff;
  }
  .details_main_right {
    width: 480px;
    height: 239px;
    background: #ffffff;
  }
  .details_main_left_title {
    font-size: 18px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
    margin: 0px 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .list_box {
    margin-left: 30px;
    .list {
      span {
        width: 56px;
        margin-right: 20px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #666666;
      }
      div {
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;

        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
      display: flex;
      margin-bottom: 20px;
    }
  }
}
.details_foot {
  background: #ffffff;
  margin-top: 15px;
  .good_list {
    padding: 35px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ededed;
    .good_list_pic {
      width: 60px;
      height: 60px;
      img {
        width: 60px;
        height: 60px;
      }
    }
    .goode_list_title {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1a1a1a;
      margin-left: 20px;
    }
    .goode_list_title1 {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1a1a1a;
      margin-left: 160px;
    }
    .goode_list_title2 {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1a1a1a;
      margin-left: 80px;
    }
  }
  .price {
    display: flex;
    justify-content: flex-end;
    .pricebox {
      margin: 20px 0;
      margin-right: 35px;
      .yh {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
        line-height: 3;
      }
      .zj {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #1a1a1a;
        span {
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #e70014;
        }
      }
    }
  }
}
</style>