<template>
  <div>
    <div class="home_head">
      <div class="home_head_left">
        <div class="avater">
          <img :src="user.avatar" alt="" srcset="" />
        </div>
        <div class="home_head_left_text">
          <div class="home_head_left_text_hy">hi！欢迎回来～</div>
          <div class="home_head_left_tip">让生活更有仪式感</div>
        </div>
      </div>
      <div class="home_head_right">
        <router-link to="/integral">
          <div class="home_head_right_jf">
            <div>{{ user.points }}</div>
            <div>积分</div>
          </div></router-link
        >
        <span class="shu"></span>
        <router-link to="/coupon">
          <div class="home_head_right_jf">
            <div>{{ ordernum.coupon_count }}</div>
            <div>优惠券</div>
          </div></router-link
        >
        <span class="shu"></span>
        <router-link to="/colletion">
          <div class="home_head_right_jf">
            <div>0</div>
            <div>我的收藏</div>
          </div></router-link
        >
      </div>
    </div>
    <div class="home_main">
      <div class="home_main_left">
        <div class="home_main_left_top">
          <div style="height: 1px"></div>
          <div class="home_main_left_top_title">我的订单</div>
          <div class="home_main_left_top_gird">
            <ul>
              <el-badge :value="ordernum.dai_fu_kuan" class="item">
                <li class="home_main_left_top_gird_staus" @click="navorder(1)">
                  <div><img src="../../assets/待付款@2x.png" alt="" /></div>
                  <div class="home_main_left_top_gird_staus_text">待付款</div>
                </li>
              </el-badge>
              <el-badge :value="ordernum.fu_wu" class="item">
                <li class="home_main_left_top_gird_staus" @click="navorder(2)">
                  <div><img src="../../assets/待付款@2x.png" alt="" /></div>
                  <div class="home_main_left_top_gird_staus_text">服务中</div>
                </li>
              </el-badge>
              <el-badge :value="ordernum.dai_ping_jia" class="item">
                <li class="home_main_left_top_gird_staus" @click="navorder(5)">
                  <div><img src="../../assets/待付款@2x.png" alt="" /></div>
                  <div class="home_main_left_top_gird_staus_text">待评价</div>
                </li>
              </el-badge>
              <el-badge :value="ordernum.all" class="item">
                <li class="home_main_left_top_gird_staus" @click="navorder('')">
                  <div><img src="../../assets/待付款@2x.png" alt="" /></div>
                  <div class="home_main_left_top_gird_staus_text">全部订单</div>
                </li>
              </el-badge>
            </ul>
          </div>
        </div>
        <div class="home_main_left_botton" v-if="near.length > 0">
          <div style="height: 1px"></div>
          <div class="home_main_left_top_title">周花日历</div>
          <div class="home_main_left_bottom_zh">
            <div class="home_main_left_bottom_zh_left">
              <div class="pic">
                <img
                  :src="$util.img(near[0].orderGoodsInfo.goods_image)[0]"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="text">
                <div class="text_zj">最近收花</div>
                <div class="text_time">
                  {{ near[0].time_txt }} <span>{{ near[0].wei_week }}</span>
                </div>
                <div class="text_num">还有{{ near[0].wei_day }}天配送</div>
              </div>
            </div>
            <div class="home_main_left_bottom_zh_right">
              <div class="ps_num">配送数量</div>
              <div class="ps_1">{{ near[0].zhou_num }}单</div>
            </div>
          </div>
        </div>
        <div class="home_main_left_botton">
          <div style="height: 0.1px"></div>
          <div class="bg">
            <div class="day">今天</div>
            <div class="hao">{{tian}}</div>
            <div class="month">{{showtimes}}</div>
            <div class="nong">{{ weekdate }}</div>
          </div>
        </div>
      </div>
      <div class="home_main_right">
        <div class="home_main_right_title">猜你喜欢</div>
        <div class="home_main_right_list">
          <div
            class="list"
            v-for="(item, index) in likelist"
            :key="index"
            @click="navdetails(item)"
          >
            <div class="list_pic">
              <img :src="$util.img(item.goods_image)[0]" alt="" />
            </div>
            <div class="list_title">{{ item.name }}</div>
            <div class="list_price">¥{{ item.price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungHome",
  data() {
    return {
      user: "",
      ordernum: "",
      near: "",
      likelist: "",
      showtimes: "",
      weekdate: "",
      tian:''
    };
  },

  mounted() {
    if (localStorage.getItem("token")) {
      this.getinfo();
      this.getnum();
      this.getnear();
      this.getlike();
      this.getdate();
    }
  },

  methods: {
    // 获取个人信息
    getinfo() {
      this.$api.user.info({}).then((res) => {
        this.user = res.data.userInfo;
      });
    },
    getdate() {
      var nowdate = new Date();
      var year = nowdate.getFullYear(),
        month = nowdate.getMonth() + 1,
        date = nowdate.getDate(),
        day = nowdate.getDay(),
        week = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ],
        h = nowdate.getHours(),
        m = nowdate.getMinutes(),
        s = nowdate.getSeconds(),
        h = this.checkTime(h),
        m = this.checkTime(m),
        s = this.checkTime(s);
        this.tian=date
      this.showtimes = year + "年" + month + "月" + date + "日";
      this.weekdate = week[day];
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    //订单数量
    getnum() {
      this.$api.user.order_count({}).then((res) => {
        this.ordernum = res.data;
      });
    },
    // 最近收花
    getnear() {
      this.$api.user.week_order_near({}).then((res) => {
        this.near = res.data;
      });
    },
    navorder(event) {
      this.$router.push({
        path: "/order", //路径
        query: {
          type: event,
        },
      });
    },
    navdetails(event) {
      let routeData = this.$router.resolve({
        path: "details",
        query: {
          id: event.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 猜你喜欢
    getlike() {
      this.$api.user
        .goodlist({
          limit: 9,
          page: 1,
          order_filed: "sale",
        })
        .then((res) => {
          this.likelist = res.data.list;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.home_head {
  width: 975px;
  height: 140px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .home_head_left {
    display: flex;
    align-items: center;
    margin-left: 50px;
    .avater {
      width: 80px;
      height: 80px;
      background: #d8d8d8;
      border-radius: 50%;
      img {
        width: 80px;
      }
    }
    .home_head_left_text {
      margin-left: 20px;
      .home_head_left_text_hy {
        font-size: 22px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
      }
      .home_head_left_tip {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #e70014;
        margin-top: 5px;
      }
    }
  }
  .home_head_right {
    display: flex;
    text-align: center;
    font-size: 16px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
    .home_head_right_jf {
      margin: 0 30px;
      div {
        margin-bottom: 10px;
      }
    }
    .shu {
      width: 1px;
      height: 40px;
      background-color: #ededed;
    }
    // margin-right: 55px;
  }
}
.home_main {
  margin-top: 15px;
  display: flex;
}
.home_main_left_top {
  width: 540px;
  height: 200px;
  background: #ffffff;
  .home_main_left_top_title {
    margin: 30px 0 0 30px;
    font-size: 18px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
  }
}
.home_main_left_botton {
  width: 540px;
  height: 322px;
  background: #ffffff;
  margin-top: 15px;
  .home_main_left_top_title {
    margin: 30px 0 0 30px;
    font-size: 18px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
  }
  .home_main_left_bottom_zh {
    margin: 40px 0 0 30px;
    display: flex;
    .home_main_left_bottom_zh_left {
      display: flex;
      .pic {
        width: 77px;
        height: 77px;
        border-radius: 7px;
        img {
          width: 77px;
          height: 77px;
          border-radius: 7px;
        }
      }
      .text {
        margin-left: 20px;
        .text_zj {
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #666666;
          margin-bottom: 10px;
        }
        .text_time {
          font-size: 20px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #1a1a1a;
          margin-bottom: 10px;
          span {
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #1a1a1a;
          }
        }
        .text_num {
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #e70014;
        }
      }
    }
    .home_main_left_bottom_zh_right {
      margin-left: 73px;
      .ps_num {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
        margin-bottom: 10px;
      }
      .ps_1 {
        font-size: 20px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
      }
    }
  }
}
.home_main_left_top_gird {
  margin: 42px 71px 0 86px;
  ul {
    display: flex;
    justify-content: space-between;
    .home_main_left_top_gird_staus {
      cursor: pointer;
      text-align: center;
      img {
        width: 32px;
        height: 32px;
      }
      .home_main_left_top_gird_staus_text {
        margin-top: 12px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
      }
    }
  }
}
.home_main_right {
  margin-left: 15px;
  width: 100%;
  background-color: #ffffff;
  .home_main_right_title {
    font-size: 18px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
    margin: 30px 0 30px 25px;
  }
  .home_main_right_list {
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .list {
      cursor: pointer;
      .list_pic {
        width: 120px;
        height: 120px;
        background: #d8d8d8;
        margin-bottom: 10px;
        img {
          width: 120px;
          height: 120px;
        }
      }
      .list_title {
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100px;
      }
      .list_price {
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
      }
    }
  }
}
.bg {
  background-image: url(../../assets/日历背景@2x.png);
  width: 178px;
  height: 178px;
  background-size: 178px;
  margin: 0 auto;
  margin-top: 66px;
  text-align: center;
  .day {
    padding: 6px 0;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #ffffff;
  }
  .hao {
    font-size: 44px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #1a1a1a;
    margin-top: 23px;
  }
  .month {
    margin-top: 15px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
  }
  .nong {
    font-size: 12px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #999999;
    margin-top: 5px;
  }
}
</style>