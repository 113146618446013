<template>
  <div class="silde_box">
    <div class="good_evaluate">全部评价</div>
    <div class="evaluatebox">
      <div
        class="evaluatelist"
        v-for="(itema, indexa) in comment_list"
        :key="indexa"
      >
        <div class="evaluatelist_avatr">
          <el-avatar
            :size="50"
            :src="itema.userInfo.avatar"
            v-if="itema.userInfo"
          ></el-avatar>
          <!-- <p>139****6682</p> -->
        </div>
        <div class="evaluatelist_content">
          <div class="evaluatelist_content_df">
            <div class="evaluatelist_content_df_first">
              <div class="evaluatelist_content_df_fraction">
                打分：<el-rate
                  v-model="value"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}"
                >
                </el-rate>
              </div>
              <div class="evaluatelist_content_df_list">配送:5分 包装:5分</div>
            </div>

            <!-- <div>购买痛苦</div> -->
            <div>
              <el-button type="primary" round @click="navdetails(itema)"
                >购买同款</el-button
              >
            </div>
          </div>
          <div class="evaluatelist_content_nr">
            {{ itema.content }}
          </div>
          <div class="evaluatelist_content_img">
            <div
              class="evaluatelist_content_img_list"
              v-for="(item, index) in $util.img(itema.images)"
              :key="index"
              @click="evaluatelist(indexa, item)"
            >
              <img :src="item" alt="" srcset="" />
            </div>
            <transition name="fade" mode="out-in">
            <div class="good_img_pic" v-if="itema.ylpic != ''">
              <img :src="itema.ylpic" alt="" />
            </div>
            </transition>
          </div>
          <div class="evaluatelist_content_time">
            <div>
              <!-- <span class="evaluatelist_content_time_log"
                        >河北石家庄裕华区</span
                      > -->
              <span class="evaluatelist_content_date">{{
                itema.update_time
              }}</span>
            </div>
            <div class="is_zan" @click="zanevent(itema)">
              <img
                :src="itema.is_zan == 1 ? xin.red : xin.white"
                alt=""
                srcset=""
              />
              {{ itema.zan_num }}
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <!-- <span class="demonstration">直接前往</span> -->
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="limit"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PcSilde",

  data() {
    return {
      comment_list: [],
      xin: {
        white: require("../../assets/wxin.png"),
        red: require("../../assets/rxin.png"),
      },
      limit: 10,
      value: 5,
      page: 1,
      total: 0,
    };
  },

  mounted() {
    this.getcomment_list();
  },

  methods: {
    navdetails(event) {
      let routeData = this.$router.resolve({
        path: "details",
        query: {
          id: event.goods_id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    getcomment_list() {
      this.$api.details
        .comment_list({
          is_all: 1,
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          this.comment_list = res.data.list;
          for (let item of this.comment_list) {
            this.$set(item, "ylpic", "");
          }
          this.total = res.data.total;
        });
    },
    //查看评论图片
    evaluatelist(indexa, item) {
      this.comment_list.forEach((items) => {
        items.ylpic = "";
      });
      this.comment_list[indexa].ylpic = item;
    },
    // 点赞
    zanevent(event) {
      if (localStorage.getItem("token")) {
        if (event.is_zan == 0) {
          this.$api.details
            .comment_zan({
              id: event.id,
            })
            .then((res) => {
              if (res.code == 1) {
                event.is_zan = 1;
                event.zan_num += 1;
              }
            });
        } else {
          this.$api.details
            .comment_zan({
              id: event.id,
            })
            .then((res) => {
              if (res.code == 1) {
                event.is_zan = 0;
                event.zan_num -= 1;
              }
            });
        }
      } else {
        this.$parent.islogin();
      }
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getcomment_list();
    },
  },
};
</script>

<style lang="scss" scoped>
.is_zan {
  img {
    width: 18px;
  }
}
.page {
  padding: 40px 0;
  float: right;
}
.silde_box {
  width: 1200px;
  margin: 0 auto;
}
.good_evaluate {
  width: 1200px;
  height: 48px;
  background: #f6f6f6;
  border: 1px solid #ededed;
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
  line-height: 48px;
  padding-left: 20px;
  margin-top: 30px;
}
.evaluatebox {
  margin-bottom: 65px;
}
.evaluatelist {
  display: flex;
  align-items: flex-start;
  margin-top: 65px;
  .evaluatelist_avatr {
    margin-left: 50px;
    display: flex;
    align-items: center;
    p {
      margin-left: 20px;
      font-size: 18px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1a1a1a;
    }
  }
  .evaluatelist_content_df {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .evaluatelist_content_df_first {
    display: flex;
    align-items: center;
  }
  .evaluatelist_content_df_fraction {
    display: flex;
    align-items: center;
  }
  .evaluatelist_content_df_list {
    margin-left: 20px;
  }
  .evaluatelist_content_nr {
    margin-top: 32px;
  }
  .evaluatelist_content {
    width: 100%;
    margin-left: 40px;
    .evaluatelist_content_img {
      margin-top: 30px;
      width: 380px;
      display: flex;
      flex-wrap: wrap;
      .evaluatelist_content_img_list {
        width: 104px;
        height: 105px;
        background: #d8d8d8;
        margin-right: 20px;
        margin-bottom: 20px;
        img {
          width: 104px;
          height: 105px;
        }
      }
      .good_img_pic {
        width: 430px;
        img {
          width: 430px;
        }
      }
    }
  }
  .evaluatelist_content_time {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #666666;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .evaluatelist_content_time_log {
      margin-right: 40px;
    }
  }
}
</style>