import axios from '../axios'

/* 
 * 用户管理模块
 */

// 登录
export const login = (data) => {
    return axios({
        url: '/user/passport/mobilelogin',
        method: 'post',
        data
    })
}
// 手机短信接口
export const smsCode = (data) => {
    return axios({
        url: '/user/common/smsCode',
        method: 'post',
        data
    })
}
// 首页

export const index = (data) => {
    return axios({
        url: '/user/goods/index',
        method: 'post',
        data
    })
}
// 用户信息
export const info = (data) => {
    return axios({
        url: '/user/user/info',
        method: 'post',
        data
    })
}
// 订单的数量展示

export const order_count = (data) => {
    return axios({
        url: '/user/user/order_count',
        method: 'post',
        data
    })
}
// 最近收花

export const week_order_near = (data) => {
    return axios({
        url: '/user/order/week_order_near',
        method: 'post',
        data
    })
}
// 猜你喜欢

export const goodlist = (data) => {
    return axios({
        url: '/user/goods/list',
        method: 'post',
        data
    })
}
// 订单列表

export const order_list = (data) => {
    return axios({
        url: '/user/order/order_list',
        method: 'post',
        data
    })
}
// 订单详情

export const order_info = (data) => {
    return axios({
        url: '/user/order/order_info',
        method: 'post',
        data
    })
}
// 商品分类

export const goodsClass = (data) => {
    return axios({
        url: '/user/goodsClass/list',
        method: 'post',
        data
    })
}
// 商品列表

export const goodslist = (data) => {
    return axios({
        url: '/user/goods/list',
        method: 'post',
        data
    })
}
// 添加地址

export const userAddress = (data) => {
    return axios({
        url: '/user/userAddress/add',
        method: 'post',
        data
    })
}
// 地址列表

export const userAddress_list = (data) => {
    return axios({
        url: '/user/userAddress/list',
        method: 'post',
        data
    })
}
// 提醒列表

export const userCommemorationDay_list = (data) => {
    return axios({
        url: '/user/userCommemorationDay/list',
        method: 'post',
        data
    })
}
// 添加提醒

export const adduserCommemorationDay = (data) => {
    return axios({
        url: '/user/userCommemorationDay/add',
        method: 'post',
        data
    })
}
// 收藏列表

export const collection_list = (data) => {
    return axios({
        url: '/user/collection/collection_list',
        method: 'post',
        data
    })
}
// 取消收藏

export const collection_del = (data) => {
    return axios({
        url: '/user/collection/collection_del',
        method: 'post',
        data
    })
}
// 签到配置

export const award = (data) => {
    return axios({
        url: '/user/user/award',
        method: 'post',
        data
    })
}
// 签到

export const sign = (data) => {
    return axios({
        url: '/user/user/sign',
        method: 'post',
        data
    })
}
// 积分列表

export const pointsGoods = (data) => {
    return axios({
        url: '/user/pointsGoods/list',
        method: 'post',
        data
    })
}
// 积分明细

export const point_log = (data) => {
    return axios({
        url: '/user/user/point_log',
        method: 'post',
        data
    })
}
// 兑换记录

export const poorder_list = (data) => {
    return axios({
        url: '/user/pointsOrder/order_list',
        method: 'post',
        data
    })
}
// 优惠券

export const coupon_list_user = (data) => {
    return axios({
        url: '/user/coupon/coupon_list_user',
        method: 'post',
        data
    })
}
// 地址删除

export const deluserAddress = (data) => {
    return axios({
        url: '/user/userAddress/del',
        method: 'post',
        data
    })
}
// 编辑地址

export const edituserAddress = (data) => {
    return axios({
        url: '/user/userAddress/edit',
        method: 'post',
        data
    })
}
// 取消订单

export const order_cancel = (data) => {
    return axios({
        url: '/user/order/order_cancel',
        method: 'post',
        data
    })
}
// 删除订单

export const order_del = (data) => {
    return axios({
        url: '/user/order/order_del',
        method: 'post',
        data
    })
}
// 申请退款

export const order_refund = (data) => {
    return axios({
        url: '/user/order/order_refund',
        method: 'post',
        data
    })
}
// 推荐历史列表

export const goodsHistory = (data) => {
    return axios({
        url: '/user/goodsHistory/list',
        method: 'post',
        data
    })
}
// 花语

export const flowerLanguageWood = (data) => {
    return axios({
        url: '/user/flowerLanguageWood/list',
        method: 'post',
        data
    })
}
// 修改购物车数量

export const cartnum = (data) => {
    return axios({
        url: '/user/cart/num',
        method: 'post',
        data
    })
}