import axios from '../axios'

// 区域列表
export const arealist = (data) => {
    return axios({
        url: '/user/area/list',
        method: 'post',
        data
    })
}

// 商品详情
export const detail = (data) => {
    return axios({
        url: '/user/goods/detail',
        method: 'post',
        data
    })
}
// 区域价格

export const goods_price_area = (data) => {
    return axios({
        url: '/user/goods/goods_price_area',
        method: 'post',
        data
    })
}
// 商品评价

export const comment_list = (data) => {
    return axios({
        url: '/user/order/comment_list',
        method: 'post',
        data
    })
}
// 加入购物车

export const addcart = (data) => {
    return axios({
        url: '/user/cart/add',
        method: 'post',
        data
    })
}
// 是否收藏

export const is_collection = (data) => {
    return axios({
        url: '/user/collection/is_collection',
        method: 'post',
        data
    })
}
// 添加收藏

export const collection_add = (data) => {
    return axios({
        url: '/user/collection/collection_add',
        method: 'post',
        data
    })
}
// 删除收藏

export const collection_del = (data) => {
    return axios({
        url: '/user/collection/collection_del',
        method: 'post',
        data
    })
}
// 点赞 

export const comment_zan = (data) => {
    return axios({
        url: '/user/order/comment_zan',
        method: 'post',
        data
    })
}
// 订单信息

export const orderCreate = (data) => {
    return axios({
        url: '/user/orderCreate/order',
        method: 'post',
        data
    })
}
// 购物车列表

export const cartlist = (data) => {
    return axios({
        url: '/user/cart/list',
        method: 'post',
        data
    })
}
// 删除购物车

export const delcart = (data) => {
    return axios({
        url: '/user/cart/del',
        method: 'post',
        data
    })
}
// 最近浏览

export const collection_list = (data) => {
    return axios({
        url: '/user/history/collection_list',
        method: 'post',
        data
    })
}
// 检测足迹 

export const his_collection_add = (data) => {
    return axios({
        url: '/user/history/collection_add',
        method: 'post',
        data
    })
}
// 首页 pc

export const indexPc = (data) => {
    return axios({
        url: '/user/goods/indexPc',
        method: 'post',
        data
    })
}
// 提交订单

export const sub = (data) => {
    return axios({
        url: '/user/orderCreate/sub',
        method: 'post',
        data
    })
}
// 获取支付信息

export const pay_info = (data) => {
    return axios({
        url: '/user/orderCreate/pay_info',
        method: 'post',
        data
    })
}
// 获取支付状态

export const pay_status = (data) => {
    return axios({
        url: '/user/orderCreate/pay_status',
        method: 'post',
        data
    })
}
// 订单详情

export const order_info = (data) => {
    return axios({
        url: '/user/order/order_info',
        method: 'post',
        data
    })
}
// 商品可用优惠券

export const coupon_list_store = (data) => {
    return axios({
        url: '/user/coupon/coupon_list_store',
        method: 'post',
        data
    })
}
// 贺卡祝福语

export const greeting_card_blessing = (data) => {
    return axios({
        url: '/user/orderCreate/greeting_card_blessing',
        method: 'post',
        data
    })
}
// 经纬度

export const lnglat = (data) => {
    return axios({
        url: '/user/greetingCardBlessing/lnglat',
        method: 'post',
        data
    })
}
// 下级分类

export const list_screen = (data) => {
    return axios({
        url: '/user/goodsClass/list_screen',
        method: 'post',
        data
    })
}
// 首次送达时间

export const first_shou_time = (data) => {
    return axios({
        url: '/user/goods/first_shou_time',
        method: 'post',
        data
    })
}
// 发表评价

export const order_comment = (data) => {
    return axios({
        url: '/user/order/order_comment',
        method: 'post',
        data
    })
}
// 积分详请

export const pointsGoodsdetail = (data) => {
    return axios({
        url: '/user/pointsGoods/detail',
        method: 'post',
        data
    })
}
// 积分商品订单信息

export const pointsOrderCreate = (data) => {
    return axios({
        url: '/user/pointsOrderCreate/order',
        method: 'post',
        data
    })
}
// 积分商品提交

export const pointsOrderCreatesub = (data) => {
    return axios({
        url: '/user/pointsOrderCreate/sub',
        method: 'post',
        data
    })
}
// 首页底部

export const pcBottom = (data) => {
    return axios({
        url: '/user/goods/pcBottom',
        method: 'post',
        data
    })
}
// 广告位

export const ad_position = (data) => {
    return axios({
        url: '/user/ad/ad_position',
        method: 'post',
        data
    })
}
// 广告详情

export const ad_info = (data) => {
    return axios({
        url: '/user/ad/ad_info',
        method: 'post',
        data
    })
}
// 确认收货

export const order_confirm = (data) => {
    return axios({
        url: '/user/order/order_confirm',
        method: 'post',
        data
    })
}
// 协议

export const agreement = (data) => {
    return axios({
        url: '/store/passport/agreement',
        method: 'post',
        data
    })
}