<template>
  <div class="box">
    <div class="swiper" style="height:0.1px">
      
    </div>
    <div class="mincard" style="min-height: 570px">
      <div class="card" v-for="(item, index) in list" :key="index">
        <div class="clearfix">
          <div>
            <span>下单时间：{{ item.update_time }}</span>
            <span class="orderno">订单号：{{ item.orderno }}</span>
          </div>
          <i
            class="el-icon-delete"
            style="float: right; padding: 3px 0"
            type="text"
          ></i>
        </div>
        <el-table
          :data="item.goods_list"
          border
          style="width: 100%"
          :show-header="false"
        >
          <el-table-column prop="date" width="435">
            <template slot-scope="scope">
              <div class="table_box">
                <div class="table_pic">
                  <img :src="$util.img(scope.row.goods_image)[0]" alt="" />
                </div>
                <div class="table_text">
                  <div class="table_text_title">{{ scope.row.goods_name }}</div>
                  <div class="table_text_price">
                    <span>共{{ scope.row.buy_num }}件</span>¥{{
                      scope.row.pay_money
                    }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" width="180">
            <template slot-scope="scope">
              <div class="table_name">
                <div class="pstime">配送日期</div>
                <div class="psdate">{{ item.wish_shou_time }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address">
            <template slot-scope="scope">
              <div class="table_staus">{{ item.status_txt }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="address">
            <template slot-scope="scope">
              <div class="btn_box">
                <div class="groupBtn" v-if="item.status == 0">
                  <div class="table_btn " @click="ckxq(item.id)">查看详情</div>

                  <div class="table_btn table_red">删除订单</div>
                </div>
                <div class="groupBtn" v-if="item.status == 4">
                  <div class="table_btn " @click="ckxq(item.id)">查看详情</div>

                  <div class="table_btn table_red">删除订单</div>
                </div>
                <div class="groupBtn" v-if="item.status == 1">
                  <div class="table_btn " @click="ckxq(item.id)">查看详情</div>

                  <div class="table_btn">去付款</div>
                  <div class="table_btn table_red">取消订单</div>
                </div>
                <div class="groupBtn" v-if="item.status == 2">
                  <div class="table_btn " @click="ckxq(item.id)">查看详情</div>

                  <div class="table_btn" v-if="item.delivery_type == 10">
                    确认收货
                  </div>
                  <div
                    class="table_btn table_red"
                    v-if="Number(item.pay_money) != 0"
                  >
                    申请退款
                  </div>
                  <!-- <div class="table_btn table_red" @click="modiyaddress(item.id)">修改地址</div> -->
                </div>
                <div class="groupBtn" v-if="item.status == 5">
                  <div class="table_btn " @click="ckxq(item.id)">查看详情</div>

                  <div class="table_btn">去评价</div>
                </div>
               
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="pages">
      <el-pagination
        background
        layout="prev, pager, next,jumper"
        :page-size="limit"
        :current-page="page"
        @current-change="change"
        :total="total"
      >
        <span slot="prev"> <i class="el-icon-arrow-left btn"></i> 上一页</span>
        <span slot="next"><i class="el-icon-arrow-right btn"></i> 上一页</span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungOrder",

  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      list: "",
      limit: 5,
      page: 1,
      status: "",
      total: 0,
      ordernum:""
    };
  },

  mounted() {
    this.getnum()
    this.getorderlist();
  },

  methods: {
    xzstaus(index) {
      this.page = 1;
      this.status = index;
      this.getorderlist();
    },
    change(event) {
      this.page = event;
      this.getorderlist();
    },
    // 订单数量
    getnum() {
      this.$api.user.order_count({}).then((res) => {
        this.ordernum = res.data;
      });
    },
    // 订单列表
    getorderlist() {
      this.$api.user
        .poorder_list({
          limit: this.limit,
          page: this.page,
          status: this.status,
        })
        .then((res) => {
          this.list = res.data.list;
          this.total = res.data.total;
        });
    },
    // 查看详情
    ckxq(id){
      this.$router.push({name:'orderdetails',params:{orderid:id}});
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  background-color: #fff;
  height: 100%;
}
.swiper {
  background-color: #fff;
}
.first {
  border-bottom: 3px solid #e70014;
  box-sizing: border-box;
  .firstlist {
    margin: 0 auto;
    .listbox {
      display: flex;
      line-height: 4;
      align-items: center;
      font-size: 16px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 25px;
      .alllist {
        width: 255px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 48px;
        text-align: center;
        background: #e70014;
        border-radius: 10px 10px 0px 0px;
        margin-right: 90px;
        color: #fff;
        position: relative;
        img {
          margin-right: 18px;
        }
        .pos {
          position: absolute;
          width: 255px;
          height: 480px;
          background-color: #e70014;
          bottom: -483px;
        }
      }
      .qylist {
        line-height: 2.5;
        // margin-right: 110px;
        width: 140px;
        text-align: center;
      }
      .selectqylist {
        background: #e70014;
        border-radius: 12px 12px 0px 0px;
        color: #fff;
      }
    }
  }
  .swiperlsit {
    width: 100%;
    height: 480px;
    img {
      width: 100%;
      height: 480px;
    }
  }
}
.card {
  margin: 30px 30px 0 30px;
  background-color: #fff;
}
.clearfix {
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  height: 48px;
  background: #f5f5fa;
  border-radius: 12px 12px 0px 0px;
  line-height: 48px;
  .orderno {
    margin-left: 20px;
  }
}
.table_box {
  display: flex;
  padding: 20px 10px;
}
.table_pic {
  width: 60px;
  height: 60px;
  img {
    width: 60px;
    height: 60px;
  }
}
.table_text {
  margin-left: 20px;
  .table_text_title {
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
  }
  .table_text_price {
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
    margin-top: 18px;
    span {
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #666666;
      margin-right: 10px;
    }
  }
}
.table_name {
  text-align: center;
  .pstime {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #666666;
  }
  .psdate {
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
  }
}
.table_staus {
  text-align: center;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #e70014;
}
.table_btn {
  width: 90px;
  height: 28px;
  background: #ffffff;
  border-radius: 14px;
  border: 1px solid #ededed;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
  margin-bottom: 10px;
}
.table_red {
  color: #fff;
  background: #e70014;
}
.btn_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pages {
  display: flex;
  justify-content: flex-end;
  // margin-right: 20px;
  margin-top: 60px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #e70014;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e70014;
  color: #fff;
}
::v-deep .el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #e70014;
  outline: 0;
}
</style>