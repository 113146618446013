<template>
  <div class="remind">
    <div style="height: 100%">
      <div style="height: 1px"></div>
      <div class="remind_title">添加/编辑提醒</div>
      <div class="remind_form">
        <div class="remind_form_title">生日/纪念日信息</div>
        <div class="remind_form_form">
          <el-form ref="form" :model="form" label-width="80px" size="small">
            <el-form-item label="类 型:">
              <div class="lx">
                <span
                  class="lx_btn"
                  :class="form.type == 1 ? 'selcttype' : ''"
                  @click="selecttype(1)"
                  >生日</span
                >
                <span
                  class="lx_btn"
                  :class="form.type == 2 ? 'selcttype' : ''"
                  @click="selecttype(2)"
                  >纪念日</span
                >
              </div>
            </el-form-item>
            <el-form-item label="标 题:">
              <div style="width: 370px">
                <el-input
                  v-model="form.title"
                  placeholder="请输入标题"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="姓 名:">
              <div style="width: 370px">
                <el-input
                  v-model="form.name"
                  placeholder="请输入姓名"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="性 别:">
              <div class="lx">
                <span
                  class="lx_btn"
                  :class="form.sex == 2 ? 'selcttype' : ''"
                  @click="selectsex(2)"
                  >女</span
                >
                <span
                  class="lx_btn"
                  :class="form.sex == 1 ? 'selcttype' : ''"
                  @click="selectsex(1)"
                  >男</span
                >
              </div>
            </el-form-item>
            <el-form-item label="日 期:">
              <el-date-picker
                v-model="form.time"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <div class="tipsset">提醒设置</div>

            <el-form-item label="提醒日期">
              <el-checkbox-group v-model="form.remind_time">
                <el-checkbox label="当天" name="type"></el-checkbox>
                <el-checkbox label="提前1天" name="type"></el-checkbox>
                <el-checkbox label="提前3天" name="type"></el-checkbox>
                <el-checkbox label="提前1周" name="type"></el-checkbox>
                <el-checkbox label="不提醒" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="提醒方式">
              <el-checkbox-group v-model="form.remind_mode">
                <el-checkbox label="短信提醒（13383110015）" name="type"
                  >短信提醒（13383110015）<span
                    ><el-button type="text">绑定手机</el-button></span
                  ></el-checkbox
                >
                <el-checkbox label="微信提醒" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="备注:">
              <div style="width: 370px">
                <el-input v-model="form.remark" placeholder="选填"></el-input>
              </div>
            </el-form-item>
            <div class="sumbit" @click="sumbit">保存</div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungRemind",

  data() {
    return {
      form: {
        type: "",
        title: "",
        time: "",
        sex: "",
        name: "",
        remind_time: "",
        remark: "",
        remind_mode: "",
      },
    };
  },

  mounted() {},

  methods: {
    // 添加提醒
    sumbit() {
      this.$api.user
        .adduserCommemorationDay({
          type: this.form.type,
          name: this.form.title,
          user_name: this.form.name,
          sex: this.form.sex,
          time: this.form.time,
          remark: this.form.remark,
          remind: 1,
          remind_mode: this.form.remind_mode,
          remind_time: this.form.remind_time,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$notify({
              title: "成功",
              message: "这是一条成功的提示消息",
              type: "success",
            });
          }
        });
    },
    selecttype(index) {
      this.form.type = index;
    },
    selectsex(index) {
      this.form.sex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.selcttype {
  background: #fff3f3 !important;
  border-radius: 16px !important;
  border: 1px solid #e70014 !important;
  color: #e70014 !important;
}
.remind {
  height: 100%;
  background-color: #fff;
}
.remind_title {
  margin-left: 40px;
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 20px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
}
.remind_form {
  margin-left: 82px;
}
.remind_form_title {
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 30px;
}
.lx {
  display: flex;
  align-items: center;
  .lx_btn {
    cursor: pointer;
    width: 90px;
    height: 32px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    line-height: 32px;
    margin-right: 20px;
    text-align: center;
    box-sizing: border-box;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #999999;
  }
}
.tipsset {
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
  margin-top: 40px;
  margin-bottom: 20px;
}
.sumbit {
  width: 292px;
  height: 48px;
  line-height: 48px;
  background: #e70014;
  border-radius: 24px;
  font-size: 18px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin-top: 40px;
}
::v-deep .el-form-item__label {
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #212121;
}
</style>