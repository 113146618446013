<template>
  <div class="box">
    <div style="height:0.1px"></div>
    <div class="card">
      <div class="clearfix">
        <div>
          <span>下单时间：2022-02-10</span>
          <span class="orderno">订单号：99798768567578</span>
        </div>
        <i
          class="el-icon-delete"
          style="float: right; padding: 3px 0"
          type="text"
        ></i>
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :show-header="false"
      >
        <el-table-column prop="date" width="435">
          <template slot-scope="scope">
            <div class="table_box">
              <div class="table_pic"></div>
              <div class="table_text">
                <div class="table_text_title">你是唯一 系列2</div>
                <div class="table_text_price"><span>共1件</span>¥999</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" width="180">
          <template slot-scope="scope">
            <div class="table_name">
              <div class="pstime">配送日期</div>
              <div class="psdate">2022-02-14 上午</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address">
          <template slot-scope="scope">
            <div class="table_staus">待付款</div>
          </template>
        </el-table-column>
        <el-table-column prop="address">
          <template slot-scope="scope">
            <div class="btn_box">
              <div>
                <div class="table_btn">查看详情</div>
                <div class="table_btn table_red">立即付款</div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pages">
      <el-pagination background layout="prev, pager, next,jumper" :total="1000">
        <span slot="prev"> <i class="el-icon-arrow-left btn"></i> 上一页</span>
        <span slot="next"><i class="el-icon-arrow-right btn"></i> 上一页</span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungOrder",

  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.box {
  background-color: #fff;
  height: 100%;
}
.swiper {
  background-color: #fff;
}
.first {
  border-bottom: 3px solid #e70014;
  box-sizing: border-box;
  .firstlist {
    margin: 0 auto;
    .listbox {
      display: flex;
      line-height: 4;
      align-items: center;
      font-size: 16px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 25px;
      .alllist {
        width: 255px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 48px;
        text-align: center;
        background: #e70014;
        border-radius: 10px 10px 0px 0px;
        margin-right: 90px;
        color: #fff;
        position: relative;
        img {
          margin-right: 18px;
        }
        .pos {
          position: absolute;
          width: 255px;
          height: 480px;
          background-color: #e70014;
          bottom: -483px;
        }
      }
      .qylist {
        line-height: 2.5;
        // margin-right: 110px;
        width: 140px;
        text-align: center;
      }
      .selectqylist {
        background: #e70014;
        border-radius: 12px 12px 0px 0px;
        color: #fff;
      }
    }
  }
  .swiperlsit {
    width: 100%;
    height: 480px;
    img {
      width: 100%;
      height: 480px;
    }
  }
}
.card {
  margin: 30px 30px 0 30px;
  background-color: #fff;
  min-height: 570px;
}
.clearfix {
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  height: 48px;
  background: #f5f5fa;
  border-radius: 12px 12px 0px 0px;
  line-height: 48px;
  .orderno {
    margin-left: 20px;
  }
}
.table_box {
  display: flex;
  padding: 20px 10px;
}
.table_pic {
  width: 60px;
  height: 60px;
  background: #d8d8d8;
  border: 1px solid #979797;
}
.table_text {
  margin-left: 20px;
  .table_text_title {
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
  }
  .table_text_price {
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
    margin-top: 18px;
    span {
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #666666;
      margin-right: 10px;
    }
  }
}
.table_name {
  text-align: center;
  .pstime {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #666666;
  }
  .psdate {
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
  }
}
.table_staus {
  text-align: center;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #e70014;
}
.table_btn {
  width: 90px;
  height: 28px;
  background: #ffffff;
  border-radius: 14px;
  border: 1px solid #ededed;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
  margin-bottom: 10px;
}
.table_red {
  color: #fff;
  background: #e70014;
}
.btn_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pages{
    display: flex;
    justify-content: flex-end;
    // margin-right: 20px;
    margin-top: 60px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #E70014;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #E70014;
    color: #FFF;
}
::v-deep .el-input.is-active .el-input__inner, .el-input__inner:focus {
    border-color: #E70014;
    outline: 0;
}
</style>