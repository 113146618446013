<template>
  <div class="personal">
    <div class="" style="height: 100%">
      <div style="height: 1px"></div>
      <div class="personal_title">个人信息</div>
      <div class="personal_avater">
        <div class="personal_tx"></div>
        <div class="personal_text">编辑头像</div>
      </div>
      <div class="personl_ms">
        <el-form ref="form" :model="form" label-width="80px" size="small ">
          <el-form-item label="姓 名:">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="性 别:">
            <div class="sex">
              <div class="sexbtn">女</div>
              <div class="sexbtn">男</div>
            </div>
          </el-form-item>
          <el-form-item label="生 日:">
            <el-date-picker v-model="value1" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="绑定手机:">
            <div>
              133****0015
              <el-button type="text" class="textbtn">绑定手机</el-button>
            </div>
          </el-form-item>
          <el-form-item label="绑定邮箱:">
            <div>
              13445567654@qq.com
              <el-button type="text" class="textbtn">验证邮箱</el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="submit">保存</div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungPersonal",

  data() {
    return {
      form: "",
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.personal {
    height: 100%;
  background-color: #fff;
  .personal_title {
    margin: 40px;
    font-size: 18px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
  }
  .personal_avater {
    margin-left: 250px;
    margin-top: 50px;
  }
  .personal_tx {
    width: 88px;
    height: 88px;
    background: #d8d8d8;
  }
  .personal_text {
    width: 88px;

    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
    margin-top: 7px;
  }
  .personl_ms {
    margin-left: 85px;
    margin-right: 454px;
    margin-top: 40px;
  }
}
.sexbtn {
  width: 90px;
  height: 32px;
  background: #fff3f3;
  border-radius: 16px;
  border: 1px solid #e70014;
  line-height: 32px;
  text-align: center;
  color: #e70014;
  margin-right: 30px;
}
.sex {
  display: flex;
}
.submit {
  width: 292px;
  height: 48px;
  background: #e70014;
  border-radius: 24px;
  font-size: 18px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}
.textbtn {
  margin-left: 20px;
}
</style>