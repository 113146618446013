<template>
  <div>
    <div class="paybox">
      <div class="pay">
        <div class="paydetails">
          <img src="../../assets/提交成功.png" alt="" />
          <div class="details_text">
            <div class="order_tips">订单提交成功</div>
            <div class="order_no">订单号：{{ order_data.orderno }}</div>
            <div class="order_sh">
              收货信息：{{ order_data.buy_user }} {{ order_data.buy_user_phone }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungPay",

  data() {
    return {
      order_id: "",
      pay_img: "",
      sets: "",
      zf_id: 1,
      is_zf: false,
      order_data: "",
      is_chufa: false,
    };
  },

  mounted() {
    this.order_id = this.$route.query.orderid;
    this.getorder_details();
  },

  methods: {
    getorder_details() {
      this.$api.details
        .order_info({
          order_id: this.order_id,
        })
        .then((res) => {
          this.order_data = res.data;
        });
    },
    getpay() {
      if (this.is_chufa == false) {
        this.$api.details
          .pay_info({
            order_id: this.order_id,
            type: "wechat",
            method: "pc",
          })
          .then((res) => {
            if (res.code == 1) {
              this.pay_img = res.data;
              this.zf_id = 1;
              this.is_chufa = true;
              this.sets = setInterval(() => {
                this.getstaus();
              }, 1000);
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
    selectzf() {
      this.zf_id = 0;
    },
    getstaus() {
      this.$api.details
        .pay_status({
          order_id: this.order_id,
        })
        .then((res) => {
          if (res.data.is_pay == 1) {
            clearInterval(this.sets);
            this.is_zf = true;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.paybox {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
  .paydetails {
    margin: 50px 70px;
    display: flex;
    align-items: center;
    .details_text {
      margin-left: 30px;
      .order_tips {
        font-size: 28px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #212121;
      }
      .order_no {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
        margin-top: 20px;
      }
      .order_sh {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
        margin-top: 10px;
      }
    }
  }
  .pay {
    height: 214px;
    display: flex;
    background: #f5f5fa;
  }
  .order_yf {
    margin: 30px 80px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
    span {
      font-size: 24px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #e70014;
      margin-left: 10px;
    }
  }
}
.order_pt {
  margin: 30px 80px;
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  // margin-bottom: 240px;
  .select {
    background: #f0f5ff;
    border-radius: 4px;
    border: 1px solid #b3cdff;
  }
}
.orderbtn {
  cursor: pointer;
  width: 200px;
  height: 78px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #ededed;
  margin-left: 30px;
  line-height: 78px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.order_img {
  position: relative;
  .zhizhao {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    width: 124px;
    height: 124px;
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>