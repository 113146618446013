<template>
  <div>
    <div class="details_top">
      <div class="details_top_head">
        <div class="details_top_head_status">待配送</div>
        <div class="details_top_head_btn">
          <div class="text_btn">取消订单</div>
          <div class="xg_btn">修改贺卡备注</div>
          <div class="lj_btn">立即付款</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="head_status">配送状态：</div>
      <div class="step">
        <el-steps direction="vertical" :active="1" space="500">
          <el-step title="步骤 1" description="2022-02-10 14:04:06"></el-step>
          <el-step title="步骤 2" description="2022-02-10 14:04:06"></el-step>
          <el-step
            title="步骤 3"
            description="这是一段很长很长很长的描述性文字"
          ></el-step>
        </el-steps>
      </div>
    </div>
    <div class="details_main">
      <div class="details_main_left">
        <div class="details_main_left_title">收货信息</div>
        <div class="list_box">
          <ul>
            <li class="list">
              <span>收 货 人</span>
              <div>蜜蜜</div>
            </li>
            <li class="list">
              <span>联系电话</span>
              <div>1338310015</div>
            </li>
            <li class="list">
              <span>收货地址</span>
              <div>河北省石家庄市桥西区金裕商务 旁边小黑屋</div>
            </li>
            <li class="list">
              <span>送达日期</span>
              <div>2020-02-14 上午</div>
            </li>
            <li class="list">
              <span>电子贺卡</span>
              <div>无</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="details_main_right">
        <div class="details_main_left_title">其他信息</div>
        <div class="list_box">
          <ul>
            <li class="list">
              <span>发票信息</span>
              <div>个人发票</div>
            </li>
            <li class="list">
              <span>备注</span>
              <div>包装好看一点哦</div>
            </li>
            <li class="list">
              <span>订 单 号</span>
              <div>97836878837397</div>
            </li>
            <li class="list">
              <span>下单日期</span>
              <div>2020-02-14 10:00:00</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="details_foot">
      <div style="height: 1px"></div>
      <div class="good">
        <div class="good_list">
          <div class="good_list_pic"></div>
          <div class="goode_list_title">你是唯一 系列2</div>
          <div class="goode_list_title1">共1件</div>
          <div class="goode_list_title2">¥999</div>
        </div>
      </div>
      <div class="price">
        <div class="pricebox">
          <div class="yh">优惠：<span>-¥10</span></div>
          <div class="zj">实付款：<span>¥999</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungOrderdetails",

  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.details_top {
  width: 975px;
  background: #ffffff;
  .line {
    width: 970px;
    height: 1px;
    background: #ededed;
    margin: 0 auto;
  }
  .head_status {
    margin: 30px 46px;
    font-size: 18px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
  }
  .step {
    margin: 0px 46px;
    padding-bottom: 80px;
    display: flex;
    margin-top: 30px;
  }
  .details_top_head {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    .details_top_head_status {
      font-size: 20px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #1a1a1a;
    }
    .details_top_head_btn {
      display: flex;
      align-items: center;
      .text_btn {
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #666666;
        margin-right: 40px;
      }
      .xg_btn {
        width: 140px;
        height: 48px;
        background: #ffffff;
        border-radius: 24px;
        border: 1px solid #dedede;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #1a1a1a;
        margin-right: 30px;
      }
      .lj_btn {
        width: 128px;
        height: 48px;
        background: #e70014;
        border-radius: 24px;
        text-align: center;
        line-height: 48px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
.details_main {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .details_main_left {
    width: 480px;
    height: 239px;
    background: #ffffff;
  }
  .details_main_right {
    width: 480px;
    height: 239px;
    background: #ffffff;
  }
  .details_main_left_title {
    font-size: 18px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
    margin: 0px 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .list_box {
    margin-left: 30px;
    .list {
      span {
        width: 56px;
        margin-right: 20px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #666666;
      }
      div {
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1a1a1a;
      }
      display: flex;
      margin-bottom: 20px;
    }
  }
}
.details_foot {
  background: #ffffff;
  margin-top: 15px;
  .good_list {
    padding: 35px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ededed;
    .good_list_pic {
      width: 60px;
      height: 60px;
      background: #d8d8d8;
    }
    .goode_list_title {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1a1a1a;
      margin-left: 20px;
    }
    .goode_list_title1 {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1a1a1a;
      margin-left: 160px;
    }
    .goode_list_title2 {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1a1a1a;
      margin-left: 80px;
    }
  }
  .price {
    display: flex;
    justify-content: flex-end;
    .pricebox {
      margin: 20px 0;
      margin-right: 35px;
      .yh {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
        line-height: 3;
      }
      .zj {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #1a1a1a;
        span {
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #e70014;
        }
      }
    }
  }
}
</style>