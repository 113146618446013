<template>
  <div class="integral">
    <div class="integral_box" style="height: 100%">
      <div class="integral_box_head">
        <div style="height: 1px"></div>
        <div class="integral_box_head_bj">{{ fen }}</div>
        <div class="integral_box_head_btn">我的积分</div>
        <div class="integral_box_head_gz" @click="djgz">
          <img src="../../assets/gz.png" alt="" srcset="" /> 积分规则
        </div>
        <el-dialog
          :visible.sync="dialogVisible"
          :show-close="false"
          :before-close="close"
        >
          <div class="gz">
            {{ jfgz }}
          </div>
        </el-dialog>
      </div>
      <div class="integral_box_main">
        <div style="height: 0.1px"></div>
        <div class="integral_box_main_title">
          签到领积分
          <span
            class="integral_box_main_title_btn"
            @click="integral"
            v-if="!date_sign"
            >立即签到</span
          >
          <span
            class="integral_box_main_title_btn"
            style="
              background: #eeeeee;
              color: #666666;
              font-weight: 400;
              box-shadow: 0px 1px 0px 0px #dedede;
            "
            @click="integral"
            v-else
            >已签到</span
          >
        </div>
        <el-dialog :visible.sync="integralVisible" :show-close="false">
          <div class="dialog_box">
            <div style="height: 0.1px"></div>
            <div class="pic">
              <img src="../../assets/签到.png" alt="" srcset="" />
            </div>
            <div class="box">
              <div class="height:0.1px"></div>
              <div style="display: flex" class="box_box">
                <img class="box_pic" src="../../assets/qd2.png" alt="" />
              </div>
              <div class="box_text">积分+{{ gold }}</div>
              <div class="box_btn" @click="close">我知道了</div>
            </div>
          </div>
        </el-dialog>
        <div class="integral_box_qd">
          <ul class="integral_box_qd_box">
            <li
              class="integral_box_qd_list"
              v-for="(item, index) in awardlist"
              :key="index"
              :class="item.sign_in ? 'selsctsign' : ''"
            >
              <div style="height: 0.1px"></div>
              <div class="time" :class="item.sign_in ? 'selsctsign' : ''">
                第{{ item.day }}天
              </div>
              <div class="pic">
                <img src="../../assets/yqjb.png" alt="" />
              </div>
              <div class="jf" :class="item.sign_in ? 'selsctsign' : ''">
                {{ item.gold }}积分
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="integral_box_good">
        <div style="height: 0.1px"></div>
        <div class="integral_box_good_title">
          <div>积分兑换</div>
          <el-tooltip content="请前往小程序查看" placement="top" effect="light">
            <div class="dh">兑换记录</div>
          </el-tooltip>
        </div>
        <div class="integral_box_good_listbox">
          <div
            class="integral_box_good_list"
            v-for="(item, index) in pointsGoods_list"
            @click="navdetails(item)"
            :key="index"
          >
            <div style="height: 0.1px"></div>
            <div class="pic">
              <img :src="$util.img(item.goods_image)[0]" alt="" srcset="" />
            </div>
            <div class="title">{{ item.name }}</div>
            <div class="jf">{{ item.point }} <span>积分</span></div>
            <div class="dh">
              <span>50人已兑换</span>
              <span class="btn" :class="item.is_enough == 1 ? '' : 'nosign'"
                >去兑换</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="integral_box_table">
        <div style="height: 0.1px"></div>
        <div class="integral_box_table_title">积分明细</div>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            border
            @selection-change="handleSelectionChange"
            header-cell-style="background:#F5F5FA; color: #1A1A1A ;font-weight: bold; font-size: 14px border:none;
"
          >
            <el-table-column label="时间">
              <template slot-scope="scope">
                {{ scope.row.update_time }}
              </template>
            </el-table-column>
            <el-table-column label="获取/消耗">
              <template slot-scope="scope">
                <div class="table_sc_der">
                  {{ scope.row.point_txt }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="详细说明"
              prop="remark"
              show-overflow-tooltip
              width="275"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="pages">
          <el-pagination
            background
            layout="prev, pager, next,jumper"
            :total="total"
            :page-size="log_limit"
            :current-page="log_page"
            @current-change="change"
          >
            <span slot="prev">
              <i class="el-icon-arrow-left btn"></i> 上一页</span
            >
            <span slot="next"
              ><i class="el-icon-arrow-right btn"></i> 上一页</span
            >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PctheyoungIntegral",

  data() {
    return {
      tableData: [
        {
          date: "2022-02-14 10:00",
          name: "+50",
          address: "签到赠积分",
        },
      ],
      dialogVisible: false,
      integralVisible: false,
      awardlist: "",
      gold: "",
      date_sign: "",
      pointsGoods_list: [],
      limit: 5,
      page: 1,
      total: "",
      log_limit: 10,
      log_page: 1,
      fen: "",
      jfgz: "",
    };
  },

  mounted() {
    this.getaward();
    this.getpointsGoods();
    this.getmingxi();
    this.getpoint();
  },

  methods: {
    // 查看详情
    navdetails(event) {
      let routeData = this.$router.resolve({
        path: "ingtaldetails",
        query: {
          id: event.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 获取积分
    getpoint() {
      this.$api.user.info({}).then((res) => {
        this.fen = res.data.userInfo.points;
      });
    },
    // 签到配置
    getaward() {
      this.$api.user.award({}).then((res) => {
        this.awardlist = res.data.award_rule;
        this.date_sign = res.data.today_is_sign_in;
        this.jfgz = res.data.rule;
      });
    },
    // 分页
    change(event) {
      this.log_page = event;
      this.getmingxi();
    },
    // 积分列表
    getpointsGoods() {
      this.$api.user
        .pointsGoods({
          limit: this.limit,
          page: 1,
        })
        .then((res) => {
          this.pointsGoods_list = res.data.list;
        });
    },
    // 积分明细
    getmingxi() {
      this.$api.user
        .point_log({
          limit: this.log_limit,
          page: this.log_page,
        })
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total;
        });
    },
    // 规则
    djgz() {
      this.dialogVisible = true;
    },
    integral() {
      this.$api.user.sign({}).then((res) => {
        if (res.code == 1) {
          this.gold = res.data.gold;
          this.integralVisible = true;
          this.getpoint();
          this.getaward();
        } else {
          this.$notify.error({
            title: "失败",
            message: res.msg,
          });
        }
      });
    },
    close() {
      this.dialogVisible = false;

      this.integralVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.gz {
  width: 400px;
  min-height: 420px;
  background: #fff3f3;
  margin: 0 auto;
  border-radius: 12px;
  padding: 20px;
  line-height: 2;
}
.nosign {
  background: #f6f6f6 !important;

  color: #666666 !important;
}
.selsctsign {
  background: #ff6875 !important;
  color: #ffffff !important;
}
.integral_box_qd_box {
  display: flex;
}
.integral {
  height: 100%;
}
.integral_box_head {
  height: 249px;
  background: url(../../assets/圆.png) no-repeat,
    linear-gradient(180deg, #d09ed8 0%, #ffd7d5 100%);
  background-size: 640px;
  background-position: 89px;
  position: relative;
}
.integral_box_head_bj {
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
  background: url(../../assets/bg1.png);
  background-repeat: no-repeat;
  background-size: 140px;
  margin: 0 auto;
  margin-top: 27px;
  margin-bottom: 10px;
  font-size: 32px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff6875;
}
.integral_box_head_btn {
  width: 120px;
  height: 34px;
  background: #ffffff;
  box-shadow: 0px 2px 7px 0px #fde7ff;
  border-radius: 18px;
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #ff6875;
  margin: 0 auto;
  text-align: center;
  line-height: 34px;
}
.integral_box_head_gz {
  font-size: 16px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  top: 28px;
  right: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  img {
    width: 16px;
    margin-right: 10px;
  }
}
.integral_box_main {
  height: 244px;
  background: #ffffff;
  margin-top: 16px;
  .integral_box_main_title {
    margin: 40px 0 0 40px;
    font-size: 18px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
    .integral_box_main_title_btn {
      display: inline-block;
      width: 100px;
      height: 30px;
      background: linear-gradient(180deg, #ffa2ae 0%, #ff6875 100%);
      box-shadow: 0px 1px 0px 0px #ff5b69;
      border-radius: 14px;
      font-size: 14px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      margin-left: 20px;
    }
  }
  .integral_box_qd {
    margin: 40px;
    .integral_box_qd_list {
      margin-right: 20px;
      width: 84px;
      height: 106px;
      background: #fff3f3;
      border-radius: 10px;
      .time {
        font-size: 16px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #1a1a1a;
        text-align: center;
        margin-top: 10px;
      }
      .pic {
        width: 28px;
        height: 28px;
        text-align: center;
        margin: 0 auto;

        margin-top: 8px;

        img {
          width: 28px;
          height: 28px;
        }
      }
      .jf {
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #999999;
        text-align: center;
        margin-top: 7px;
      }
    }
  }
}
.integral_box_good {
  height: 450px;
  background: #ffffff;
  margin-top: 15px;
  .integral_box_good_title {
    margin: 40px;
    height: 26px;
    font-size: 18px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dh {
      font-size: 16px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #666666;
    }
  }
}
.integral_box_good_listbox {
  margin-left: 40px;
  display: flex;
}
.integral_box_good_list {
  width: 210px;
  margin-right: 10px;

  height: 314px;
  background: #f6f6f6;
  border-radius: 6px;
  .pic {
    width: 190px;
    height: 190px;
    background-color: #ffffff;
    margin: 10px;

    border-radius: 7px 7px 0px 0px;
    img {
      width: 190px;
      height: 190px;
    }
  }
  .title {
    font-size: 16px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
    margin: 0 10px;
    margin-bottom: 10px;
  }
  .jf {
    font-size: 22px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #ff6875;
    margin: 0 10px;
    margin-bottom: 10px;
    span {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ff6875;
    }
  }
  .dh {
    margin: 0 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      cursor: pointer;
      display: inline-block;
      padding: 7px 16px;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      background: #ff6875;
      border-radius: 17px;
    }
  }
}
.integral_box_table {
  background-color: #ffffff;
  padding-bottom: 40px;
}
.integral_box_table_title {
  margin: 40px;
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #1a1a1a;
}
.table {
  margin: 0 40px;
}
.pages {
  margin: 40px;
  display: flex;
  justify-content: center;
}
::v-deep .el-table__header-wrapper {
  border-radius: 12px 12px 0px 0px;
}
::v-deep .el-table .cell.el-tooltip {
  white-space: initial;
  min-width: 50px;
}
::v-deep .el-table--border,
.el-table--group {
  border-radius: 12px 12px 0px 0px;
}
.dialog_box {
  width: 340px;
  height: 352px;
  background: #ff6875;
  border-radius: 12px;
  margin: 0 auto;
  position: relative;
  .pic {
    position: absolute;
    top: -96px;
    left: 79px;
    img {
      width: 182px;
      height: 132px;
      margin: 0 auto;
    }
  }
  .box {
    width: 340px;
    height: 345px;
    background: #ffffff;
    border-radius: 12px;
    margin-top: 7px;
    .box_box {
    }
    .box_pic {
      width: 158px;
      height: 82px;
      margin: 0 auto;

      margin-top: 60px;
    }
  }
  .box_text {
    font-size: 20px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #1a1a1a;
    text-align: center;
    margin-top: 36px;
  }
  .box_btn {
    width: 173px;
    height: 48px;
    background: #ff6875;
    border-radius: 24px;
    margin: 0 auto;
    line-height: 48px;
    text-align: center;
    margin-top: 67px;
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #ffffff;
  }
}
::v-deep .el-dialog {
  background: transparent !important;
  box-shadow: 0px 0 0;
}
</style>