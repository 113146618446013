<template>
  <div>
    <div class="pollen">
      <div
        class="pollen_list"
        v-for="(item, index) in pollen_list"
        :key="index"
      >
        <div class="pollen_shabox" v-if="item.goods_list.length >= 6">
          <div class="ck">查看更多商品>></div>
        </div>
        <div class="pollen_list_header">
          <div class="pollen_list_header_img">
            <img :src="$util.img(item.image)[0]" alt="" srcset="" />
          </div>
          <div class="pollen_list_header_text">
            <div class="pollen_list_header_title">{{ item.name }}</div>
            <div class="pollen_list_header_label">
              {{ item.meaning }}
            </div>
          </div>
        </div>
        <div class="pollen_list_good">
          <div
            class="pollen_list_good_list"
            v-for="(itema, indexa) in item.goods_list"
            :key="indexa"
            ref=""
            @click="navdetails(itema.id)"
          >
            <div class="pollen_list_good_list_img">
              <img :src="$util.img(itema.goods_image)[0]" alt="" srcset="" />
            </div>
            <div class="pollen_list_good_list_title">¥{{ itema.price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pollen_list: [],
    };
  },
  mounted() {
    this.getpollen_list();
  },

  methods: {
    getpollen_list() {
      this.$api.user.flowerLanguageWood({}).then((res) => {
        this.pollen_list = res.data;
      });
    },
    // 查看详情
    navdetails(id) {
      let routeData = this.$router.resolve({
        path: "details",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang='scss'>
.pollen {
  width: 1200px;
  min-height: 852px;
  background: #fff;
  margin: 0 auto;
}
.pollen_list {
  padding: 30px 0;
  position: relative;
  .pollen_shabox {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100px;
    height: 218px;
    background: #ffffff;
    box-shadow: -7px 0px 6px -6px #ededed;

    .ck {
      margin: 67px 30px;
      font-size: 16px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #1a1a1a;
      text-align: center;
    }
  }
  .pollen_list_header {
    display: flex;
    align-items: center;
    .pollen_list_header_img {
      width: 98px;
      height: 98px;
      cursor: pointer;
      img {
        width: 98px;
        height: 98px;
        border-radius: 50%;
      }
    }
    .pollen_list_header_text {
      margin-left: 30px;
      .pollen_list_header_title {
        font-size: 28px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #1a1a1a;

        line-height: 40px;
      }
      .pollen_list_header_label {
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #666666;
        line-height: 18px;
      }
    }
  }
  .pollen_list_good {
    display: flex;

    margin-top: 30px;
    .pollen_list_good_list {
      cursor: pointer;
      width: 180px;
      margin-right: 30px;
      .pollen_list_good_list_img {
        width: 180px;
        height: 180px;
        background: #d8d8d8;
        border-radius: 12px;
        img {
          width: 180px;
          height: 180px;
        }
      }
      .pollen_list_good_list_title {
        font-size: 16px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #e70014;
        text-align: center;
        padding: 10px 0;
      }
    }
  }
}
</style>