// 图片处理
export function img(img_path, params) {
    var path = "";
    var paths = [];
    var pic = "";
    if (img_path != undefined) {
        pic = img_path.split(",");
        for (let item of pic) {
            if (item && item != undefined && item != "") {
                if (
                    item.indexOf("http://") == -1 &&
                    item.indexOf("https://") == -1
                ) {
                    paths.push("https://flower.csfeng.com" + item);
                } else {
                    paths.push(item);
                }
                // 处理商品助手的图片路径
                path = path
                    .replace("addons/NsGoodsAssist/", "")
                    .replace("shop/goods/", "");
            }
        }
    } else {
        paths.push("https://waiter.csfeng.com/theyoung/img/picyc.png");
    }
    return paths;
}
export default {
    img,
}