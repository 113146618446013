<template>
  <!-- pc首页 -->
  <div>
    <main class="main">
      <div class="posfixed" v-if="is_scroll">
        <ul class="fixed_list">
          <div style="height: 0.1px"></div>
          <li class="" @click="navcart">
            <img src="../assets/gwc2x.png" alt="" />
            <div>购物车</div>
          </li>
          <li class="">
            <img src="../assets/ewm2x.png" alt="" />
            <div>微信小程序</div>
          </li>
          <li class="" @click="navback_top">
            <img src="../assets/fh2x.png" alt="" />
            <div>返回顶部</div>
          </li>
        </ul>
      </div>
      <div class="swiper">
        <div class="first">
          <div class="firstlist">
            <ul class="listbox">
              <li
                class="qylist"
                v-for="(item, index) in classlist"
                :key="index"
                @click="navclass(item)"
              >
                {{ item.name }}
              </li>
              <!-- <li class="qylist">周花</li>
              <li class="qylist">永生花</li> -->
              <li class="qylist" @click="navpollen">花语大全</li>
            </ul>
          </div>
        </div>
        <div class="swiperlsit" style="width: 100%">
          <el-carousel style="width: 100%" :height="imgHeight ">
            <el-carousel-item
              v-for="(item, index) in adlist"
              :key="index"
              style="width: 100%"
            >
              <img
                :src="$util.img(item.image_pc)[0]"
                alt=""
                ref="imgHeight"
                :style="{ width: clie_width + 'px' }"
                @click="navad(item)"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="navbox">
        <div
          class="navlist"
          v-for="(item, index) in toplist"
          :key="index"
          @click="navcs(item)"
        >
          <div class="navlist_1_top">
            <div style="height: 1px"></div>
            <div class="navlist_1_top_text1">
              <h1>{{ item.name }}</h1>
              <img src="../assets/右.png" alt="" />
            </div>
            <p class="navlist_1_top_text2">{{ item.sub_name }}</p>
            <div class="nav_pic">
              <img :src="$util.img(item.image)[0]" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="navbox">
        <div class="navlist_1_bottom" @click="navsale">
          <div>
            <h1>热销榜单</h1>
            <div class="navlist_1_bottom_type">销售冠军</div>
            <div class="navlist_1_bottom_title">热销排行</div>
          </div>

          <div class="img">
            <img src="../assets/1@2x.png" alt="" srcset="" />
          </div>
        </div>

        <div class="navlist_1_bottom" @click="navzh">
          <div>
            <h1>一周一花</h1>
            <div class="navlist_1_bottom_text">优选当季鲜花</div>
          </div>

          <div class="img">
            <img src="../assets/2@2x.png" alt="" srcset="" />
          </div>
        </div>
        <div class="navlist_1_bottom" @click="navys">
          <div>
            <h1>永生花</h1>
            <div class="navlist_1_bottom_text">永不凋谢的爱</div>
          </div>

          <div class="img">
            <img src="../assets/3@2x.png" alt="" srcset="" />
          </div>
        </div>
      </div>
      <div class="goodslit" v-for="(item, index) in indexlist" :key="index">
        <div class="title">
          <div class="head">
            <div class="head_type">{{ item.name }}</div>
            <div class="verticalbar"></div>
            <div class="describe">{{ item.sub_name }}</div>
          </div>
          <div
            class="see"
            @click="navshoplist(item.class_top_id, item.goods_class_id)"
          >
            查看更多>>
          </div>
        </div>
        <div class="listbox">
          <div
            class="img"
            @click="navshoplist(item.class_top_id, item.goods_class_id)"
          >
            <img :src="$util.img(item.image)" alt="" />
          </div>
          <!-- <div> -->
          <div
            class="list"
            v-for="(items, indexs) in item.list"
            :key="indexs"
            @click="navdetails(items.id)"
          >
            <div class="list_img">
              <img :src="$util.img(items.goods_image)[0]" alt="" />
            </div>
            <!-- <div class="lsit_text">新品上架</div> -->
            <div class="lsit_text_title">{{ items.name }}</div>
            <div class="lsit_text_price">￥{{ items.price }}</div>
            <div class="lsit_text_Sold">已售{{ items.sale_num }}单</div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <!-- 花粉晒图 -->
      <div class="goodslit">
        <div class="title">
          <div class="head">
            <div class="head_type">花粉晒图</div>
          </div>
          <div class="see" @click="see">查看更多>></div>
        </div>
        <div class="hf_listbox">
          <div class="hf_list" v-for="(item, index) in pollenlist" :key="index">
            <div>
              <div class="avater">
                <div class="head_pic">
                  <img :src="item.userInfo.avatar" alt="" srcset="" />
                </div>
                <span>{{ item.nick }}</span>
              </div>
              <div class="hf_text">
                {{ item.content }}
              </div>
              <div class="hf_location">河北石家庄桥西区</div>
              <div class="hf_time">2022-02-14</div>
            </div>
            <div class="st_pic">
              <img :src="$util.img(item.images)[0]" alt="" srcset="" />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  data() {
    return {
      property: "value",
      pollenlist: [],
      classlist: [],
      indexlist: [],
      is_scroll: false,
      fullHeight: 0,
      toplist: "",
      clie_width: document.body.clientWidth,
      bottom: "",
      adlist: "",
      imgHeight:'540px'
    };
  },
  mounted() {
    this.getpollen();
    this.getclass();
    this.getindexPc();
    window.addEventListener("scroll", this.handleScroll);
    this.getbodyheight();
    this.getad_position();
    window.onresize = () => {
      this.imgLoad();
    };
  },
  // 销毁滚动方法
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.onresize = null;
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        this.imgHeight = this.$refs.imgHeight["0"].height + "px";
        console.log( this.imgHeight);
      });
    },
    // 获取广告为
    getad_position() {
      this.$api.details.ad_position({}).then((res) => {
        this.adlist = res.data.top.adList;
      });
    },
    navad(event) {
      let routeData = this.$router.resolve({
        path: "hotsales",
        query: {
          ad_id: event.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 获取滚动距离
    handleScroll() {
      this.scroll = document.documentElement.scrollTop;
      if (this.scroll >= 700) {
        this.is_scroll = true;
      } else {
        this.is_scroll = false;
      }
    },
    navcart() {
      this.$router.push("cart");
    },
    navback_top() {
      document.documentElement.scrollTop = 0;
    },
    // 获取页面高度
    getbodyheight() {
      this.fullHeight = document.documentElement.clientHeight;
    },
    // 花语
    navpollen() {
      let routeData = this.$router.resolve({
        path: "pollen",
      });
      window.open(routeData.href, "_blank");
    },
    see() {
      let routeData = this.$router.resolve({
        path: "silde",
      });
      window.open(routeData.href, "_blank");
    },
    navsale() {
      let routeData = this.$router.resolve({
        path: "hotsale",
      });
      window.open(routeData.href, "_blank");
    },
    navcs(item) {
      let routeData = this.$router.resolve({
        path: "Commodity",
        query: {
          classid: item.class_top_id,
          goodid: item.goods_class_id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    navys() {
      let routeData = this.$router.resolve({
        path: "Commodity",
        query: {
          classid: 2,
        },
      });
      window.open(routeData.href, "_blank");
    },
    navzh() {
      let routeData = this.$router.resolve({
        path: "Commodity",
        query: {
          classid: 3,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 获取首页
    getindexPc() {
      this.$api.details.indexPc({}).then((res) => {
        this.indexlist = res.data.tab_list;
        this.toplist = res.data.top_list;
      });
    },
    // 商品详情
    navdetails(id) {
      let routeData = this.$router.resolve({
        path: "details",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_self").close();
    },
    // 商品分类
    navshoplist(class_top_id, good_id) {
      let routeData = this.$router.resolve({
        path: "Commodity",
        query: {
          classid: class_top_id,
          goodid: good_id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    getclass() {
      this.$api.user.goodsClass({}).then((res) => {
        this.classlist = res.data;
      });
    },
    navclass(item) {
      let routeData = this.$router.resolve({
        path: "Commodity",
        query: {
          classid: item.id,
          // goodid: good_id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    navCommodity() {
      this.$router.push("Commodity");
    },
    getpollen() {
      this.$api.details
        .comment_list({
          is_all: 1,
          limit: 6,
          page: 1,
        })
        .then((res) => {
          this.pollenlist = res.data.list;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.posfixed {
  width: 82px;
  // height: 314px;
  background: rgb(255, 255, 255, 0.1);
  box-shadow: 1px 1px 4px 0px rgba(195, 193, 193, 0.75);
  position: fixed;
  right: 0%;
  // bottom: 370px;
  top: 100px;
  z-index: 9999999;
  .fixed_list {
    li {
      text-align: center;
      padding: 20px 0;
      margin: 0 6px;
      font-size: 16px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #1a1a1a;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
.list_box {
  margin: 20px;
  text-align: left;
  color: #1a1a1a;
  line-height: normal;
  .list_box_header {
    font-size: 16px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1a1a1a;
  }
  .list_box_list {
    li {
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #1a1a1a;
    }
  }
}
body {
  background-color: #f6f6f6;
}
.main {
  width: 100%;
  margin: 0 auto;
  background-color: #ededed;
  position: relative;
  .first {
    border-bottom: 3px solid #e70014;
    box-sizing: border-box;
    .firstlist {
      width: 1200px;
      margin: 0 auto;
      .listbox {
        display: flex;
        align-items: center;

        line-height: 3;
        font-size: 18px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 25px;
        cursor: pointer;
        .alllist {
          width: 255px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 48px;
          text-align: center;
          background: #e70014;
          border-radius: 10px 10px 0px 0px;
          margin-right: 90px;
          color: #fff;
          position: relative;
          img {
            margin-right: 18px;
          }
          .pos {
            position: absolute;
            width: 255px;
            height: 480px;
            background-color: rgb(237, 237, 237, 0.5);
            bottom: -483px;
            z-index: 99999999999;
          }
        }
        .qylist {
          margin-right: 110px;
          width: 100%;
          text-align: center;
          height: 48px;
          line-height: 48px;
        }
        .qylist:hover {
          color: #e70014;
        }
      }
    }
    .swiperlsit {
      width: 100%;
      height: 480px;
      img {
        width: 100%;
        height: 480px;
      }
    }
  }
  .navbox {
    width: 1200px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    .navlist {
      width: 390px;

      .navlist_1_top {
        width: 390px;
        height: 420px;
        background: #f5f5fa;
        border-radius: 11px;
        .navlist_1_top_text1 {
          display: flex;
          margin: 30px 0 15px 30px;

          h1 {
            font-size: 32px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #1a1a1a;
            line-height: 32px;
          }
          img {
            width: 34px;
            height: 34px;
            margin-left: 20px;
          }
        }
        .navlist_1_top_text2 {
          font-size: 22px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #ff7985;
          line-height: 24px;
          margin-left: 30px;
        }
        .nav_pic {
          width: 100%;
          // margin: 30px;
          margin-top: 10px;
          img {
            width: 100%;
          }
        }
      }
    }
    .navlist_1_bottom {
      cursor: pointer;
      width: 390px;
      height: 200px;
      background: #f5f5fa;
      border-radius: 11px;
      display: flex;
      justify-content: space-between;

      .img {
        img {
          width: 198px;
          height: 198px;
        }
      }
      // margin-top: 20px;
      h1 {
        font-size: 32px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #1a1a1a;
        line-height: 3;
        margin-left: 30px;
      }
      .navlist_1_bottom_type {
        width: 109px;
        height: 28px;
        border-radius: 16px;
        border: 1px solid #e70014;
        line-height: 28px;
        text-align: center;
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #e70014;
        margin-left: 30px;
      }
      .navlist_1_bottom_title {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 25px;
        margin-top: 15px;
        margin-left: 30px;
      }
      .navlist_1_bottom_text {
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ff6875;
        line-height: 25px;
        margin-left: 30px;
      }
    }
  }
  .goodslit {
    width: 1200px;
    margin: 0 auto;
    clear: both;
    .title {
      display: flex;
      justify-content: space-between;
      .head {
        display: flex;
        align-items: center;
        .head_type {
          font-size: 30px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #1a1a1a;
        }
        .verticalbar {
          width: 1px;
          height: 18px;
          margin: 0 10px;
          background: #999999;
        }
        .describe {
          font-size: 18px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 25px;
        }
      }
      .see {
        cursor: pointer;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }
    .listbox {
      // display: flex;
      // flex-wrap: wrap;
      // align-content: flex-start;

      // //   margin-right: 18px;
      // justify-content: space-between;
      margin-top: 30px;
      width: 102%;

      .img {
        width: 590px;
        height: 441px;
        background: linear-gradient(300deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        margin-bottom: 20px;
        float: left;
        margin-right: 20px;
        cursor: pointer;
        img {
          width: 590px;
          height: 441px;
        }
      }
      .list {
        width: 285px;
        height: 441px;
        margin-bottom: 20px;
        background-color: #fff;
        cursor: pointer;
        float: left;
        margin-right: 20px;

        // margin-right: 10px;
        .list_img {
          width: 285px;
          background-color: skyblue;
          height: 285px;
          overflow: hidden;

          img {
            width: 285px;
            height: 285px;
            cursor: pointer;
            transform: scale(1);
            transition: transform 1s ease 0s;
          }
          img:hover {
            transform: scale(1.2);
          }
        }
        .lsit_text {
          text-align: center;
          margin-top: 12px;
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #e70014;
        }
        .lsit_text_title {
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #1a1a1a;
          margin-top: 22px;
          text-align: center;
        }
        .lsit_text_price {
          text-align: center;
          margin-top: 10px;

          font-size: 22px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #e70014;
        }
        .lsit_text_Sold {
          text-align: center;
          margin-top: 10px;

          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #999999;
        }
      }
      .list:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
    .hf_listbox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;
      .hf_list {
        width: 390px;
        height: 260px;
        background: #fff;
        border-radius: 11px;
        margin-bottom: 20px;
        display: flex;
        .st_pic {
          width: 165px;
          margin: 15px 0 0 20px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          img {
            height: 230px;
            border-radius: 12px;
          }
        }
        .avater {
          display: flex;
          margin: 30px 0 0 30px;
          align-items: center;
          .head_pic {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: #1a1a1a;
            img {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }
          }
          span {
            margin-left: 10px;
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .hf_text {
          margin: 24px 0 0 30px;

          width: 165px;
          height: 104px;
          font-size: 16px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #e70014;
          line-height: 26px;
        }
        .hf_location {
          margin: 24px 0 0 30px;
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #999999;
        }
        .hf_time {
          margin: 4px 0 0 30px;
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
</style>
